import SglTable from "components/ToolkitTable/SglTable"
import React, { useEffect, useMemo, useState } from "react"
import { Button, Col, Row } from "reactstrap"
import { hoursToMinutes } from "utils/timeHandling"
import { Link } from "react-router-dom"

function InvoicesForecastTable(props) {
  const columns = [
    {
      dataField: "name",
      text: "nome",
      sort: true,
      hidden: false,
    },
    {
      dataField: "scheduledTime",
      text: "Horas Agendadas",
      sort: true,
    },
    {
      dataField: "forseenTime",
      text: "Projeção de Horas",
      sort: true,
      hidden: false,
    },
    {
      dataField: "performedTime",
      text: "Horas Realizadas",
      sort: true,
      hidden: false,
    },
    {
      dataField: "periodBalance",
      text: "Diferença período",
      sort: true,
      hidden: !props.hours,
      formatter: (cell) => {
        const minutes = hoursToMinutes(cell)
        const color = minutes < 0 ? "crimson" : minutes > 0 ? "green" : "black"
        return <span style={{ color: color }}>{cell}</span>
      },
    },
    {
      dataField: "todayBalance",
      text: "Diferença dia",
      sort: true,
      hidden: !props.hours,
      formatter: (cell) => {
        const minutes = hoursToMinutes(cell)
        const color = minutes < 0 ? "crimson" : minutes > 0 ? "green" : "black"
        return <span style={{ color: color }}>{cell}</span>
      },
    },

    {
      dataField: "scheduledCost",
      text: "Valor Agendado",
      sort: true,
      hidden: false,
    },
    {
      dataField: "forseenCost",
      text: "Valor Projetado",
      sort: true,
      hidden: false,
    },
    {
      dataField: "performedCost",
      text: "Valor Realizado",
      sort: true,
      hidden: false,
    },
    {
      dataField: "costOverTime",
      text: "Valor/Hora",
      sort: true,
    },
    {
      dataField: "periodBalance",
      text: "Diferença período",
      sort: true,
      hidden: !props.hours,
      formatter: (cell) => {
        const minutes = hoursToMinutes(cell)
        const color = minutes < 0 ? "crimson" : minutes > 0 ? "green" : "black"
        return <span style={{ color: color }}>{cell}</span>
      },
    },
    {
      dataField: "todayBalance",
      text: "Diferença dia",
      sort: true,
      hidden: !props.hours,
      formatter: (cell) => {
        const minutes = hoursToMinutes(cell)
        const color = minutes < 0 ? "crimson" : minutes > 0 ? "green" : "black"
        return <span style={{ color: color }}>{cell}</span>
      },
    },
    {
      dataField: "actions",
      text: "Ações",
      sort: true,
      hidden: !props.hours,
      formatter: (cell) => {
        return !cell?.consultantId ? (
          <></>
        ) : (
          <Button size="sm">
            <Link
              to={{
                pathname: `/document/${cell?.consultantId}`,
                search: `type=os_report&dateTo=${props.to}&dateFrom=${props.from}`,
              }}
              target={"_blank"}
            >
              <i className="fas fa-file-pdf"> </i>
            </Link>
          </Button>
        )
      },
    },
  ]

  return (
    <>
      <Row>
        <Col xs={12}>
          {props.data.length > 0 && (
            <SglTable
              removePagination={true}
              columns={columns}
              className={"invoiceForecastTable"}
              data={props.data}
              rowClick={() => {}}
            />
          )}
        </Col>
      </Row>
    </>
  )
}
export default InvoicesForecastTable
