import React from "react"
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Modal,
  Row,
  Col,
} from "reactstrap"
import InputMask from "react-input-mask"

class ContractInstallment extends React.Component {
  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.props.modalContractInstallmentOpen}
          autoFocus={false}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="createInstallments">
              {this.props.modalContractInstallmentTitle}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeModalContractInstallment(e)}
              disabled={this.props.saving}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardBody>
                    <Form
                      className="needs-validation"
                      noValidate
                      autoComplete="off"
                    >
                      <Row>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="contractInstallmentDocDate"
                          >
                            Emissão
                          </label>
                          <Input
                            autoFocus
                            id="contractInstallmentDocDate"
                            type="date"
                            value={this.props.contractInstallmentDocDate}
                            readOnly={this.props.crudInstallment === "D"}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles
                                .contractInstallmentDocDateState === "valid"
                            }
                            invalid={
                              this.props.customStyles
                                .contractInstallmentDocDateState === "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(
                                e,
                                "contractInstallmentDocDate"
                              )
                            }
                          />
                          <div className="invalid-feedback">
                            Digite a data de emissão.
                          </div>
                        </Col>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="contractInstallmentDueDate"
                          >
                            Vencimento
                          </label>
                          <Input
                            id="contractInstallmentDueDate"
                            type="date"
                            value={this.props.contractInstallmentDueDate}
                            readOnly={this.props.crudInstallment === "D"}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles
                                .contractInstallmentDueDateState === "valid"
                            }
                            invalid={
                              this.props.customStyles
                                .contractInstallmentDueDateState === "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(
                                e,
                                "contractInstallmentDueDate"
                              )
                            }
                          />
                          <div className="invalid-feedback">
                            Digite a data de vencimento.
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="contractInstallmentAmount"
                          >
                            Valor (R$)
                          </label>
                          <Input
                            id="contractInstallmentAmount"
                            placeholder="0.00"
                            type="number"
                            value={this.props.contractInstallmentAmount}
                            readOnly={this.props.crudInstallment === "D"}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles
                                .contractInstallmentAmountState === "valid"
                            }
                            invalid={
                              this.props.customStyles
                                .contractInstallmentAmountState === "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(
                                e,
                                "contractInstallmentAmount"
                              )
                            }
                          />
                          <div className="invalid-feedback">
                            Digite o valor da parcela.
                          </div>
                        </Col>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="contractInstallmentHours"
                          >
                            Horas
                          </label>
                          <Input
                            id="contractInstallmentHours"
                            placeholder="0"
                            type="text"
                            mask="999:99"
                            tag={InputMask}
                            value={this.props.contractInstallmentHours}
                            disabled={
                              this.props.saving || this.props.hourlyRate === 0
                            }
                            valid={
                              this.props.customStyles
                                .contractInstallmentHoursState === "valid"
                            }
                            invalid={
                              this.props.customStyles
                                .contractInstallmentHoursState === "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(
                                e,
                                "contractInstallmentHours"
                              )
                            }
                          />
                          <div className="invalid-feedback">
                            Qtde. horas/minutos inválida.
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" md="12">
                          <label
                            className="form-control-label"
                            htmlFor="contractInstallmentCriteria"
                          >
                            Critérios
                          </label>
                          <Input
                            id="contractInstallmentCriteria"
                            placeholder="Criterios do pagamento"
                            type="text"
                            value={this.props.contractInstallmentCriteria}
                            readOnly={this.props.crudInstallment === "D"}
                            disabled={this.props.saving}
                            onChange={(e) =>
                              this.props.stylesForm(
                                e,
                                "contractInstallmentCriteria"
                              )
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="contractInstallmentAuthorized"
                          >
                            Autorizado ?
                          </label>
                          <div>
                            <label className="custom-toggle custom-toggle-default mr-1">
                              <input
                                type="checkbox"
                                checked={
                                  this.props.contractInstallmentAuthorized
                                }
                                disabled={
                                  this.props.saving ||
                                  this.props.crudInstallment === "D"
                                }
                                onChange={(e) =>
                                  this.props.stylesForm(
                                    e,
                                    "contractInstallmentAuthorized",
                                    true
                                  )
                                }
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="Não"
                                data-label-on="Sim"
                              />
                            </label>
                          </div>
                        </Col>
                        <Col className="mb-3" md="6">
                          <label
                            className="form-control-label"
                            htmlFor="contractInstallmentStatus"
                          >
                            Status
                          </label>
                          <Input
                            id="contractInstallmentStatus"
                            type="select"
                            value={this.props.contractInstallmentStatus}
                            disabled
                            valid={
                              this.props.customStyles
                                .contractInstallmentStatusState === "valid"
                            }
                            invalid={
                              this.props.customStyles
                                .contractInstallmentStatusState === "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(
                                e,
                                "contractInstallmentStatus"
                              )
                            }
                          >
                            <option value="P">Pendente</option>
                            <option value="I">Faturada</option>
                          </Input>
                          <div className="invalid-feedback">
                            Escolha um status.
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          <div className="modal-footer">
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeModalContractInstallment(e)}
              disabled={this.props.saving}
            >
              {this.props.crudInstallment === "R" ? "Fechar" : "Cancelar"}
            </Button>
            <Button
              color="success"
              type="button"
              onClick={(e) => this.props.saveContractInstallment(e)}
              disabled={this.props.saving}
              hidden={this.props.crudInstallment === "R"}
            >
              {this.props.crudInstallment === "D" ? "Excluir" : "Salvar"}
            </Button>
          </div>
        </Modal>
      </>
    )
  }
}

export default ContractInstallment
