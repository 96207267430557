import React from "react"
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Modal,
  Row,
  Col,
} from "reactstrap"
import Select2 from "react-select2-wrapper"
import InputMask from "react-input-mask"

class ActivityConsultant extends React.Component {
  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.props.modalConsultantOpen}
          autoFocus={false}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="activityConsultant">
              {this.props.modalConsultantTitle}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeModalConsultant(e)}
              disabled={this.props.saving}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardBody>
                    <Form
                      className="needs-validation"
                      noValidate
                      autoComplete="off"
                    >
                      <Row>
                        <Col className="mb-3" md="8">
                          <label
                            className="form-control-label"
                            htmlFor="consultant"
                          >
                            Consultor
                          </label>
                          <Select2
                            className="form-control"
                            type="text"
                            value={this.props.consultant}
                            disabled={
                              this.props.saving ||
                              this.props.crudConsultant !== "C"
                            }
                            onSelect={(e) =>
                              this.props.stylesForm(e, "consultant")
                            }
                            options={{
                              placeholder: "Selecione um consultor",
                              language: {
                                noResults: function () {
                                  return "Nenhum consultor encontrado."
                                },
                              },
                            }}
                            data={this.props.consultantsList}
                          />
                          <Input
                            hidden
                            valid={
                              this.props.customStyles.consultantState ===
                              "valid"
                            }
                            invalid={
                              this.props.customStyles.consultantState ===
                              "invalid"
                            }
                          />
                          <div className="invalid-feedback">
                            Consultor inválido ou já alocado.
                          </div>
                        </Col>
                        <Col className="mb-3" md="4">
                          <label
                            className="form-control-label"
                            htmlFor="consultantEstimatedHours"
                          >
                            Horas Estimadas
                          </label>
                          <Input
                            id="consultantEstimatedHours"
                            placeholder="0"
                            type="text"
                            mask="999:99"
                            tag={InputMask}
                            value={this.props.consultantEstimatedHours}
                            readOnly={this.props.crudConsultant === "D"}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles
                                .consultantEstimatedHoursState === "valid"
                            }
                            invalid={
                              this.props.customStyles
                                .consultantEstimatedHoursState === "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(
                                e,
                                "consultantEstimatedHours"
                              )
                            }
                          />
                          <div className="invalid-feedback">
                            Qtde. horas/minutos inválida.
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          <div className="modal-footer">
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeModalConsultant(e)}
              disabled={this.props.saving}
            >
              {this.props.crudConsultant === "R" ? "Fechar" : "Cancelar"}
            </Button>
            <Button
              color="success"
              type="button"
              onClick={(e) => this.props.saveConsultant(e)}
              disabled={this.props.saving}
              hidden={this.props.crudConsultant === "R"}
            >
              {this.props.crudConsultant === "D" ? "Excluir" : "Salvar"}
            </Button>
          </div>
        </Modal>
      </>
    )
  }
}

export default ActivityConsultant
