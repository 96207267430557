import React from 'react'
import { Col, Form, Input } from 'reactstrap'

export default function BillingForm (props) {
  return (
    <Form className='needs-validation' noValidate autoComplete='off'>
      <div className='form-row'>
        {/* Date */}
        <Col className='mb-3' lg='6' md='12' style={{ alignContent: 'center' }}>
          <label className='form-control-label' htmlFor='date'>
            Data
          </label>
          <Input
            id='date'
            type='date'
            value={props.billingBody.date}
            onChange={e => {
              props.setBillingBody({ date: e.target.value })
            }}
          />
        </Col>
      </div>
    </Form>
  )
}
