import MenuAccessForm from 'components/Forms/MenuAccessForm'
import 'moment/locale/pt-br'
import React, { useEffect, useState } from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import { Button, Card, CardBody, Modal } from 'reactstrap'
import api from 'services/api'

import ConfirmationModal from '../ConfirmationModal'

export default function MenuAccess (props) {
  const [saving, setSaving] = useState(false)
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [confirmationModalAction, setConfirmationModalAction] = useState(false)
  const [menuAccessBody, setMenuAccessBody] = useState(null)
  
  const deleteMenuAccess = async ()=>{
    try {
      const user = await api.post("user/search",{sglProfile : menuAccessBody.code})
      if(user.data.length > 0){
        alert("Esse acesso está sendo usado")
      }else{
        await api.delete("menu/delete/"+menuAccessBody._id)
        props.refetchAccesses()
        props.closeModal()
      }
    } catch (error) {
      console.error(error)
    }
   
  }
  const saveMenuAccess = async ()=>{
    try {
      await api.put("menu/update/"+menuAccessBody._id, menuAccessBody)
      props.refetchAccesses()
      props.closeModal()
    } catch (error) {
      console.error(error)
      alert("ocorreu um erro")
    }
  }

  useEffect(() => {
    if(props.menuAccess){
      setMenuAccessBody({...props.menuAccess})

    }else{
      setMenuAccessBody(null)
    }
  }, [ props.menuAccess])
  
 
  return (
    <Modal
      className='modal-dialog-centered'
      size='lg'
      isOpen={props.isOpen}
      autoFocus={false}
    >
      <div className='modal-header'>
        <h5 className='modal-title'>{props.title}</h5>
        <button
          aria-label='Close'
          className='close'
          data-dismiss='modal'
          type='button'
          onClick={e => props.closeModal()}
          disabled={saving}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <Card>
        <CardBody>
          {
            menuAccessBody && (
            <MenuAccessForm
              disableCode={true}
              menuAccessBody={menuAccessBody}
              setMenuAccessBody={(prop)=>{
                setMenuAccessBody(prev=>({...prev, ...prop}))}}
            ></MenuAccessForm>
            )
          }
         
        </CardBody>
      </Card>
        <div className='modal-footer'>
          <Button
            color='warning'
            data-dismiss='modal'
            type='button'
            id='deleteSchedule'
            onClick={() => {
              setConfirmationModalAction(
              {
                action: 'delete',
                text: `Excluir Permissão de acesso`
              })
              setConfirmationModalOpen(true)
            }}
          >
            Excluir
          </Button>
          <Button
            color='success'
            type='button'
            onClick={e => {
              saveMenuAccess()
            }}
           
          >
            {props.action}
          </Button>
          <ConfirmationModal
            isOpen={confirmationModalOpen}
            action={confirmationModalAction}
            text={'Você tem certeza que deseja excluir esse Acesso?'}
            confirm={() => {
            }}
            closeModal={() => {
              setConfirmationModalOpen(false)
              deleteMenuAccess()
            }}
            deny={() => {
              setConfirmationModalOpen(false)
            }}
          />
        </div>
    </Modal>
  )
}
