import ConsultantClosingForm from "components/Forms/ConsultantClosingForm"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Button, Card, CardBody, Modal, Row } from "reactstrap"
import api from "services/api"

const emptyClosing = {
  date: "",
  consultant: "",
  hours: "0:00",
}

function CreateConsultantClosing({
  isOpen,
  closeModal,
  setLoading,
  refetchTable,
  warningAlert,
}) {
  const [closingBody, setClosingBody] = useState(emptyClosing)
  const [saving, setSaving] = useState(false)
  const [consultantOptions, setConsultantOptions] = useState([])

  const fetchConsultants = useCallback(async () => {
    try {
      const consultantsResponse = await api.post("user/search", { class: "CG" })
      setConsultantOptions(consultantsResponse.data)
    } catch (error) {
      warningAlert({
        color: "danger",
        message: error.response.data.error + "\n" + error.response.data.message,
      })
    }
  }, [setConsultantOptions, warningAlert])

  /**
   * saves closing
   */
  const createClosing = async () => {
    setLoading(true)
    setSaving(true)
    try {
      const checkDateResponse = await api.post("consultantClosing/search", {
        search: {
          date: closingBody.date,
          consultant: closingBody.consultant,
        },
      })
      let regex = new RegExp(/^-?(\d+):([0-5]\d)$/)
      if (!regex.test(closingBody.hours)) {
        warningAlert({
          color: "danger",
          message: "Horas não está no formato HH:mm ",
        })

        return
      }
      if (checkDateResponse.data.length > 0) {
        warningAlert({
          color: "danger",
          message:
            "Já existe um fechamento para esse consultor cadastrado nessa data",
        })

        return
      }

      await api.post("consultantClosing/create", closingBody)
      refetchTable()
      setClosingBody(emptyClosing)
      closeModal()
      warningAlert({
        color: "success",
        message: `Fechamento criado`,
      })
    } catch (error) {
      warningAlert({
        color: "danger",
        message: error.response.data.error + "\n" + error.response.data.message,
      })
    } finally {
      setLoading(false)
      setSaving(false)
    }
  }

  useEffect(() => {
    fetchConsultants()
  }, [fetchConsultants])

  const formValid = useMemo(
    () => closingBody.date !== "" && closingBody.consultant !== "",
    [closingBody]
  )

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={isOpen}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">Criar Fechamento de Consultor</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => {
              setClosingBody(emptyClosing)
              closeModal()
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardBody>
                  <ConsultantClosingForm
                    closingBody={closingBody}
                    consultantOptions={consultantOptions}
                    setClosingProp={(prop) =>
                      setClosingBody((prev) => ({ ...prev, ...prop }))
                    }
                  ></ConsultantClosingForm>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
        <div className="modal-footer">
          <Button
            color="danger"
            data-dismiss="modal"
            type="button"
            onClick={(e) => {
              setClosingBody(emptyClosing)
              closeModal()
            }}
          >
            Cancelar
          </Button>
          <Button
            color="success"
            type="button"
            disabled={saving || !formValid}
            onClick={(e) => createClosing()}
          >
            Salvar
          </Button>
        </div>
      </Modal>
      {alert}
    </>
  )
}

CreateConsultantClosing.propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
}
export default CreateConsultantClosing
