import Tag from "components/Tag/Tag"
import React from "react"
import { Row, Table } from "reactstrap"

function MobileSchedulesTable(props) {
  function formatMobileDate(date) {
    let splitDate = date.split(/[- T]/)
    return `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`
  }

  function getCustomer(id) {
    return props.customers.find((customer) => customer._id === id)
  }
  function getScheduleTagColor(schedule) {
    if (schedule.status === "PA") return "var(--yellow)"
    else if (schedule.status === "RJ") return "#000000"
    let date = new Date()
    const dayOfWeek = date.getDay()
    if (dayOfWeek === 0) {
      date.setDate(date.getDate() - 4)
    } else if (dayOfWeek === 6) {
      date.setDate(date.getDate() - 3)
    } else if (dayOfWeek === 1) {
      date.setDate(date.getDate() - 4)
    } else {
      date.setDate(date.getDate() - 2)
    }
    const isServiceOrderLate = new Date(schedule.date) < date
    return schedule.serviceOrder
      ? "var(--success)"
      : !schedule.serviceOrder && isServiceOrderLate
      ? "var(--warning)"
      : undefined
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Data</th>
          <th>Agenda</th>
        </tr>
      </thead>

      <tbody>
        {props.schedules.map((schedule, index) => {
          return (
            <tr key={index}>
              <td>
                <Row>{formatMobileDate(schedule.date)}</Row>
              </td>
              <td>
                <Row
                  onClick={() => {
                    props.handleScheduleModal(true)
                    props.handleSelectSchedule(schedule)
                  }}
                >
                  <Tag backgroundColor={getScheduleTagColor(schedule)}>
                    {getCustomer(schedule.customer).shortName}
                  </Tag>
                </Row>
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
export default MobileSchedulesTable
