import React from "react"
import { Col, Form, Input } from "reactstrap"
import PropTypes from "prop-types"
import Select2 from "react-select2-wrapper"
import { isEmpty } from "lodash"
import ReactInputMask from "react-input-mask"
import { transformNumberToCurency } from "utils/currencyHandling"

function ThirdPartyAppointmentForm(props) {
  return (
    <Form className="needs-validation" noValidate autoComplete="off">
      <div className="form-row">
        <Col className="mb-3" lg="6" md="12">
          <label className="form-control-label" htmlFor="customer">
            Cliente
          </label>
          <Select2
            type="text"
            id="customer"
            defaultValue={props.customer?._id}
            value={props.customer?._id}
            onChange={(e) => {
              props.selectCustomer(e.target.value)
            }}
            options={{
              size: "small",
              placeholder: "Selecione um Cliente",
              language: {
                noResults: function () {
                  return "Nenhum cliente encontrado."
                },
              },
            }}
            data={props.customers.map((customer) => {
              return {
                id: customer._id,
                text: customer.shortName,
              }
            })}
          />
        </Col>
        <Col className="mb-3" lg="6" md="12">
          <label className="form-control-label" htmlFor="project">
            Projeto
          </label>
          <Select2
            id="project"
            type="text"
            defaultValue={props.project?._id}
            value={props.project?._id}
            onChange={(e) => {
              props.selectProject(e.target.value)
            }}
            disabled={isEmpty(props.customer)}
            options={{
              size: "small",
              placeholder: "Selecione um Projeto",
              language: {
                noResults: function () {
                  return "Nenhum projeto encontrado."
                },
              },
            }}
            data={props.projects.map((project) => {
              return {
                id: project._id,
                text: project.name,
              }
            })}
          />
        </Col>
      </div>
      <div className="form-row">
        <Col className="mb-3" lg="6" md="12">
          <label className="form-control-label" htmlFor="date">
            Terceiro
          </label>
          <Select2
            type="text"
            id="consultant"
            defaultValue={props.appointmentBody.consultant}
            value={props.appointmentBody.consultant}
            onChange={(e) => {
              props.setAppointmentBody({ consultant: e.target.value })
              props.selectConsultant(e.target.value)
            }}
            options={{
              size: "small",
              placeholder: "Selecione um Consultor Terceiro",
              language: {
                noResults: function () {
                  return "Nenhum Consultor encontrado."
                },
              },
            }}
            data={props.consultants.map((consultant) => {
              return {
                id: consultant._id,
                text: consultant.fullName,
              }
            })}
          />
        </Col>

        <Col className="mb-3" lg="6" md="12">
          <label className="form-control-label" htmlFor="activity">
            Atividate
          </label>
          <Select2
            type="text"
            id="activity"
            defaultValue={props.appointmentBody.activity}
            value={props.appointmentBody.activity}
            onChange={(e) => {
              props.setAppointmentBody({ activity: e.target.value })
              props.selectActivity(e.target.value)
            }}
            disabled={
              (isEmpty(props.project), !props.appointmentBody.consultant)
            }
            options={{
              size: "small",
              placeholder: "Selecione uma Atividade",
              language: {
                noResults: function () {
                  return "Nenhuma atividade encontrada."
                },
              },
            }}
            data={props.activities.map((activity) => {
              return {
                id: activity._id,
                text: activity.process,
              }
            })}
          />
        </Col>
      </div>
      <div className="form-row">
        <Col className="mb-3" lg="6" md="12">
          <label className="form-control-label" htmlFor="date">
            Data
          </label>
          <Input
            id="date"
            type="date"
            value={props.appointmentBody.date}
            onChange={(e) => props.setAppointmentBody({ date: e.target.value })}
          />
        </Col>
        <Col className="mb-3" xs="12" md="">
          <label className="form-control-label" htmlFor="hours">
            Horas
          </label>
          <Input
            aria-describedby="inputGroupPrepend"
            id="hours"
            type="text"
            mask="999:99"
            tag={ReactInputMask}
            value={props.appointmentBody.hours}
            disabled={isEmpty(props.consultant)}
            valid={props.appointmentBody.hours !== ""}
            invalid={props.appointmentBody.hours === ""}
            onChange={(e) => {
              props.setAppointmentBody({
                hours: e.target.value.replace(/_/, /0/).replace("/0", "0"),
              })
            }}
          />
          <div className="invalid-feedback">Digite uma hora.</div>
        </Col>
      </div>
      <div className="form-row">
        <Col className="mb-3" lg="6" md="12">
          <label className="form-control-label" htmlFor="hourlyRate">
            Custo/ Hora
          </label>
          <Input
            aria-describedby="inputGroupPrepend"
            id="hourlyRate"
            type="text"
            value={transformNumberToCurency(props.appointmentBody?.hourlyRate)}
            readOnly={true}
          />
        </Col>
        <Col className="mb-3" lg="6" md="12">
          <label className="form-control-label" htmlFor="cost">
            Custo Total
          </label>
          <Input
            aria-describedby="inputGroupPrepend"
            id="cost"
            type="text"
            value={transformNumberToCurency(props.appointmentBody.cost)}
            readOnly={true}
          />
        </Col>
      </div>
      <div className="form-row">
        {/* Date */}

        {/* Descriptio  */}
        <Col className="mb-3" md="12">
          <label className="form-control-label" htmlFor="description">
            Descrição
          </label>
          <Input
            aria-describedby="inputGroupPrepend"
            id="description"
            type="textarea"
            value={props.appointmentBody.description}
            readOnly={props.readOnly}
            disabled={props.saving}
            onChange={(e) => {
              props.setAppointmentBody({ description: e.target.value })
            }}
          />
        </Col>
        {/* Date */}
      </div>
    </Form>
  )
}

ThirdPartyAppointmentForm.propTypes = {
  appointmentBody: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
  customers: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  consultants: PropTypes.array.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectActivity: PropTypes.func.isRequired,
  selectCustomer: PropTypes.func.isRequired,
  selectConsultant: PropTypes.func.isRequired,
  setAppointmentBody: PropTypes.func.isRequired,
}

export default ThirdPartyAppointmentForm
