const { convertMongoDate } = require("./dateHandling");
const { hoursToMinutes } = require("./timeHandling");

const header = [
  {
    value: `CLIENTE`,
  },
  {
    value: `CÓDIGO_PROJETO`,
  },
  {
    value: `NOME_PROJETO`,
  },
  {
    value: `NOME_COORDENADOR`,
  },
  {
    value: `VALOR_HORA`,
  },
  {
    value: `DATA_ENCERRAMENTO`,
  },
  {
    value: `HORAS_VENDIDAS`,
  },
  {
    value: `TOTAL_REALIZADO`,
  },
  {
    value: `SALDO_HORAS`,
  },
  {
    value: `TOTAL_FATURADO`,
  },
  {
    value: `VALOR_TOTAL_PROJETO`,
  },
  {
    value: `PERC_EXEC`,
  },
  {
    value: `COMPROMETIDO`,
  },
  {
    value: `SALDO_COMPROMETIDO`,
  },
  {
    value: `SALDO_VALOR`,
  },
  {
    value: `CUSTO`,
  },
  {
    value: `MARGEM`,
  },
  {
    value: `CUSTO_HORA`,
  },
];

function createDatasetForSpreadSheet(projects) {
  return [
    header,
    ...projects.map((project, index) => {
      let soldHours = hoursToMinutes(project.soldHours) / 60;
      let totalHours = project.activitiesTotal?.totalMinutes / 60 || 0;
      let totalBilled =
        project.contract.installmentsTotal?.totalMinutes / 60 || 0;

      if (!project.projectPercentageOfContract) {
        project.projectPercentageOfContract = 100;
      }

      return [
        {
          value: `${project.customerShortName}`,
          column: "A",
        },
        {
          value: `${project.code}`,
          column: "B",
        },
        {
          value: `${project.name}`,
          column: "C",
        },
        {
          value: `${project.coordinator.fullName}`,
          column: "D",
        },
        {
          value: (project.contract.hourlyRate),
          column: "E",
        },
        {
          value: `${convertMongoDate(project.endDate)}`,
          column: "F",
        },
        {
          value: (soldHours),
          column: "G",
        },
        {
          value: (totalHours),
          column: "H",
        },
        {
          value: (soldHours - totalHours),
          column: "I",
        },
        {
          value: (
            (totalBilled / 100) * project.projectPercentageOfContract
          ),
          column: "J",
        },
        {
          value: (
            ((soldHours * project.contract.hourlyRate) / 100) *
              project.projectPercentageOfContract
          ),
          column: "K",
        },
        {
          value: (1 - totalHours / soldHours),
          column: "L",
        },
        {
          value: (
            ((project.endDate || soldHours - totalHours < 0
              ? 0
              : totalBilled - totalHours) /
              100) *
              project.projectPercentageOfContract
          ),
          column: "M",
        },
        {
          value: (
            ((project.endDate || soldHours - totalHours < 0
              ? 0
              : (totalBilled - totalHours) * project.contract.hourlyRate) /
              100) *
              project.projectPercentageOfContract
          ),
          column: "N",
        },
        {
          value: (
            (((soldHours - totalHours) * project.contract.hourlyRate) / 100) *
              project.projectPercentageOfContract
          ),
          column: "O",
        },
        {
          value: (parseFloat(project.activitiesTotal?.totalCost) || 0.0),
          column: "P",
        },
        {
          value: (
            1 -
              (parseFloat(project.activitiesTotal?.totalCost) || 0) /
                (soldHours * project.contract.hourlyRate)
          ),
          column: "Q",
        },
        {
          value: (
            parseFloat(project.activitiesTotal?.totalCost) / totalHours || 0
          ),
          column: "R",
        },
      ].map((item) => {
        return typeof item.value === "number" &&
          (isNaN(item.value) ||
            item.value === -Infinity ||
            item.value === Infinity)
          ? { column: item.column, value: 0 }
          : item;
      });
    }),
  ];
}

function createDatasetForDownload(dataset) {
  return dataset.map((data) => {
    return data.map((innerData) => {
      return innerData.value;
    });
  });
}

export { createDatasetForSpreadSheet, createDatasetForDownload };
