import api from "services/api";
import LightHeader from "components/Headers/LightHeader";
import Loading from "components/Modals/Loading";
import React, { useState, useMemo, useEffect } from "react";
import { Card, CardBody, CardHeader, Container, Row } from "reactstrap";
import Schedule from "components/Modals/user/Schedule";
import { BrowserView, MobileView } from "react-device-detect";
import { userId } from "services/auth";
import MobileSchedulesTableHeader from "components/SchedulesTableMobile/user/MobileSchedulesTableHeader";
import SchedulesTable from "components/Tables/user/SchedulesTable";
import SchedulesTableHeader from "components/TableHeaders/user/SchedulesTableHeader";
import MobileSchedulesTable from "components/SchedulesTableMobile/user/MobileSchedulesTable";
import ReactBSAlert from "react-bootstrap-sweetalert";

/*
  TODO:
  fix the get all user method to get schedules with all inner data
*/
const now = new Date();
export default function ServiceOrders(props) {
  //component
  const [customers, setCustomers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [adresses, setAddresses] = useState([]);
  const [consultant, setConsultant] = useState([]);
  const [consultantActivities, setConsultantActivities] = useState([]);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [columns, setColumns] = useState([]);
  const [loading, setloading] = useState(false);
  const [alert, setAlert] = useState(null);

  //consultant used on mobile table

  //Schedule modal
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState({});

  const openServiceOrdersReport = () => {};

  async function getUsersAndSchedules() {
    try {
      setloading(true);

      const userResponse = await api.get(
        "/user/read-with-schedules/" + userId()
      );

      const consultant = userResponse.data;

      const schedules = consultant.schedules.filter((schedule) => {
        return schedule.deleted === "N";
      });

      consultant.schedules = schedules;

      setConsultant(consultant);

      const customersResponse = await api.get("/customer/all");
      setCustomers(customersResponse.data);

      const projectsResponse = await api.get("/project/all");
      setProjects(projectsResponse.data);

      const adressResponse = await api.get("/customerAddress/all");
      setAddresses(adressResponse.data);

      const consultantActivitiesConsultantResponse = await api.post(
        "/activityConsultant/search",
        { consultant: userId() }
      );
      setConsultantActivities(consultantActivitiesConsultantResponse.data);
    } catch (error) {
      console.error(error);
    } finally {
      setloading(false);
    }
  }

  useMemo(async () => {
    await getUsersAndSchedules();
    setFrom(
      new Date(now.getFullYear(), now.getMonth(), 1).toISOString().split("T")[0]
    );
    setTo(
      new Date(now.getFullYear(), now.getMonth() + 1, 0)
        .toISOString()
        .split("T")[0]
    );
  }, []);

  function yearsAppartTooBig(to, from) {
    if (!to || !from) return false;

    let yearTo = parseInt(to.split("-")[0]);
    let yearFrom = parseInt(from.split("-")[0]);
    let result = yearTo - yearFrom > 2 || yearTo - yearFrom < 0;
    return result;
  }

  useEffect(() => {
    if (!yearsAppartTooBig(to, from)) {
      for (
        var arr = [], dt = new Date(from + " 00:00");
        dt <= new Date(to + " 00:00");
        dt.setDate(dt.getDate() + 1)
      ) {
        arr.push({
          day: new Date(dt).toLocaleDateString("pt-BR", {
            month: "numeric",
            day: "numeric",
          }),
          weekday: new Date(dt).toLocaleDateString("pt-BR", {
            weekday: "short",
          }),
          fullDate: new Date(dt).toISOString(),
        });
      }
      setColumns(arr);
    }
  }, [to, from]);

  function hideAlert() {
    setAlert(null);
  }
  function showAlert(message, color, title = "Atenção") {
    setAlert(
      <ReactBSAlert
        style={{ display: "block" }}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle={`${color}`}
        confirmBtnText="Ok"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );
  }

  async function requestSchedule(schedule) {
    setloading(true);
    schedule.status = "PA";
    schedule.rejectionReason = "";
    try {
      let scheduleResponse = schedule._id
        ? await api.post("schedule/search", {
            consultant: userId(),
            date: schedule.date,
            project: schedule.project,
            deleted: "N",
            _id: { $ne: schedule._id },
          })
        : await api.post("schedule/search", {
            consultant: userId(),
            date: schedule.date,
            project: schedule.project,
            deleted: "N",
          });
      // ADD "DELETED" FILTER HERE
      if (
        Array.isArray(scheduleResponse.data) &&
        scheduleResponse.data.length > 0
      ) {
        showAlert(
          "Já existe uma solicitação para esse projeto nesse dia ",
          "danger"
        );
        return;
      }
      if (schedule._id) {
        await api.patch("schedule/update/" + schedule._id, schedule);
      } else {
        await api.post("schedule/create", schedule);
      }
      await getUsersAndSchedules();
      setScheduleModalOpen(false);
      showAlert("Agenda requisitada!", "success", "");
    } catch (error) {
      showAlert("Ocorreu um erro ao Requisitar a agenda", "danger");
    } finally {
      setloading(false);
    }
  }

  return (
    <>
      <LightHeader
        title="Ordem de Serviço"
        parent="Cadastros"
        menuTitle="Agenda Consultor"
      />
      <Container className="mt--6" fluid style={{ overFlowY: "scroll" }}>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <BrowserView>
                  <SchedulesTableHeader
                    to={to}
                    from={from}
                    setTo={(to) => {
                      setTo(to);
                    }}
                    setFrom={(from) => {
                      setFrom(from);
                    }}
                    handleOpenServiceOrdersReport={() =>
                      openServiceOrdersReport()
                    }
                    openScheduleRequisition={() => {
                      setSelectedSchedule(null);
                      setScheduleModalOpen(true);
                    }}
                  />
                </BrowserView>
                <MobileView>
                  <MobileSchedulesTableHeader
                    to={to}
                    from={from}
                    setTo={(to) => {
                      setTo(to);
                    }}
                    setFrom={(from) => {
                      setFrom(from);
                    }}
                  />
                </MobileView>
              </CardHeader>
              <CardBody
                style={{
                  overflowX: "auto",
                  padding: "0px 0px 0px 0px",
                }}
              >
                <BrowserView>
                  <SchedulesTable
                    columns={columns ?? []}
                    customers={customers ?? []}
                    projects={projects ?? []}
                    adresses={adresses ?? []}
                    consultant={consultant ?? {}}
                    handleScheduleModal={(value) => {
                      setScheduleModalOpen(value);
                    }}
                    handleSelectSchedule={(schedule) => {
                      setSelectedSchedule(schedule);
                    }}
                  />
                </BrowserView>
                <MobileView>
                  {consultant.schedules && (
                    <MobileSchedulesTable
                      customers={customers ?? []}
                      schedules={consultant.schedules.filter((sh) => {
                        let date = sh.date.split("T")[0];
                        return date <= to && date >= from;
                      })}
                      handleScheduleModal={(value) => {
                        setScheduleModalOpen(value);
                      }}
                      handleSelectSchedule={(schedule) => {
                        setSelectedSchedule(schedule);
                      }}
                    ></MobileSchedulesTable>
                  )}
                </MobileView>
              </CardBody>
            </Card>
          </div>
        </Row>

        <Loading modalOpened={props.isLoading} />

        <Schedule
          isOpen={scheduleModalOpen}
          consultantSchedules={consultant.getUsersAndSchedules}
          title={"Agenda"}
          action="Salvar"
          schedule={selectedSchedule}
          customers={customers}
          adresses={adresses}
          projects={projects}
          consultantActivities={consultantActivities}
          loading={loading}
          saveScheduleRequest={(schedule) => requestSchedule(schedule)}
          updateSchedule={async () => {
            const updatedScheduleResponse = await api.get(
              `/schedule/read/${selectedSchedule._id}`
            );
            setSelectedSchedule(updatedScheduleResponse.data);
            getUsersAndSchedules();
          }}
          updateList={() => {
            getUsersAndSchedules();
          }}
          closeModal={() => {
            setSelectedSchedule(null);
            setScheduleModalOpen(false);
          }}
        />
      </Container>
      <Loading modalOpened={loading} />
      {alert}
    </>
  );
}
