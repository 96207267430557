import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardTitle,
  CardHeader,
  Row,
  Col,
  CardBody,
  CardFooter,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
  Progress,
} from "reactstrap";
import Avatar from "react-avatar";
import { transformNumberToCurency } from "utils/currencyHandling";
import { hoursToMinutes } from "utils/timeHandling";
import { minutesToHours } from "utils/timeHandling";
debugger
class ActivitiesPanel extends React.Component {
  getActivities = (activities) => {
    return activities.map((prop, key) => {
      return (
        <Card className="mb-0 mr-0" key={key}>
          <CardTitle
            className={
              "text-muted mb-0 text-white bg-gradient-" +
              (prop.status === "P"
                ? "danger"
                : prop.status === "I"
                ? "info"
                : "success")
            }
          >
            <Row className="mb-0">
              <Col className="text-left mt-1 mb-0" xs="1">
                <Button
                  className="btn-icon-only text-white"
                  color=""
                  onClick={(e) =>
                    this.props.showOrHideActivity(
                      e,
                      this.props.activities,
                      prop
                    )
                  }
                  size="sm"
                  disabled={this.props.saving}
                >
                  <i
                    className={
                      this.props.openedActivities?.findIndex(
                        (ac) => ac === prop._id
                      ) >= 0
                        ? "ni ni-bold-up"
                        : "ni ni-bold-down"
                    }
                  />
                </Button>
              </Col>
              <Col className="text-left mt-1 px-0" xs="5">
                <p className="mt-0 mb-0 text-sm">
                  <span className="h5 font-weight-bold mt-0 text-white">
                    {prop.code + " - " + prop.process}
                  </span>
                  <span className="h5 mb-0 text-light text-nowrap">
                    {" | " +
                      (prop.status === "P"
                        ? "Pendente"
                        : prop.status === "I"
                        ? "Em Andamento"
                        : "Finalizada")}
                  </span>
                </p>
              </Col>
              <Col className="text-right mt-2 mb-0" xs="5">
                <h6 className="ls-0 text-white mt-0 mb-0 px-0">
                  {"Referência: " +
                    prop.soldHours +
                    " | Estimado: " +
                    minutesToHours(prop.estimatedMinutes) +
                    " | Realizado: " +
                    minutesToHours(prop.performedMinutes) +
                    " | Custo: " +
                    transformNumberToCurency(prop.cost)}
                </h6>
              </Col>
              <Col className="text-right mt-1 mb-0" xs="1">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    color=""
                    role="button"
                    size="sm"
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      disabled={this.props.endDate}
                      onClick={(e) =>
                        this.props.openModalActivity(e, "U", prop)
                      }
                    >
                      <i className="fas fa-edit" />
                      <span>Alterar</span>
                    </DropdownItem>
                    {hoursToMinutes(prop.performedHours) === 0 ? (
                      <>
                        <DropdownItem
                          onClick={(e) =>
                            this.props.openModalActivity(e, "D", prop)
                          }
                        >
                          <i className="fas fa-trash" />
                          <span>Excluir</span>
                        </DropdownItem>
                      </>
                    ) : null}
                    <DropdownItem
                      onClick={(e) =>
                        this.props.openModalActivity(e, "R", prop)
                      }
                    >
                      <i className="fas fa-glasses" />
                      <span>Visualizar</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    {prop.hasActivities ? (
                      <DropdownItem
                        onClick={(e) =>
                          this.props.openModalActivity(e, "C", prop)
                        }
                      >
                        <i className="fas fa-plus" />
                        <span>Sub-Atividade</span>
                      </DropdownItem>
                    ) : (
                      <DropdownItem
                        disabled={this.props.endDate}
                        onClick={(e) =>
                          this.props.openModalConsultant(e, "C", null, prop)
                        }
                      >
                        <i className="fas fa-plus" />
                        <span>Consultor</span>
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
            </Row>
          </CardTitle>
          {!prop.hasActivities &&
          this.props.openedActivities?.findIndex((ac) => ac === prop._id) >=
            0 ? (
            <Table
              className="align-items-center table-flush"
              responsive
              striped
            >
              <thead className="thead-light">
                <tr>
                  <th>Consultor</th>
                  <th>Horas Estimadas</th>
                  <th>Horas Realizadas</th>
                  <th>Custo</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{this.getConsultants(prop.activityConsultants)}</tbody>
            </Table>
          ) : null}
          {prop.hasActivities &&
          this.props.openedActivities?.findIndex((ac) => ac === prop._id) >=
            0 &&
          prop.subActivities ? (
            <CardFooter>{this.getActivities(prop.subActivities)}</CardFooter>
          ) : null}
        </Card>
      );
    });
  };

  getConsultants = (consultants) => {
    return consultants.map((prop, key) => {
      return (
        <tr key={key}>
          <td className="table-user">
            {prop.consultant.image ? (
              <img
                alt="..."
                className="avatar rounded-circle mr-3"
                src={prop.consultant.image.url}
              />
            ) : (
              <Avatar
                className="avatar rounded-circle mr-3"
                name={prop.consultant.fullName}
                maxInitials={2}
                round={true}
                size={48}
                title={" "}
                color={"#172b4d"}
                fgColor={"#11cdef"}
              />
            )}
            <b>{prop.consultant.fullName}</b>
          </td>
          <td>
            <span className="text-muted">{prop.estimatedHours}</span>
          </td>
          <td>
            <span className="text-danger mb-0">
              {minutesToHours(prop.performedMinutes)}
            </span>
          </td>
          <td>
            <span className="text-right">
              {transformNumberToCurency(prop.cost)}
            </span>
          </td>
          <td className="text-right">
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                color=""
                role="button"
                size="sm"
              >
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem
                  onClick={(e) => this.props.openModalConsultant(e, "U", prop)}
                >
                  <i className="fas fa-edit" />
                  <span>Alterar</span>
                </DropdownItem>
                <DropdownItem
                  onClick={(e) => this.props.openAppointmentsModal(e, prop)}
                >
                  <i className="fas fa-list" />
                  <span>Ver Apontamentos</span>
                </DropdownItem>
                {hoursToMinutes(prop.performedHours) === 0 ? (
                  <DropdownItem
                    href="#pablo"
                    onClick={(e) =>
                      this.props.openModalConsultant(e, "D", prop)
                    }
                  >
                    <i className="fas fa-trash" />
                    <span>Excluir</span>
                  </DropdownItem>
                ) : null}
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      );
    });
  };

  render() {
    return (
      <>
        <Card hidden={!this.props.activitiesMaintenence}>
          <CardHeader>
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Painel de Atividades</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button
                  className="btn-round btn-icon"
                  color="primary"
                  id="buttonTogglerActivities"
                  onClick={(e) => {
                    if (!this.props.allActivitiesOpened) {
                      this.props.showAllActivities(e, this.props.activities);
                    } else {
                      this.props.hideAllActivities(e, this.props.activities);
                    }
                  }}
                  size="sm"
                >
                  <i
                    className={
                      this.props.allActivitiesOpened
                        ? "ni ni-bold-up"
                        : "ni ni-bold-down"
                    }
                  />
                  <UncontrolledTooltip
                    delay={0}
                    placement="top"
                    target="buttonTogglerActivities"
                  >
                    {this.props.allActivitiesOpened
                      ? "Ocultar todas"
                      : "Exibir todas"}
                  </UncontrolledTooltip>
                </Button>
                <Button
                  className="btn-round btn-icon"
                  color="primary"
                  onClick={(e) => this.props.openModalActivity(e, "C")}
                  size="sm"
                  disabled={this.props.saving || this.props.endDate}
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-plus" />
                  </span>
                  <span className="btn-inner--text">Nova Atividade</span>
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>{this.getActivities(this.props.activities)}</CardBody>

          {this.props.isLoadingActivities && (
            <div
              className="text-center text-muted my-4"
              style={{ margin: "0 50px" }}
            >
              <span className="text-muted">Carregando atividades...</span>
              <Progress animated color="danger" value="100" />
            </div>
          )}
        </Card>
      </>
    );
  }
}
debugger
export default ActivitiesPanel;
