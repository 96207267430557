import React from 'react'
import { Col, Form, Input } from 'reactstrap'
import { minutesToHours } from 'utils/timeHandling'
import 'assets/css/custom.css'

function ServiceOrderForm (props) {
  return (
    <Form className='needs-validation' noValidate autoComplete='off'>
      <div className='form-row'>
        {/* Date */}
        <Col className='mb-3' lg='6' md='12'>
          <label className='form-control-label' htmlFor='date'>
            Data
          </label>
          <Input
            id='date'
            type='date'
            value={props.serviceOrderBody.date}
            readOnly={true}
            disabled={true}
          />
        </Col>
        <Col className='mb-3' lg='6' md='12'>
          <label className='form-control-label' htmlFor='date'>
            Cliente
          </label>
          <Input
            id='customer'
            type='text'
            value={props.customer || props.serviceOrderBody.customer?.shortName}
            readOnly={true}
            disabled={true}
          />
        </Col>
        <Col className='mb-3' xs='12' md='6'>
          <label className='form-control-label' htmlFor='hours'>
            Horas apontadas
          </label>
          <Input
            aria-describedby='inputGroupPrepend'
            id='hours'
            type='text'
            value={minutesToHours(props.serviceOrderBody.totalMinutes)}
            readOnly={true}
          />
        </Col>
        <div className='form-row'>
          <Col className='mb-3' xs='12' md='2'>
            <label className='form-control-label' htmlFor='timeFrom'>
              Início
            </label>
            <Input
              aria-describedby='inputGroupPrepend'
              id='timeFrom'
              type='time'
              value={props.serviceOrderBody.timeFrom}
              readOnly={props.readOnly}
              disabled={props.saving || props.readOnly}
              onChange={e => {
                props.setServiceOrderBody({ timeFrom: e.target.value })
              }}
            />
          </Col>
          <Col className='mb-3' xs='12' md='2'>
            <label className='form-control-label' htmlFor='timeTo'>
              Término
            </label>
            <Input
              aria-describedby='inputGroupPrepend'
              id='timeTo'
              type='time'
              value={props.serviceOrderBody.timeTo}
              readOnly={props.readOnly}
              disabled={props.saving || props.readOnly}
              onChange={e => {
                props.setServiceOrderBody({ timeTo: e.target.value })
              }}
            />
          </Col>
          <Col className='mb-3' xs='12' md='2'>
            <label className='form-control-label' htmlFor='timeCommute'>
              Traslado
            </label>
            <Input
              aria-describedby='inputGroupPrepend'
              id='timeCommute'
              type='text'
              value={props.serviceOrderBody.timeCommute}
              readOnly={true}
              disabled={props.saving || props.readOnly}
            />
            <div className='invalid-feedback'>Digite uma Hora.</div>
          </Col>
          <Col className='mb-3' xs='12' md='2'>
            <label className='form-control-label' htmlFor='timeOthers'>
              Outros
            </label>
            <Input
              aria-describedby='inputGroupPrepend'
              id='timeOthers'
              type='time'
              className='without_ampm'
              value={props.serviceOrderBody.timeOthers}
              readOnly={props.readOnly}
              disabled={props.saving || props.readOnly}
              onChange={e => {
                props.setServiceOrderBody({ timeOthers: e.target.value })
              }}
            />
          </Col>
          <Col className='mb-3' xs='12' md='2'>
            <label
              className='form-control-label'
              htmlFor='validationCustomUsername'
            >
              Total
            </label>
            <Input
              aria-describedby='inputGroupPrepend'
              id='timeTotal'
              type='text'
              value={props.serviceOrderBody.timeTotal}
              readOnly={true}
              valid={
                props.serviceOrderBody.timeTotal ===
                minutesToHours(props.serviceOrderBody.totalMinutes)
              }
              invalid={
                props.serviceOrderBody.timeTotal !==
                minutesToHours(props.serviceOrderBody.totalMinutes)
              }
            />
            <div className='invalid-feedback'>
              Total deve ser igual as horas apontadas.
            </div>
          </Col>
        </div>
        <Col className='mb-3' md='12'>
          <label className='form-control-label' htmlFor='log'>
            Descrição
          </label>
          <Input
            aria-describedby='inputGroupPrepend'
            id='log'
            type='textarea'
            value={props.serviceOrderBody.description}
            readOnly={true}
            disabled={props.saving || props.readOnly}
            style={{ height: '15vh' }}
          />
        </Col>
        {/* Date */}
      </div>
    </Form>
  )
}

export default ServiceOrderForm
