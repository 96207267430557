import React from "react";
// javascript plugin that creates nice dropzones for files
import Dropzone from "dropzone";
import { uniqueId } from "lodash";
import filesize from "filesize";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  ListGroupItem,
  ListGroup,
  Row,
  Col,
  Input,
} from "reactstrap";

Dropzone.autoDiscover = false;

class MultipleFilesDropzone extends React.Component {
  componentDidMount() {
    let currentMultipleFile = undefined;

    new Dropzone(document.getElementById("dropzone-multiple"), {
      dictDefaultMessage: this.props.instructions,
      dictCancelUploadConfirmation: "Confirma a remoção do arquivo ?",
      url: "https://",
      thumbnailWidth: null,
      thumbnailHeight: null,
      previewsContainer: document.getElementsByClassName(
        "dz-preview-multiple"
      )[0],
      previewTemplate: document.getElementsByClassName("dz-preview-multiple")[0]
        .innerHTML,
      maxFiles: this.props.maxFiles,
      acceptedFiles: this.props.acceptedFiles,
      init:
        this.props.maxFiles > 1
          ? function () {
              this.on("addedfile", function (file) {
                // if (currentMultipleFile) {

                // }
                currentMultipleFile = file;

                const uploadFile = {
                  file,
                  id: uniqueId(),
                  name: file.name,
                  readableSize: filesize(file.size),
                  upload: true,
                  url: null,
                };

                const newFiles = document.getElementById("newFiles");

                if (newFiles !== null) {
                  if (newFiles.uploadfileslist) {
                    if (newFiles.uploadfileslist.length === 0) {
                      newFiles.uploadfileslist = [uploadFile];
                    } else {
                      newFiles.uploadfileslist.push(uploadFile);
                    }
                  } else {
                    newFiles.uploadfileslist = [uploadFile];
                  }
                }
              });
            }
          : function () {
              this.on("addedfile", function (file) {
                if (currentMultipleFile) {
                  this.removeFile(currentMultipleFile);
                }
                currentMultipleFile = file;

                const uploadFile = {
                  file,
                  id: uniqueId(),
                  name: file.name,
                  readableSize: filesize(file.size),
                  upload: true,
                  url: null,
                };

                const newFiles = document.getElementById("newFiles");

                if (newFiles !== null) {
                  newFiles.uploadfileslist = [uploadFile];
                }
              });
            },
      canceled: async (file) => {
        //Remove file from list
        const newFiles = document.getElementById("newFiles");

        if (newFiles !== null) {
          if (newFiles.uploadfileslist) {
            if (newFiles.uploadfileslist.length > 0) {
              for (let i = 0; i < newFiles.uploadfileslist.length; i++) {
                if (newFiles.uploadfileslist[i].file === file) {
                  newFiles.uploadfileslist[i].upload = false;
                }
              }
            }
          }
        }
      },
    });

    document.getElementsByClassName("dz-preview-multiple")[0].innerHTML = "";
  }

  render() {
    return (
      <>
        <div className="card-wrapper">
          <Card>
            <CardBody>
              <div
                className="dropzone dropzone-multiple"
                id="dropzone-multiple"
              >
                <div className="fallback">
                  <div className="custom-file">
                    <input
                      className="custom-file-input"
                      id="customFileUploadMultiple"
                      multiple="multiple"
                      type="file"
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="customFileUploadMultiple"
                    >
                      Choose file
                    </label>
                  </div>
                </div>
                <ListGroup
                  className=" dz-preview dz-preview-multiple list-group-lg"
                  flush
                >
                  <ListGroupItem className=" px-0">
                    <Row className=" align-items-center">
                      <Col className=" col-auto">
                        <img
                          alt="..."
                          className="avatar rounded-circle mr-3"
                          data-dz-thumbnail
                          src="..."
                        />
                      </Col>
                      <div className=" col ml--3">
                        <h4 className=" mb-1" data-dz-name>
                          ...
                        </h4>
                        <p className=" small text-muted mb-0" data-dz-size>
                          ...
                        </p>
                      </div>
                      <Col className=" col-auto">
                        <Button size="sm" color="danger" data-dz-remove>
                          <i className="fas fa-trash" />
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </div>
              <Input id="newFiles" hidden uploadfileslist />
            </CardBody>
          </Card>
        </div>
      </>
    );
  }
}
export default MultipleFilesDropzone;
