import LightHeader from "components/Headers/LightHeader"
import CreateThirdPartyAppointment from "components/Modals/create/CreateThirdPartyAppointment"
import Loading from "components/Modals/Loading"
import ThirdPartyAppointmentTableHeader from "components/TableHeaders/admin/ThirdPartyAppointmentTableHeader"
import React, { useCallback, useEffect, useState } from "react"
import { BrowserView } from "react-device-detect"
import { Card, CardBody, CardHeader, Container, Row } from "reactstrap"
import api from "services/api"
import ReactBSAlert from "react-bootstrap-sweetalert"
import SglTable from "components/ToolkitTable/SglTable"
import { convertMongoDate } from "utils/dateHandling"
import { transformNumberToCurency } from "utils/currencyHandling"
import EditThirdPartyAppointment from "components/Modals/show-edit/ThirdPartyAppointment"

const columns = [
  {
    dataField: "_id",
    text: "Id",
    hidden: true,
  },
  {
    dataField: "date",
    text: "Data",
    sort: true,
    hidden: false,
  },
  {
    dataField: "consultant",
    text: "Terceiro",
    sort: true,
    hidden: false,
  },
  {
    dataField: "customer",
    text: "Cliente",
    sort: true,
    hidden: false,
  },
  {
    dataField: "project",
    text: "Projeto",
    sort: true,
    hidden: false,
  },
  {
    dataField: "hours",
    text: "Horas",
    sort: true,
  },

  {
    dataField: "cost",
    text: "Valor",
    sort: true,
  },
]

function ThirdPartyAppointment() {
  const [loading, setLoading] = useState(false)
  const [appointments, setAppointments] = useState([])
  const [alert, setAlert] = useState(null)
  const [selectedAppointment, setSelectedAppointment] = useState({})

  const [
    createThirdPartyAppointmentModalOpen,
    setCreateThirdPartyAppointmentModalOpen,
  ] = useState(false)

  const [
    editThirdPartyAppointmentModalOpen,
    setEditThirdPartyAppointmentModalOpen,
  ] = useState(false)

  function hideAlert() {
    setAlert(null)
  }

  /**
   *
   * @param {String} id
   */
  const selectAppointment = (id) => {
    const appointment = appointments.find(
      (appointment) => appointment._id === id
    )
    setEditThirdPartyAppointmentModalOpen(true)
    setSelectedAppointment(appointment)
  }

  function showAlert(message, color) {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block" }}
        title="Atenção!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle={`${color}`}
        confirmBtnText="Ok"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    )
  }

  /**
   *
   */
  const fetchAppointments = useCallback(async () => {
    setLoading(true)
    try {
      const appointmentsResponse = await api.get(
        "appointment/allThirdPartyAppointments "
      )
      setAppointments(appointmentsResponse.data || [])
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [])

  /**
   *
   */
  useEffect(() => {
    fetchAppointments()
  }, [fetchAppointments])

  return (
    <>
      <LightHeader
        title="Apontamento de Terceiros"
        parent="Cadastros"
        menuTitle="Apontamento
      de Terceiros"
      />
      <Container className="mt--6" fluid style={{ overFlowY: "scroll" }}>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <ThirdPartyAppointmentTableHeader
                  openCreateAppointment={() =>
                    setCreateThirdPartyAppointmentModalOpen(true)
                  }
                />
              </CardHeader>
              <CardBody>
                <SglTable
                  columns={columns}
                  data={appointments.map((appointment) => {
                    return {
                      _id: appointment._id,
                      consultant: appointment.consultant.fullName,
                      customer: appointment.activity.project.customer.shortName,
                      project: appointment.activity.project.name,
                      hours: appointment.hours,
                      date: convertMongoDate(appointment.date),
                      cost: transformNumberToCurency(appointment.cost),
                    }
                  })}
                  rowClick={(row) => {
                    selectAppointment(row._id)
                  }}
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      {createThirdPartyAppointmentModalOpen && (
        <CreateThirdPartyAppointment
          isOpen={createThirdPartyAppointmentModalOpen}
          updateAppointments={() => fetchAppointments()}
          closeModal={() => setCreateThirdPartyAppointmentModalOpen(false)}
          showAlert={(message) => showAlert(message)}
        />
      )}
      <EditThirdPartyAppointment
        isOpen={editThirdPartyAppointmentModalOpen}
        appointmentBody={selectedAppointment}
        updateAppointments={() => fetchAppointments()}
        closeModal={() => {
          setSelectedAppointment({})
          setEditThirdPartyAppointmentModalOpen(false)
        }}
        showAlert={(message) => showAlert(message)}
      />
      <Loading modalOpened={loading} />
    </>
  )
}

export default ThirdPartyAppointment
