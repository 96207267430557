/**
 *
 * @param {Number} value
 * @returns
 */
function transformNumberToDecimal(value) {
  return Math.round(value).toFixed(2)
}
function transformNumberToCurency(value) {
  if (!value) value = 0
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  }).format(value)
}

export { transformNumberToDecimal, transformNumberToCurency }
