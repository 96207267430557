import LightHeader from "components/Headers/LightHeader"
import Loading from "components/Modals/Loading"
import React, { useCallback, useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import { BrowserView, MobileView } from "react-device-detect"
import ResourceCostManagementForm from "components/Forms/ResourceCostManagmentForm"
import api from "services/api"
import ConfirmationModal from "components/Modals/ConfirmationModal"
import { convertMongoDate } from "utils/dateHandling"
import { transformNumberToCurency } from "utils/currencyHandling"
import ReactBSAlert from "react-bootstrap-sweetalert"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { convertMongoDateAndHour } from "utils/dateHandling"

export default function ResourceCostManagement(props) {
  const [loading, setloading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [consultants, setConsultants] = useState([])

  //form data
  const [consultant, setConsultant] = useState()
  const [dateTo, setDateTo] = useState("")
  const [dateFrom, setDateFrom] = useState("")
  const [newValue, setNewValue] = useState("000.00")

  const [alert, setAlert] = useState(null)

  const [validation, setValidation] = useState({
    consultant: "",
    dateTo: "",
    dateFrom: "",
    newValue: "",
  })

  //confirmationModal

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [confirmationModalAction, setConfirmationModalAction] = useState({
    text: "",
    blockConfirm: false,
  })
  const [confirmationModalText, setConfirmationModalText] = useState(undefined)

  function hideAlert() {
    setAlert(null)
  }

  /**
   *
   * @param {String} message
   */
  function openAlert(message) {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block" }}
        title="Atenção!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="warning"
        confirmBtnText="Ok"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    )
  }
  const save = async () => {
    setSaving(true)

    try {
      await api.patch("user/upadateHourlyRate", {
        consultant: consultant,
        newValue: newValue,
        dateFrom: dateFrom,
        dateTo: dateTo,
      })
      await fetchConsultants()
      let currenConsultantResponse = await api.get(
        "user/read/" + consultant._id
      )
      setConsultant(currenConsultantResponse.data)
      setDateFrom("")
      setDateTo("")
      setNewValue("")
      openAlert("Valor do recurso Alterado")
    } catch (error) {
    } finally {
      setSaving(false)
    }
  }

  /**
   *
   * @param {Number} id
   */
  const findAndSetConsultant = (id) => {
    const consultant = consultants.find((consultant) => consultant._id === id)

    setConsultant(consultant)
  }

  async function openConfirmationModal() {
    setConfirmationModalAction({ text: "Alterar valor do recurso" })
    setConfirmationModalText(
      "Você tem certeza de que quer alterar o valor do recurso?"
    )
    setConfirmationModalOpen(true)
  }

  /**
   *
   */
  const fetchConsultants = useCallback(async () => {
    setloading(true)
    const usersResponse = await api.post("user/search", {
      class: "CG",
      showSchedule: true,
    })
    setConsultants(usersResponse.data)
    setloading(false)
  }, [])

  useEffect(() => {
    fetchConsultants()
  }, [fetchConsultants])

  return (
    <>
      <LightHeader
        title="Reajuste de Custo do Recurso"
        parent="Cadastros"
        menuTitle="Reajuste de Custo do Recurso"
      />
      <Container className="mt--6" fluid style={{ overFlowY: "scroll" }}>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row>
                  <Col
                    className="mt-3"
                    lg="12"
                    style={{ textAlignLast: "end" }}
                  >
                    <Button
                      color="success"
                      type="button"
                      className="mt-3"
                      disabled={
                        !consultant || !dateFrom || !dateTo || !newValue
                      }
                      onClick={(e) => openConfirmationModal()}
                    >
                      Salvar
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody
                style={{
                  overflowX: "auto",
                  padding: "0px 0px 0px 0px",
                  minHeight: "65px",
                }}
              >
                {consultants.length > 0 ? (
                  <ResourceCostManagementForm
                    consultants={consultants}
                    consultant={consultant}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    newValue={newValue}
                    readOnly={false}
                    validation={validation}
                    setDateFrom={(date) => {
                      setDateFrom(date)
                    }}
                    setDateTo={(date) => setDateTo(date)}
                    setNewValue={(newValue) => setNewValue(newValue)}
                    setConsultant={(id) => findAndSetConsultant(id)}
                  />
                ) : null}
                {consultant?.hourlyRateHistory?.length > 0 && !loading ? (
                  <Row className="pl-2 mt-3">
                    <span className="pl-3">
                      <h3>Histórico de alteração</h3>
                    </span>
                    <Table>
                      <thead>
                        <tr>
                          <th>Valor Antigo</th>
                          <th>Valor Novo</th>
                          <th>Data De</th>
                          <th>Data até</th>
                          <th>Data da mudança</th>
                        </tr>
                      </thead>
                      <tbody>
                        {consultant.hourlyRateHistory.map((entry, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {transformNumberToCurency(entry.oldValue)}
                              </td>
                              <td>
                                {transformNumberToCurency(entry.newValue)}
                              </td>
                              <td>{convertMongoDate(entry.dateFrom)}</td>
                              <td>{convertMongoDate(entry.dateTo)}</td>
                              <td>
                                {convertMongoDateAndHour(entry.changeDate)}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </Row>
                ) : loading ? (
                  <Skeleton count={3} />
                ) : null}
              </CardBody>
            </Card>
          </div>
        </Row>

        <Loading modalOpened={props.isLoading} />
      </Container>
      <Loading modalOpened={loading} />
      <ConfirmationModal
        isOpen={confirmationModalOpen}
        action={confirmationModalAction}
        text={confirmationModalText}
        confirm={() => save()}
        deny={() => setConfirmationModalOpen(false)}
        closeModal={() => setConfirmationModalOpen(false)}
      />
    </>
  )
}
