import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import React from "react"
import { Button, Card, CardBody, CardHeader } from "reactstrap"
import { userFullName } from "services/auth"

const imageHeader = require("assets/img/brand/marca-original.png").default

const border = {
  borderStyle: "solid",
  borderColor: "black",
  borderWidth: "1px",
  padding: "0px 5px 0px 5px",
}
const blackTimes = {
  fontFamily: "Times New Roman",
  color: "black",
}

//TODO: Make the PDF itself a component
function ViewPdf(props) {
  function printDocument() {
    const input = document.getElementById("divToPrint")
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png")
      const pdf = new jsPDF()
      pdf.addImage(imgData, "JPEG", 10, 10, 190, 297)
      // pdf.output('dataurlnewwindow');
      pdf.save(
        `${userFullName()}_${
          props.serviceOrder.customer.shortName
        }_${new Date()}.pdf`
      )
    })
  }

  return (
    <div>
      {props.serviceOrder?._id && (
        <>
          <Card>
            <CardHeader>
              {" "}
              <Button
                hidden={props.printAll}
                type="button"
                onClick={(e) => {
                  printDocument()
                }}
              >
                Baixar
              </Button>
            </CardHeader>
            <CardBody>
              <div
                id="divToPrint"
                style={{
                  fontFamily: "Times New Roman",
                  width: "210mm",
                  height: "297mm",
                }}
              >
                {/* header */}
                <div style={{ margin: "auto" }}>
                  <div style={{ ...border, margin: "auto" }}>
                    <div
                      style={{
                        align: "left",
                        display: "inline-block",
                        width: "33%",
                      }}
                    ></div>
                    <div
                      style={{
                        align: "center",
                        display: "inline-block",
                        width: "33%",
                        textAlign: "center",
                        fontWeight: "bolder",
                      }}
                    >
                      <h2
                        style={{
                          ...blackTimes,
                          fontSize: "24px",
                          fontWeight: "bold",
                        }}
                      >
                        Ordem de Serviço
                      </h2>
                    </div>
                    <div
                      style={{
                        align: "right",
                        display: "inline-block",
                        textAlign: "right",
                        width: "33%",
                      }}
                    >
                      <img
                        src={imageHeader}
                        alt={""}
                        style={{ maxWidth: "80%" }}
                      ></img>
                    </div>
                  </div>
                  {/* golive ldta  */}
                  <div
                    style={{
                      ...border,
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        align: "left",
                        display: "inline-block",
                        textAlign: "left",
                        width: "50%",
                      }}
                    >
                      <span
                        style={{
                          ...blackTimes,
                          fontWeight: "bold",
                          fontSize: "15px",
                        }}
                      >
                        GoLive Consultoria Ltda.
                      </span>
                    </div>

                    <div
                      style={{
                        align: "right",
                        display: "inline-block",
                        width: "50%",
                        textAlign: "right",
                      }}
                    >
                      <span style={{ ...blackTimes, fontSize: "15px" }}>
                        CNPJ: 13.994.051/0001-28
                      </span>
                    </div>
                  </div>
                  <div style={{ ...border }}>
                    <span style={{ ...blackTimes, fontSize: "18px" }}>
                      Consultor: {userFullName()}
                    </span>
                  </div>
                  <div style={{ ...border }}>
                    <span style={{ ...blackTimes, fontSize: "18px" }}>
                      Cliente: {props.serviceOrder.customer.shortName}
                    </span>
                  </div>
                  <div style={{ ...border, marginBottom: "10px" }}>
                    <span style={{ ...blackTimes, fontSize: "18px" }}>
                      CNPJ: {props.serviceOrder.customer.cnpj}
                    </span>
                  </div>
                  {/* Date and hours */}
                  <div>
                    <div
                      style={{
                        ...border,
                        width: "50%",
                        minHeight: "80px",
                        display: "inline-block",
                        verticalAlign: "top",
                      }}
                    >
                      <div
                        style={{
                          display: "inline-block",
                          align: "left",
                          width: "20%",
                          verticalAlign: "top",
                        }}
                      >
                        <span style={{ ...blackTimes }}> Data O.S:</span>
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          align: "Right",
                          width: "80%",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ ...blackTimes, fontSize: "30px" }}>
                          {new Date(
                            props.serviceOrder.date.split("T")[0] + " 00:00"
                          ).toLocaleDateString("pt-BR")}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        ...border,
                        borderLeft: "none",
                        borderBottom: "none",
                        width: "50%",
                        display: "inline-block",
                        minHeight: "80px",
                      }}
                    >
                      <span style={{ ...blackTimes }}>
                        Período: {props.serviceOrder.timeFrom} às{" "}
                        {props.serviceOrder.timeTo}
                      </span>
                      <br></br>
                      <span style={{ ...blackTimes }}>
                        Traslado: {props.serviceOrder.timeCommute}{" "}
                      </span>
                      <br></br>

                      <span style={{ ...blackTimes }}>
                        Outros: {props.serviceOrder.timeOthers}
                      </span>
                    </div>
                  </div>
                  <div style={{ marginBottom: "20px" }}>
                    <div
                      style={{
                        width: "50%",
                        display: "inline-block",
                        verticalAlign: "top",
                      }}
                    ></div>
                    <div
                      style={{
                        ...border,
                        width: "50%",
                        display: "inline-block",
                      }}
                    >
                      <span style={{ ...blackTimes }}>Total :</span>{" "}
                      <span
                        style={{
                          ...blackTimes,
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        {props.serviceOrder.timeTotal}
                      </span>
                    </div>
                  </div>
                  {/* Description */}

                  <div style={{ ...border, marginBottom: "20px" }}>
                    <span style={{ ...blackTimes, fontWeight: "bold" }}>
                      Descrição de Atividades:
                    </span>
                  </div>
                  <div style={{ ...border, marginBottom: "20px" }}>
                    <span style={{ ...blackTimes }}>
                      <div
                        style={{ whiteSpace: "pre-wrap" }}
                        dangerouslySetInnerHTML={{
                          __html: props.serviceOrder.description.replace(
                            "\n",
                            "&emsp;"
                          ),
                        }}
                      />
                    </span>{" "}
                  </div>
                  <div style={{ ...border, marginBottom: "50px" }}>
                    <span style={{ ...blackTimes }}>
                      Eventuais restrições quanto ao conteúdo, apontamentos ou
                      outros dados contidos nas Ordens de Serviços aqui listadas
                      devem ser apontadas no verso deste documento. O cliente
                      ainda terá um prazo adicional de 48 horas, contados a
                      partir da emissão desta, para apontar restrições.
                    </span>
                  </div>
                  {/* warning */}
                  <div>
                    <div
                      style={{
                        align: "left",
                        width: "50%",
                        display: "inline-block",
                        textAlign: "center",
                      }}
                    >
                      <span>_____________________________________</span>
                      <br></br>
                      <span
                        style={{
                          ...blackTimes,
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        {props.serviceOrder.consultant.fullName}
                      </span>
                    </div>
                    <div
                      style={{
                        align: "right",
                        width: "50%",
                        display: "inline-block",
                        textAlign: "center",
                      }}
                    >
                      <span>_____________________________________</span>
                      <br></br>
                      <span
                        style={{
                          ...blackTimes,
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        {props.serviceOrder.customer.fullName}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </>
      )}
    </div>
  )
}

export default ViewPdf
