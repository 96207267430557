import LightHeader from "components/Headers/LightHeader"
import ProjectsReportsTableHeader from "components/TableHeaders/admin/ProjectsReportsTableHeader"
import SglTable from "components/ToolkitTable/SglTable"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { BrowserView, MobileView } from "react-device-detect"
import Skeleton from "react-loading-skeleton"
import { Card, CardBody, CardHeader, Container, Row } from "reactstrap"
import api from "services/api"
import { transformNumberToCurency } from "utils/currencyHandling"
import { minutesToHours } from "utils/timeHandling"
const columns = [
  {
    dataField: "_id",
    text: "Id",
    hidden: true,
  },
  {
    dataField: "fullName",
    text: "consultor",
    sort: true,
    hidden: false,
  },
  {
    dataField: "estimatedTime",
    text: "Horas Estimadas",
    sort: true,
  },
  {
    dataField: "performedTime",
    text: "Horas Executadas",
    sort: true,
  },
  {
    dataField: "balance",
    text: "Saldo",
    sort: true,
  },
  {
    dataField: "performedCost",
    text: "Custo Executado",
    sort: true,
  },
]

export default function ProjectsReports() {
  const [customers, setCustomers] = useState([])
  const [customer, setCustomer] = useState(null)
  const [project, setProject] = useState(null)
  const [dateTo, setDateTo] = useState(null)
  const [dateFrom, setDateFrom] = useState(null)
  const [loading, setloading] = useState(false)
  const [consultants, setConsultants] = useState([])

  const projects = useMemo(() => {
    return customer?.projects ? [...customer.projects] : []
  }, [customer])

  const selectCustomer = (id) => {
    setProject(null)
    const cust = customers.find((customer) => customer._id === id)
    setCustomer(cust)
  }

  const fetchProjects = useCallback(async () => {
    const customersResponse = await api.get("customer/allWithProjects")
    setCustomers(customersResponse.data)
  }, [])

  const getTableData = () => {
    let total = {
      estimatedTime: 0,
      performedTime: 0,
      performedCost: 0,
      balance: 0,
    }
    let consultantsData = consultants.map((consultant) => {
      total.estimatedTime += consultant.activitiesTotals.estimatedTime
      total.performedTime += consultant.activitiesTotals.performedTime
      total.performedCost += consultant.activitiesTotals.performedCost
      total.balance +=
        consultant.activitiesTotals.estimatedTime -
        consultant.activitiesTotals.performedTime
      return {
        fullName: consultant.fullName,
        estimatedTime: minutesToHours(
          consultant.activitiesTotals.estimatedTime
        ),
        balance: minutesToHours(
          consultant.activitiesTotals.estimatedTime -
            consultant.activitiesTotals.performedTime
        ),
        performedTime: minutesToHours(
          consultant.activitiesTotals.performedTime
        ),
        performedCost: transformNumberToCurency(
          consultant.activitiesTotals.performedCost
        ),
      }
    })
    consultantsData.push({
      fullName: "TOTAL",
      estimatedTime: minutesToHours(total.estimatedTime),
      performedTime: minutesToHours(total.performedTime),
      balance: minutesToHours(total.balance),
      performedCost: transformNumberToCurency(total.performedCost),
    })
    return consultantsData
  }
  const fetchConsultants = useCallback(async () => {
    setloading(true)
    try {
      const consultantsResponse = await api.post(
        "user/searchForSchedulesTotal",
        {
          project: project,
          dateTo: dateTo,
          dateFrom: dateFrom,
        }
      )
      setConsultants(consultantsResponse.data)
    } catch (error) {
      alert("Ocorreu um erro")
    } finally {
      setloading(false)
    }
  }, [dateFrom, dateTo, project])
  useEffect(() => {
    fetchProjects()
  }, [fetchProjects])

  return (
    <>
      <LightHeader
        title="Projeto Previsto x Realizado"
        menuTitle="Projeto Previsto x Realizado"
        parent="Relatórios"
      />
      <Container className="mt--6 " fluid style={{ overFlowY: "scroll" }}>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <BrowserView>
                  <ProjectsReportsTableHeader
                    projects={projects}
                    customers={customers}
                    project={project}
                    customer={customer}
                    dateTo={dateTo}
                    dateFrom={dateFrom}
                    selectCustomer={(id) => selectCustomer(id)}
                    setProject={(id) => setProject(id)}
                    setDateFrom={(date) => setDateFrom(date)}
                    setDateTo={(date) => setDateTo(date)}
                    fetchConsultants={() => fetchConsultants()}
                  />
                </BrowserView>
                <MobileView></MobileView>
              </CardHeader>
              <CardBody
                style={{
                  overflowX: "auto",
                  minHeight: "10vh",
                  padding: "0px 0px 0px 0px",
                }}
              >
                <BrowserView>
                  {loading ? (
                    <center>
                      <Skeleton
                        count={5}
                        width="95%"
                        height={30}
                        borderRadius="5px"
                        inline
                      />
                    </center>
                  ) : consultants.length > 0 ? (
                    <SglTable
                      className={"invoiceForecastTable"}
                      columns={columns}
                      data={getTableData()}
                      rowClick={(row) => {}}
                    />
                  ) : null}
                </BrowserView>
                <MobileView></MobileView>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
