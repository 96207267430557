import ScheduleForm from "components/Forms/ScheduleForm"
import "moment/locale/pt-br"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import "react-big-calendar/lib/css/react-big-calendar.css"
import "bootstrap/js/src/collapse.js"

import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Modal,
  UncontrolledTooltip,
} from "reactstrap"
import api from "services/api"

import ActivitiesTable from "components/Tables/ActivitiesTable"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

import "react-loading-skeleton/dist/skeleton.css"
import { userId } from "services/auth"
import { minutesToHours } from "utils/timeHandling"
const cleanUser = {
  customer: "",
  address: "",
  date: new Date().toISOString().split("T")[0],
  timeFrom: "00:00",
  timeTo: "00:00",
  timeCommute: "00:00",
  timeOthers: "00:00",
  timeTotal: "00:00",
  billing: false,
  project: "",
  log: [],
}
export default function Schedule(props) {
  // eslint-disable-next-line no-unused-vars
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const [canMakeOs, setCanMakeOs] = useState(false)
  const [consultants, setConsultants] = useState([])
  const [createServiceOrderModalOpen, setCreateServiceOrderModalOpen] =
    useState(false)

  const [loadingActivities, setLoadingActivities] = useState(false)

  const [serviceOrderModalOpen, setServiceOrderModalOpen] = useState(false)

  const [userCustomers, sertUserCustomers] = useState(null)

  const [scheduleBody, setScheduleBody] = useState(cleanUser)

  const fetchConsultants = useCallback(async () => {
    setLoading(true)
    if (!props.isOpen) return
    try {
      const consultantsResponse = await api.get("user/all")
      setConsultants(consultantsResponse.data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [props.isOpen])

  const fetchCustomers = useCallback(async () => {
    setLoading(true)
    if (!props.isOpen) return
    try {
      const customerResponse = await api.post(
        "customer/getConsultantCustomers",
        { consultant: userId() }
      )
      sertUserCustomers(customerResponse.data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [props.isOpen])
  const deleteSchedule = async () => {
    try {
      await api.patch(`schedule/delete/${scheduleBody._id}`, {
        ...scheduleBody,
        user: userId(),
      })
      props.updateList()
      props.closeModal()
    } catch (error) {
      alert("Ocorreu um erro")
    }
  }
  /**
   * Hook to calculate final time
   */
  useEffect(() => {
    let timeArray = [
      scheduleBody.timeTo,
      scheduleBody.timeFrom,
      scheduleBody.timeCommute,
      scheduleBody.timeOthers,
    ]
    let hours = 0,
      minutes = 0
    timeArray.forEach((time, index) => {
      if (!time) return
      let parseTime = time.split(":")
      //on even index we add in not even we subtract
      if (index % 2 === 0) {
        hours += parseInt(parseTime[0])
        minutes += parseInt(parseTime[1])
      } else {
        hours -= parseInt(parseTime[0])
        minutes -= parseInt(parseTime[1])
      }
    })

    const total = hours * 60 + minutes
    if (total <= 0) {
      setScheduleBody((prev) => ({
        ...prev,
        timeTotal: "00:00",
      }))
      return
    }
    setScheduleBody((prev) => ({
      ...prev,
      timeTotal: minutesToHours(total),
    }))
  }, [
    scheduleBody.timeCommute,
    scheduleBody.timeFrom,
    scheduleBody.timeOthers,
    scheduleBody.timeTo,
  ])
  useEffect(() => {
    fetchConsultants()
    fetchCustomers()
    if (props.schedule?._id) {
      props.schedule.date = props.schedule.date.split("T")[0]

      const address = props.adresses.find(
        (ad) => ad._id === props.schedule.address
      )
      setScheduleBody({
        ...props.schedule,
        timeCommute: address?.transfer ?? "00:00",
      })
    } else {
      setScheduleBody((prev) => ({
        consultant: userId(),
        billing: true,
        ...cleanUser,
      }))
    }
  }, [props.adresses, props.schedule, fetchConsultants, fetchCustomers])

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="xl"
        isOpen={props.isOpen}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{props.title}</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => props.closeModal()}
            disabled={saving}
          >
            <span aria-hidden={true}></span>
          </button>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => props.closeModal()}
            disabled={saving}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Card>
          {props.customers && props.adresses && consultants && !loading ? (
            <CardBody>
              <ScheduleForm
                readOnlyFields={["consultant", "billing"]}
                customers={userCustomers || props.customers || []}
                customerAddresses={props.adresses}
                consultants={consultants}
                projects={props.projects}
                scheduleBody={scheduleBody}
                readOnly={
                  scheduleBody._id && scheduleBody.status === "AP"
                    ? true
                    : false
                }
                setScheduleBody={(prop) => {
                  setScheduleBody((prev) => ({ ...prev, ...prop }))
                }}
              />
              {scheduleBody.status === "RJ" ? (
                <>
                  <h3> Agenda Rejeitada:</h3>
                  <div className="form-row">
                    <Col className="mb-3" md="12">
                      <label className="form-control-label" htmlFor="activity">
                        Motivo
                      </label>
                      <Input
                        value={scheduleBody.rejectionReason}
                        aria-describedby="inputGroupPrepend"
                        id="activity"
                        type="textarea"
                        disabled={true}
                      />
                    </Col>
                  </div>
                </>
              ) : (
                <></>
              )}

              {scheduleBody._id && scheduleBody.status === "AP" ? (
                <>
                  <h3>Atividades</h3>
                  <ActivitiesTable
                    schedule={scheduleBody}
                    consultantActivities={props.consultantActivities}
                    createServiceOrderModalOpen={createServiceOrderModalOpen}
                    serviceOrderModalOpen={serviceOrderModalOpen}
                    closeCreateServiceOrderModal={() =>
                      setCreateServiceOrderModalOpen(false)
                    }
                    closeServiceOrderModal={() =>
                      setServiceOrderModalOpen(false)
                    }
                    updateSchedule={() => props.updateSchedule()}
                    canMakeOs={(value) => setCanMakeOs(value)}
                  />
                </>
              ) : (
                <></>
              )}
            </CardBody>
          ) : (
            <center>
              {" "}
              <Skeleton
                count={5}
                width="90%"
                height={30}
                borderRadius="5px"
                inline
              />
            </center>
          )}

          {/* <Loading
            modalOpened={true}
          /> */}
        </Card>
        <div className="modal-footer">
          {scheduleBody._id && scheduleBody.status === "AP" ? (
            <>
              {!canMakeOs && (
                <UncontrolledTooltip delay={0} target="addServiceOrderButton">
                  Não há apontamentos para essa data
                </UncontrolledTooltip>
              )}
              <Button
                id="addServiceOrderButton"
                color="success"
                type="button"
                onClick={(e) => {
                  setCreateServiceOrderModalOpen(true)
                }}
                disabled={saving || !canMakeOs || loadingActivities}
                hidden={scheduleBody.serviceOrder}
              >
                Criar Ordem de serviço
              </Button>
              <Button
                color="success"
                type="button"
                disabled={loadingActivities}
                onClick={(e) => {
                  setServiceOrderModalOpen(true)
                }}
                hidden={!scheduleBody.serviceOrder}
              >
                Ver Ordem de serviço
              </Button>
            </>
          ) : (
            <>
              <Button
                color="warning"
                data-dismiss="modal"
                type="button"
                id="deleteSchedule"
                onClick={() => {
                  deleteSchedule()
                }}
              >
                Excluir
              </Button>
              <Button
                id="createScheduleRequisition"
                color="success"
                type="button"
                disabled={
                  !scheduleBody.consultant ||
                  !scheduleBody.customer ||
                  !scheduleBody.date ||
                  !scheduleBody.activity ||
                  !scheduleBody.date ||
                  !scheduleBody.project ||
                  !scheduleBody.timeTotal ||
                  scheduleBody.timeTotal === "00:00"
                }
                onClick={(e) => {
                  props.saveScheduleRequest(scheduleBody)
                }}
              >
                Requisitar Agenda
              </Button>
            </>
          )}
        </div>
      </Modal>
    </>
  )
}
