import LightHeader from "components/Headers/LightHeader"
import Loading from "components/Modals/Loading"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap"
import api from "services/api"
import ReactBSAlert from "react-bootstrap-sweetalert"
import ServiceDeskTable from "components/Tables/admin/ServiceDeskTable"

import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { transformNumberToCurency } from "utils/currencyHandling"
import { minutesToHours } from "utils/timeHandling"
import ServiceDeskTableHeader from "components/TableHeaders/admin/ServiceDeskTableHeader"
import { data } from "jquery"
import moment from "moment"

export default function ServiceDesk(props) {
  const [loading, setloading] = useState(false)
  const [to, setTo] = useState(null)
  const [from, setFrom] = useState(null)

  const [alert, setAlert] = useState(null)
  const [contracts, setContracts] = useState([])

  //confirmationModal

  const fetchContracts = useCallback(async () => {
    setloading(true)
    try {
      const contractsResponse = await api.post(
        "contract/searchForServiceDeskReport",
        { dateTo: to, dateFrom: from }
      )

      setContracts(contractsResponse.data)

    } catch (error) {
      console.error("Ocorreu um erro")
    } finally {
      setloading(false)
    }
  }, [from, to])


  function hideAlert() {
    setAlert(null)
  }

  const parsedTableData = useMemo(() => {
    let total = {
      customer: "TOTAL",
      hourlyRate: null,
      maxCostHour: null,
      performedHours: 0,
      billedHours: 0,
      maximumCost: 0,
      performedCost: 0,
      difference: 0,
    }


    let parsed = contracts.map((contract) => {

      let custoMedio = 0;
      let custoMedioMinutos = 0;
      let amount = 0;
      let hours = 0;
      let hoursMinutos = 0;
      console.log(contract.customer.shortName)
      for (let i = 0; i < contract.contractinstallments.length; i++) {
        let emissao = new Date(contract.contractinstallments[i].docDate)
        let dataDe = new Date(from + "T00:00:00.000Z")
        let dataAte = new Date(to + "T23:59:59.000Z")

        if (emissao >= dataDe && emissao <= dataAte && contract.contractinstallments[i].deleted === "N" && contract.contractinstallments[i].status === "I") {
    
          amount += contract.contractinstallments[i].amount;
          let hora = contract.contractinstallments[i].hours.split(':')
          hours += parseInt(hora[0])
    
          hoursMinutos += parseInt(hora[1])
          console.log(emissao)
          console.log(contract.contractinstallments[i].amount)
          console.log(contract.contractinstallments[i].hours)
          
        }
      }
      custoMedio = amount / hours;
      custoMedioMinutos = amount / (hours + (hoursMinutos / 60));
      console.log(amount)
      console.log((hours + (hoursMinutos /60)))
      console.log(custoMedioMinutos)
      let horasFaturadas  = (contract.installmentsTotal?.totalTime || 0) / 60
    
      
  
      //let maxCostHour = contract.hourlyRate * 0.4
      let maxCostHour = custoMedioMinutos * 0.4
      let maxCost =
        (custoMedioMinutos *
          0.4 *
          (horasFaturadas || 0)) 
        
      total.maximumCost += maxCost
      total.performedCost += contract.projectTotal.performedCost
      total.difference += maxCost - contract.projectTotal.performedCost
      total.performedHours += contract.projectTotal?.totalTime || 0
      total.billedHours += horasFaturadas || 0
      
      return {
        customer: contract.customer.fullName,
        hourlyRate: transformNumberToCurency(custoMedioMinutos),
        maxCostHour: transformNumberToCurency(maxCostHour),
        performedHours: minutesToHours(contract.projectTotal?.totalTime || 0),
        billedHours: minutesToHours(contract.installmentsTotal?.totalTime || 0),
        maximumCost: transformNumberToCurency(maxCost),
        performedCost: transformNumberToCurency(
          contract.projectTotal.performedCost
        ),
        difference: transformNumberToCurency(
          maxCost - contract.projectTotal.performedCost
        ),
      }
    })
debugger
    parsed.push({
      ...total,
      maximumCost: transformNumberToCurency(total.maximumCost),
      performedCost: transformNumberToCurency(total.performedCost),
      difference: transformNumberToCurency(total.difference),
      billedHours: minutesToHours(total.billedHours * 60 ),
      performedHours: minutesToHours(total.performedHours),

    })
    return parsed
  }, [contracts])

  /**
   *
   * @param {String} message
   */
  function openAlert(message) {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block" }}
        title="Atenção!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="warning"
        confirmBtnText="Ok"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    )
  }

  return (
    <>
      <LightHeader
        title="Análise Service Desk"
        parent="Relatórios"
        menuTitle="Análise Service Desk"
      />
      <Container className="mt--6" fluid style={{ overFlowY: "scroll" }}>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <ServiceDeskTableHeader
                  to={to}
                  from={from}
                  setTo={(value) => setTo(value)}
                  setFrom={(value) => setFrom(value)}
                  fetchContracts={() => fetchContracts()}
                ></ServiceDeskTableHeader>
              </CardHeader>
              <CardBody
                style={{
                  overflowX: "auto",
                  minHeight: "10vh",
                  padding: "0px 0px 0px 0px",
                }}
              >
                {loading ? (
                  <center>
                    {" "}
                    <Skeleton
                      count={10}
                      height={30}
                      borderRadius="5px"
                      inline
                    />
                  </center>
                ) : parsedTableData.length > 0 ? (
                  <ServiceDeskTable data={parsedTableData || []} />
                ) : (
                  <></>
                )}
              </CardBody>
            </Card>
          </div>
        </Row>

        <Loading modalOpened={props.isLoading} />
      </Container>
      <Loading modalOpened={loading} />
      {alert}
    </>
  )
}
