import React from "react"
import { Button, Col, Row } from "reactstrap"
import PropTypes from "prop-types"
import { BrowserView, MobileView } from "react-device-detect"

function ThirdPartyAppointmentTableHeader(props) {
  return (
    <>
      <Row>
        <Col xs="6" lg="6">
          <h3 className="mb-0">Apontamentos de Terceiros</h3>
        </Col>
        <Col xs="6" lg="6" style={{ textAlignLast: "end" }}>
          <BrowserView>
            {" "}
            <Button
              className="btn-round btn-icon"
              color="primary"
              onClick={(e) => props.openCreateAppointment()}
              size="sm"
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-plus" />
              </span>
              <span className="btn-inner--text">Adicionar Apontamento</span>
            </Button>
          </BrowserView>
          <MobileView>
            {" "}
            <Button
              className="btn-round btn-icon"
              color="primary"
              onClick={(e) => props.openCreateAppointment()}
              size="sm"
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-plus" />
              </span>
              <span className="btn-inner--text">Add Apt</span>
            </Button>
          </MobileView>
        </Col>
      </Row>
      <Row></Row>
    </>
  )
}

ThirdPartyAppointmentTableHeader.propTypes = {
  openCreateAppointment: PropTypes.func.isRequired,
}

export default ThirdPartyAppointmentTableHeader
