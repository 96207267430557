import "moment/locale/pt-br";
import React, { useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import PropTypes from "prop-types";

import { Button, Modal, ModalBody } from "reactstrap";
import ConfirmationModal from "./ConfirmationModal";
import PendenciesDetailTable from "components/Tables/PendenciesDetailTable";

function PendenciesModal(props) {
  const [confirmationModalAction, setConformationModalAction] = useState({
    text: "",
    blockConfirm: false,
  });
  const [confirmationModalText, setConformationModalText] = useState("");
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  /**
   *
   */
  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    setConformationModalAction({ text: "", blockConfirm: false });
    setConformationModalText(``);
  };
  /**
   *
   */
  const openConfirmationModal = () => {
    setConformationModalAction({ text: "Envio de email", blockConfirm: false });
    setConformationModalText(
      `Enviar email para ${props.pendency.consultant.email}?`
    );
    setIsConfirmationModalOpen(true);
  };
  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={props.isOpen}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">
            Pendências de {props.pendency?.consultant?.fullName}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => props.closeModal()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <ModalBody style={{ overflowX: "auto" }} id="appointments-table">
          {props.pendency && (
            <PendenciesDetailTable pendency={props.pendency} />
          )}
        </ModalBody>
        <div className="modal-footer">
          <Button
            color="success"
            type="button"
            onClick={(e) => openConfirmationModal()}
          >
            Enviar e-mail
          </Button>
        </div>
      </Modal>
      <ConfirmationModal
        action={confirmationModalAction}
        isOpen={isConfirmationModalOpen}
        text={confirmationModalText}
        confirm={() => props.sendEmail()}
        closeModal={() => closeConfirmationModal()}
        deny={() => closeConfirmationModal()}
      />
    </>
  );
}
PendenciesModal.propTypes = {
  title: PropTypes.string.isRequired,
  pendency: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
};
export default PendenciesModal;
