import ServiceOrderForm from "components/Forms/ServiceOrderForm"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, CardHeader, Modal, Row } from "reactstrap"
import api from "services/api"
import { userId } from "services/auth"
import { convertMongoDate } from "utils/dateHandling"
import { minutesToHours } from "utils/timeHandling"
import { hoursToMinutes } from "utils/timeHandling"
import "../../../assets/css/custom.css"

const emptyServiceOrder = {
  schedule: "",
  activities: "",
  hours: "00:00",
}
function ServiceOrder(props) {
  const [serviceOrderBody, setServiceOrderBody] = useState(emptyServiceOrder)
  const [saving, setSaving] = useState(false)
  const [sanitizedActivities, setSanitizedActivities] = useState([])

  /**
   * hook to filter date activities and removes others dates appointments
   */
  useEffect(() => {
    if (props.activities && props.schedule) {
      let activities = []
      props.activities.forEach((activity) => {
        let appointments = activity.appointments.filter((appointment) => {
          return appointment.date.split("T")[0] === props.schedule.date
        })
        if (appointments.length > 0) {
          activities.push({
            ...activity,
            appointments: appointments,
          })
        }
      })
      setSanitizedActivities(activities)
      props.canMakeOs(activities.length > 0)
    }
  }, [props, props.activities, props.schedule])

  /**
   * hook to get service order to set the hours object
   */
  useEffect(() => {
    async function getServiceOrder() {
      if (props.schedule.serviceOrder) {
        const serviceOrderResponse = await api.get(
          "/serviceOrder/read/" + props.schedule.serviceOrder
        )
        const serviceOrder = serviceOrderResponse.data
        serviceOrder.date = serviceOrder.date
          ? serviceOrder.date.split("T")[0]
          : props.schedule.date
        setServiceOrderBody({
          ...serviceOrder,
          totalMinutes: hoursToMinutes(serviceOrder.timeTotal ?? "00:00"),
        })
      }
    }
    getServiceOrder()
  }, [
    props.schedule.customer,
    props.schedule.date,
    props.schedule.serviceOrder,
  ])
  /**
   * Deletes ServiceOrder, deducts performed hours to activity saves serviceOrderGenerated = false on appointments
   */
  const deleteServiceOrder = async () => {
    setSaving(true)
    try {
      await api.delete("/serviceOrder/delete/" + serviceOrderBody._id)
      await Promise.all(
        sanitizedActivities.map(async (activity) => {
          let totalTime = 0
          await Promise.all(
            activity.appointments.map(async (appointment) => {
              totalTime += hoursToMinutes(appointment.hours)
              return await api.patch(`/appointment/update/${appointment._id}`, {
                ...appointment,
                osCreated: false,
              })
            })
          )
          let activityHours = hoursToMinutes(activity.performedHours)
          //updates activity performed hours
          delete activity.appointments
          let project = activity.project[0]
          delete activity.project

          const activityConsultant = await api.post(
            `/activityConsultant/search/`,
            {
              activity: activity._id,
              consultant: userId(),
            }
          )
          await api.put(`/project/update/${project._id}`, {
            ...project,
            oldCode: project.code,
            performedHours: minutesToHours(
              hoursToMinutes(project.performedHours) - totalTime
            ),
          })
          const performedHours = minutesToHours(activityHours - totalTime)

          await api.put(
            `/activityConsultant/update/${activityConsultant.data[0]._id}`,
            {
              ...activityConsultant.data[0],
              performedHours: performedHours,
            }
          )

          return await api.put(`/activity/update/${activity._id}`, {
            ...activity,
            performedHours: performedHours,
          })
        })
      )
      await api.patch(`/schedule/update/${props.schedule._id}`, {
        ...props.schedule,
        serviceOrder: null,
      })
      props.updateActivities()
      props.updateSchedule()
    } catch (error) {
      alert("ocorreu um erro" + error)
    } finally {
      setSaving(false)
      props.closeModal()
    }
  }
  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={props.isOpen}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="createServiceOrder">
            {props.title}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => props.closeModal()}
            disabled={saving}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardBody>
                  <ServiceOrderForm
                    serviceOrderBody={serviceOrderBody}
                    readOnly={true}
                  />
                  {serviceOrderBody.billed && serviceOrderBody.billing && (
                    <h3 style={{ color: "red" }}>
                      FATURADA* em{" "}
                      {convertMongoDate(serviceOrderBody.billing?.date)}
                      <br></br>
                      CÓDIGO:{serviceOrderBody.billing?.code}
                    </h3>
                  )}
                  {serviceOrderBody.billed && !serviceOrderBody.billing && (
                    <h3 style={{ color: "red" }}>OS já faturada</h3>
                  )}
                </CardBody>
                <CardHeader></CardHeader>
              </Card>
            </div>
          </div>
        </Row>
        <div className="modal-footer">
          <Button
            color="danger"
            type="button"
            onClick={(e) => deleteServiceOrder()}
            disabled={saving || serviceOrderBody.billed}
          >
            Excluir
          </Button>
          <Button color="success" type="button" disabled={saving}>
            <Link
              to={{
                pathname: `/document/${serviceOrderBody._id}`,
                search: `type=os`,
              }}
              target={"_blank"}
            >
              <span style={{ color: "white" }}>Imprimir</span>
            </Link>
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default ServiceOrder
