import LightHeader from "components/Headers/LightHeader";
import Loading from "components/Modals/Loading";
import PendenciesTableHeader from "components/TableHeaders/PendenciesTableHeader";
import AdminPendenciesTable from "components/Tables/admin/PendenciesTable";
import UserPendenciesTable from "components/Tables/user/PendenciesTable";
import { useCallback, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Card, CardBody, CardHeader, Container, Row } from "reactstrap";
import api from "services/api";
import { userId } from "services/auth";
import { userKind } from "services/auth";

import "react-loading-skeleton/dist/skeleton.css";

export default function Pendencies() {
  const [loading, setloading] = useState(false);
  const [pendingPointings, setPendingPointings] = useState([]);
  const [pendingSchedulings, setPendingSchedulings] = useState([]);

  const [isEmailRoutineModalOpen, setIsEmailRoutineModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const fetchPendencies = useCallback(async () => {
    setloading(true);
    let billingsResponse = {};

    let date = new Date();
    const dayOfWeek = date.getDay();
    if (dayOfWeek === 0) {
      date.setDate(date.getDate() - 4);
    } else if (dayOfWeek === 6) {
      date.setDate(date.getDate() - 3);
    } else if (dayOfWeek === 1) {
      date.setDate(date.getDate() - 4);
    } else {
      date.setDate(date.getDate() - 2);
    }

    const getPointingPendencies = async () => {
      let date = new Date();
      const dayOfWeek = date.getDay();
      if (dayOfWeek === 0) {
        date.setDate(date.getDate() - 4);
      } else if (dayOfWeek === 6) {
        date.setDate(date.getDate() - 3);
      } else if (dayOfWeek === 1) {
        date.setDate(date.getDate() - 4);
      } else {
        date.setDate(date.getDate() - 2);
      }
debugger
      if (userKind() === "M") {
        billingsResponse = await api.post("schedule/fullSearch", {
          serviceOrder: null,
          status: "AP",
          date: { $lt: date.toISOString() },
          log: {
            $elemMatch: {
              date: { $lt: date.toISOString() },
              activity: "AP"
            }
          }
        });
      } else if (userKind() === "R") {
        billingsResponse = await api.post("schedule/fullSearch", {
          consultant: userId(),
          serviceOrder: null,
          status: "AP",
          date: { $lt: date.toISOString() },
          log: {
            $elemMatch: {
              date: { $lt: date.toISOString() },
              activity: "AP"
            }
          }
        });
      }
      console.log(billingsResponse.data)
debugger
      setPendingPointings(billingsResponse.data || []);
    };

    const getSchedulePendencies = async () => {
      const fifteenDaysAgo = new Date();
      fifteenDaysAgo.setDate(fifteenDaysAgo.getDate() - 15);

      const twoDaysAgo = new Date();
      twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

      let schedulesList = [];

      if (userKind() === "M") {
        schedulesList = await api
          .post("schedule/fullSearch", {
            date: {
              $gt: fifteenDaysAgo.toISOString(),
              $lt: twoDaysAgo.toISOString(),
            },
          })
          .then((response) => response?.data || []);
      } else if (userKind() === "R") {
        schedulesList = await api
          .post("schedule/fullSearch", {
            consultant: userId(),
            date: {
              $gt: fifteenDaysAgo.toISOString(),
              $lt: twoDaysAgo.toISOString(),
            },
          })
          .then((response) => response?.data || []);
      }

      const holidayList = await api
        .post("holliday/search", {
          date: {
            $gte: fifteenDaysAgo.toISOString(),
            $lt: twoDaysAgo.toISOString(),
          },
        })
        .then((response) => response?.data || []);
debugger
      const userSortedSchedulePendencies = {};
      schedulesList.forEach(
        (scheduleInList) =>
          (userSortedSchedulePendencies[scheduleInList.consultant?._id] = {
            ...scheduleInList.consultant,
            pendingSchedulings: [],
          })
      );
debugger
      fifteenDaysAgo.setDate(fifteenDaysAgo.getDate() + 1);
      const currentDate = fifteenDaysAgo;
      const endDate = twoDaysAgo;

      while (currentDate <= endDate) {
        const formattedDate = currentDate.toISOString()?.split?.("T")?.[0];
        const dateIsHolidayOrWeekend =
          currentDate.getDay() === 6 ||
          currentDate.getDay() === 0 ||
          holidayList.some(
            (holidayInList) =>
              holidayInList.date?.split?.("T")?.[0] === formattedDate
          );

        if (dateIsHolidayOrWeekend) {
          currentDate.setDate(currentDate.getDate() + 1);
          continue;
        }

        const schedulesInThisDate = schedulesList.filter(
          (scheduleInList) =>
            scheduleInList.date?.split?.("T")?.[0] === formattedDate
        );

        Object.keys(userSortedSchedulePendencies).forEach((consultantId) => {
          debugger
          schedulesInThisDate.some((scheduleInList) => {
            const scheduleIsFromConsultant =
              scheduleInList.consultant?._id === consultantId;

            if (!scheduleIsFromConsultant) {
              return false;
            }

            const scheduleCreatedAt = new Date(scheduleInList.createdAt);
            scheduleCreatedAt.setHours(0, 0, 0, 0);
            const pointedDatePlusOneDay = new Date(scheduleInList.date);
            pointedDatePlusOneDay.setHours(0, 0, 0, 0);
            pointedDatePlusOneDay.setDate(pointedDatePlusOneDay.getDate() + 1);

            const wasScheduledInTime =
              scheduleCreatedAt <= pointedDatePlusOneDay;

            let scheduleFoundIsValid = true;

            if (!wasScheduledInTime) {
              debugger
              const timeDifferenceInMilliseconds =
                pointedDatePlusOneDay.getTime() - scheduleCreatedAt.getTime();
              const daysDifference = Math.floor(
                Math.abs(timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24))
              );

              for (
                let howManyDays = 1;
                howManyDays < daysDifference;
                howManyDays++
              ) {
                const dayToCheck = new Date(scheduleCreatedAt);
                dayToCheck.setDate(dayToCheck.getDate() - howManyDays);
                debugger
                const dayWasHolidayOrWeekend =
                  dayToCheck.getDay() === 6 ||
                  dayToCheck.getDay() === 0 ||
                  holidayList.some(
                    (holidayInList) =>
                      holidayInList.date?.split?.("T")?.[0] ===
                      dayToCheck.toISOString()?.split?.("T")?.[0]
                  );

                if (!dayWasHolidayOrWeekend) {
                  userSortedSchedulePendencies[
                    consultantId
                  ]?.pendingSchedulings?.push?.({
                    date: formattedDate,
                    createdAt: scheduleCreatedAt
                      ?.toISOString?.()
                      ?.split?.("T")?.[0],
               
                      timeFrom: scheduleInList.timeFrom,
                      timeTo: scheduleInList.timeTo,
                      timeTotal: scheduleInList.timeTotal,
                      customer: scheduleInList.customer.fullName,
                      project: scheduleInList.project.name,
                  
                      
                  });

                  break;
                }
              }
            }

            return scheduleFoundIsValid;
          });
        });

        currentDate.setDate(currentDate.getDate() + 1);
      }

      Object.keys(userSortedSchedulePendencies).forEach((consultantId) => {
        if (
          !(
            userSortedSchedulePendencies?.[consultantId]?.pendingSchedulings
              ?.length > 0
          )
        ) {
          delete userSortedSchedulePendencies[consultantId];
        }
      });
      setPendingSchedulings(userSortedSchedulePendencies);
    };

    try {
      await getPointingPendencies();
      await getSchedulePendencies();
    } catch (error) {
      console.error(error);
    } finally {
      setloading(false);
    }
  }, []);
  /**
   *
   */
  useEffect(() => {
    fetchPendencies();
    return () => {
      setPendingPointings([]);
    };
  }, [fetchPendencies]);

  return (
    <>
      <LightHeader
        title="Pendências"
        menuTitle="Pendências"
        parent="Relatórios"
      />
      <Container className="mt--6" fluid style={{ overFlowY: "scroll" }}>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <PendenciesTableHeader
                  openEmailRoutineModal={() => {
                    setIsEmailRoutineModalOpen(true);
                  }}
                  openConfirmationModal={() => {
                    setIsConfirmationModalOpen(true);
                  }}
                />
              </CardHeader>
              <CardBody
                style={{
                  padding: "0px 0px 0px 0px",
                  height: "auto",
                  minHeight: "65px",
                }}
              >
                {console.log(pendingPointings,"pendingPointings")}
                {console.log(userKind(),"pendingPointings")}
                {userKind() === "M" ? (
                  <AdminPendenciesTable
                    isEmailRoutineModalOpen={isEmailRoutineModalOpen}
                    isConfirmationModalOpen={isConfirmationModalOpen}
                    closeEmailRoutineModal={() => {
                      setIsEmailRoutineModalOpen(false);
                    }}
                    closeConfirmationModal={() => {
                      setIsConfirmationModalOpen(false);
                    }}
                    pendingPointings={pendingPointings}
                    pendingSchedulings={pendingSchedulings}
                  />
                ) : userKind() === "R" && pendingPointings.length > 0 ? (
                  <UserPendenciesTable pendencies={pendingPointings} />
                ) : loading ? (
                  <center>
                    <Skeleton
                      count={5}
                      width="90%"
                      height={30}
                      borderRadius="5px"
                      inline
                    />
                  </center>
                ) : (
                  <h4 className="mt-2 ml-4">Não há pendencias</h4>
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Loading modalOpened={loading} />
    </>
  );
}
