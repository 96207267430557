import React, { useCallback, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Button, Card, CardBody, Modal, Row } from "reactstrap"
import ConfirmationModal from "../ConfirmationModal"
import api from "services/api"
import ThirdPartyAppointmentForm from "components/Forms/ThirdPartyAppointmentForm"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { hoursToMinutes } from "utils/timeHandling"

const emptyAppointment = {
  activity: "",
  consultant: "",
  cost: null,
  hourlyRate: null,
  date: null,
  description: "",
  hours: "000:00",
}

function CreateThirdPartyAppointment(props) {
  const [loading, setLoading] = useState(false)
  const [customers, setCustomers] = useState([])
  const [consultants, setConsultants] = useState([])
  const [activities, setActivities] = useState([])
  const [selectedProject, setSelectedProject] = useState({})
  const [selectedCustomer, setSelectedCustomer] = useState({})
  const [selectedConsultant, setSelectedConsultant] = useState({})
  const [selectedActivity, setSelectedActivity] = useState({})
  const [appointmentBody, setAppointmentBody] = useState({
    ...emptyAppointment,
  })

  const [confirmationModalAction, setConformationModalAction] = useState({
    text: "",
    blockConfirm: false,
  })
  const [confirmationModalText, setConformationModalText] = useState("")
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  const save = async () => {
    setLoading(true)
    try {
      if (selectedActivity.hoursSettled) {
        const activityConsultantResponse = await api.post(
          "activityConsultant/search",
          {
            activity: appointmentBody.activity,
            consultant: appointmentBody.consultant,
          }
        )
        let estimatedHurs = hoursToMinutes(
          activityConsultantResponse.data[0].estimatedHours
        )
        let performedHours = hoursToMinutes(
          activityConsultantResponse.data[0].performedHours
        )
        let appointmentHours = hoursToMinutes(appointmentBody.hours)
        if (estimatedHurs - (appointmentHours + performedHours) < 0) {
          alert(
            "Apontamento não pode ser salvo por que não há horas estimadas o suficiente"
          )
          return
        }
      }

      await api.post("appointment/create", {
        ...appointmentBody,
        thirdParty: true,
      })
      await api.patch("activity/patch/" + appointmentBody.activity, {
        status: "I",
      })
      props.showAlert("Apontamento salvo", "success")
      props.updateAppointments()
      setAppointmentBody(emptyAppointment)
      props.closeModal()
    } catch (error) {
      console.error(error)
      props.showAlert("Ocorreu um erro", "danger")
      alert("Ocorreu um erro")
    } finally {
      setLoading(false)
    }
  }

  /**
   *
   */
  const openConfirmationModal = () => {
    setConformationModalAction({
      text: "Criar Apontamento de terceiro",
      blockConfirm: false,
    })
    setConformationModalText(`Deseja criar apontamento para o terceiro?`)
    setIsConfirmationModalOpen(true)
  }
  /**
   *
   */
  const fetchCustomers = useCallback(async () => {
    const customersResponse = await api.get("customer/allWithProjects")
    setCustomers(customersResponse.data)
  }, [])
  /**
   *
   */
  const fetchConsultants = useCallback(async () => {
    const consultantsResponse = await api.post("user/search", {
      class: "TP",
      active: true,
    })

    setConsultants(consultantsResponse.data)
  }, [])
  /**
   *
   */
  const fetchActivities = useCallback(async () => {
    if ((appointmentBody.consultant, selectedProject?.activities?.length > 0)) {
      const consultantsResponse = await api.post("activityConsultant/search", {
        consultant: appointmentBody.consultant,
      })
      const responseActivities = consultantsResponse.data.map(
        (ac) => ac.activity
      )
      let activities = selectedProject.activities.filter((activity) =>
        responseActivities.find((ac) => ac._id === activity._id)
      )

      setActivities(activities)
    } else {
      setActivities([])
    }
  }, [appointmentBody.consultant, selectedProject])
  /**
   *
   * @param {String} id
   */
  const selectActivity = (id) => {
    const activity = activities.find((activity) => activity._id === id)
    setSelectedActivity(activity)
  }
  /**
   *
   * @param {String} id
   */
  const selectProject = (id) => {
    const project = projects.find((project) => project._id === id)
    setSelectedProject(project)
  }
  /**
   *
   * @param {String} id
   */
  const selectCustomer = (id) => {
    const customer = customers.find((customer) => customer._id === id)
    setSelectedCustomer(customer)
  }
  /**
   *
   * @param {String} id
   */
  const selectConsultant = (id) => {
    const consultant = consultants.find((consultant) => consultant._id === id)
    if (!consultant) return
    setAppointmentBody((prev) => ({
      ...prev,
      hourlyRate: consultant.hourlyRate,
    }))
    setSelectedConsultant(consultant)
  }
  /**
   *
   * @param {Object} prop
   */
  const updateAppointmentBody = (prop) => {
    if (prop.hours) {
      const minutes = hoursToMinutes(prop.hours)
      const cost = (minutes / 60) * appointmentBody.hourlyRate
      setAppointmentBody((prev) => ({ ...prev, ...prop, cost: cost }))
    }

    setAppointmentBody((prev) => ({ ...prev, ...prop }))
  }
  useEffect(() => {
    fetchConsultants()
  }, [fetchConsultants])
  useEffect(() => {
    fetchActivities()
  }, [fetchActivities, appointmentBody.consultant])

  /**
   *
   */
  const projects = useMemo(() => {
    return selectedCustomer?.projects ? [...selectedCustomer.projects] : []
  }, [selectedCustomer])

  /**
   *
   */
  const formValidated = useMemo(() => {
    return !(
      appointmentBody.activity === "" ||
      appointmentBody.consultant === "" ||
      !appointmentBody.cost ||
      !appointmentBody.hourlyRate ||
      !appointmentBody.date ||
      appointmentBody.description === "" ||
      appointmentBody.hours === "000:00"
    )
  }, [appointmentBody])

  useEffect(() => {
    setLoading(true)
    fetchCustomers()
    setLoading(false)
  }, [fetchCustomers, fetchConsultants])
  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={props.isOpen}
      autoFocus={false}
    >
      <div className="modal-header">
        <h5 className="modal-title">Criação de Apontamento de Terceiros</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={(e) => {
            props.closeModal()
            setAppointmentBody(emptyAppointment)
          }}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <Row>
        <div className="col">
          <div className="card-wrapper">
            <Card>
              <CardBody>
                {loading ? (
                  <center>
                    <Skeleton
                      count={5}
                      width="95%"
                      height={30}
                      borderRadius="5px"
                      inline
                    />
                  </center>
                ) : (
                  <ThirdPartyAppointmentForm
                    projects={projects}
                    customers={customers}
                    activities={activities}
                    customer={selectedCustomer}
                    consultant={selectedConsultant}
                    project={selectedProject}
                    consultants={consultants}
                    appointmentBody={appointmentBody}
                    setAppointmentBody={(prop) => {
                      updateAppointmentBody(prop)
                    }}
                    selectProject={(id) => selectProject(id)}
                    selectCustomer={(id) => selectCustomer(id)}
                    selectConsultant={(id) => selectConsultant(id)}
                    selectActivity={(id) => selectActivity(id)}
                  />
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      </Row>
      <div className="modal-footer">
        <Button
          color="success"
          type="button"
          disabled={!formValidated || loading}
          onClick={(e) => openConfirmationModal()}
        >
          Criar
        </Button>
        <ConfirmationModal
          isOpen={isConfirmationModalOpen}
          action={confirmationModalAction}
          text={confirmationModalText}
          closeModal={() => {
            setIsConfirmationModalOpen(false)
          }}
          confirm={async () => save()}
          deny={() => {
            setIsConfirmationModalOpen(false)
          }}
        />
      </div>
    </Modal>
  )
}

CreateThirdPartyAppointment.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  updateAppointments: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
}

export default CreateThirdPartyAppointment
