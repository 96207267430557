import PropTypes from "prop-types"
import SglTable from "components/ToolkitTable/SglTable"
import React, { useState } from "react"
import { Col, Row, Table } from "reactstrap"
import { convertMongoDate } from "utils/dateHandling"
import Skeleton from "react-loading-skeleton"

function ConsultantClosingsTable({ closings, rowClick, isLoading }) {
  const columns = [
    {
      dataField: "date",
      text: "Data",
      sort: true,
      hidden: false,
      formatter: (cell) => {
        return convertMongoDate(cell)
      },
    },
    {
      dataField: "consultant.fullName",
      text: "Consultor",
      sort: true,
    },
    {
      dataField: "hours",
      text: "Horas",
      sort: true,
      hidden: false,
    },
  ]
  if (isLoading)
    return (
      <center>
        <Skeleton
          count={10}
          width="95%"
          height={30}
          borderRadius="5px"
          inline
        />
      </center>
    )
  else if ((!isLoading && !Array.isArray(closings)) || closings.length < 1)
    return <h2 className="mx-4 my-2">Não há fechamentos cadastrados</h2>
  else
    return (
      <>
        <Row>
          <Col xs={12}>
            {closings.length > 0 && (
              <SglTable
                columns={columns}
                data={closings}
                rowClick={(row) => rowClick(row)}
              />
            )}
          </Col>
        </Row>
      </>
    )
}

ConsultantClosingsTable.propTypes = {
  hollidays: PropTypes.shape({
    length: PropTypes.number,
  }),
  rowClick: PropTypes.func,
}

export default ConsultantClosingsTable
