import PropTypes from "prop-types"
import SglTable from "components/ToolkitTable/SglTable"
import React, { useEffect, useMemo, useState } from "react"
import { Col, Row, Table } from "reactstrap"
import { convertMongoDate } from "utils/dateHandling"

function NationalHollidaysTable({
  hollidays,
  rowClick,
  changeHollidaySelected,
}) {
  const columns = useMemo(
    () => [
      {
        dataField: "date",
        text: "Data",
        sort: true,
        hidden: false,
        editable: false,
        formatter: (cell) => {
          return convertMongoDate(cell)
        },
      },
      {
        dataField: "reason",
        text: "Motivo",
        sort: true,
        editable: false,
      },
      {
        dataField: "hours",
        text: "Horas",
        sort: true,
        editable: false,
      },
      {
        dataField: "showSchedule",
        text: "Selecionado",
        sort: true,
        formatter: (cell, row, rowIndex) => {
          return (
            <label className="custom-toggle custom-toggle-default">
              <input
                defaultChecked={row.selected}
                type="checkbox"
                onChange={(event) => changeHollidaySelected(rowIndex)}
              />
              <span
                className="custom-toggle-slider rounded-circle"
                data-label-off="Não"
                data-label-on="Sim"
              />
            </label>
          )
        },
      },
    ],
    [changeHollidaySelected]
  )
  if (!Array.isArray(hollidays) || hollidays.length < 1)
    return <h2 className="mx-4 my-2">Escolha um ano</h2>
  return (
    <>
      <Row>
        <Col xs={12}>
          {hollidays.length > 0 && (
            <SglTable
              columns={columns}
              data={hollidays.map((holliday) => ({
                ...holliday,
              }))}
              rowClick={(row) => {}}
              cellClick={(cell) => {
                console.log(cell)
              }}
            />
          )}
        </Col>
      </Row>
    </>
  )
}

NationalHollidaysTable.propTypes = {
  hollidays: PropTypes.shape({
    length: PropTypes.number,
  }),
  rowClick: PropTypes.func,
}

export default NationalHollidaysTable
