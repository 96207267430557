import "moment/locale/pt-br"
import React from "react"
import "react-big-calendar/lib/css/react-big-calendar.css"
import { Link } from "react-router-dom"

import { Button, Col, Modal, ModalBody, Table } from "reactstrap"
import { convertMongoDate } from "utils/dateHandling"

export default function Appointments(props) {
  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={props.isOpen}
      autoFocus={false}
    >
      <div className="modal-header">
        <h5 className="modal-title">
          Apontamentos da atividade {props.consultantActivity.code} -{" "}
          {props.consultantActivity.process}
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={(e) => props.closeModal()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <ModalBody>
        <Col xs={12} style={{ marginTop: "5vh" }}>
          <Table>
            <thead>
              <tr>
                <th style={{ width: "10%" }}>Ação</th>
                <th style={{ width: "10%" }}>Data</th>
                <th style={{ width: "10%" }}>Horas</th>
                <th style={{ width: "10%" }}>Agenda</th>
                <th style={{ width: "60%" }}>Descrição</th>
              </tr>
            </thead>
            <tbody>
              {props.appointments.map((appointment, index) => (
                <tr key={index}>
                  <td>
                    {appointment.schedule ? (
                      <Link
                        to={{
                          pathname: `/document/${appointment.schedule.serviceOrder}`,
                          search: `type=os`,
                        }}
                        target={"_blank"}
                      >
                        <Button size="sm">
                          <i className="fas fa-print" />
                        </Button>
                      </Link>
                    ) : (
                      <center>-</center>
                    )}
                  </td>
                  <td>{convertMongoDate(appointment.date)}</td>
                  <td>{appointment.hours}</td>
                  <td>
                    {appointment.schedule ? (
                      convertMongoDate(appointment.schedule.date)
                    ) : (
                      <center>-</center>
                    )}{" "}
                  </td>
                  <td
                    style={{
                      overflow: "hidden",
                      maxWidth: "0",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {appointment.description}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </ModalBody>
    </Modal>
  )
}
