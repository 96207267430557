import BillingDetailTable from "components/Tables/admin/BillingDetailTable";
import { useCallback, useEffect, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import "react-loading-skeleton/dist/skeleton.css";
import { Button, Card, CardBody, Modal } from "reactstrap";
import api from "services/api";
import apiSap from "services/apiSap";
import { transformNumberToCurency } from "utils/currencyHandling";
import { convertMongoDate } from "utils/dateHandling";
import ConfirmationModal from "../ConfirmationModal";
import Loading from "../Loading";
import SapLoginModal from "../admin/SapLoginModal";
import { result, toInteger } from "lodash";
import Billings from "views/pages/browsers/admin/Billings";

function BillingDetail(props) {
  const [loading, setLoading] = useState(false);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [sapLoginModalOpen, setsapLoginModalOpen] = useState(false);
  const [loginSapOpen, setloginSapOpen] = useState(false);
  const [status, setStatus] = useState(null);
  const [autorizado, setAutorizado] = useState(null);
  const [confirmationModalAction, setConfirmationModalAction] = useState({});
  const [alert, setAlert] = useState(null);

  // sap login modal
  const [operation, setOPeration] = useState("");

  const warningAlert = (props) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block" }}
        title="Atenção!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle={props.color}
        confirmBtnText="Ok"
        btnSize=""
      >
        {props.message}
      </ReactBSAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };

  const deleteBilling = async () => {
    setLoading(true);
    try {

      await api.delete("billing/delete/" + props.billing._id);

      await Promise.all(
        props.billing.serviceOrders.map(async (so) => {
          return await api.patch("serviceOrder/patch/" + so, {
            billed: false,
            billing: null,
          });
        })
      );
      await Promise.all(
        props.billing.contractInstallments.map(async (ca) => {
          return await api.patch("contractInstallment/patch/" + ca, {
            status: "P",
            billing: null,
            billingDate: null,
          });
        })
      );
      warningAlert({ color: "success", message: "O faturamento foi deletado" });
      props.updateBillings();
      props.closeModal();
    } catch (error) {
      alert("Ocorreu um erro\n " + error);
    } finally {
      setLoading(false);
    }
  };

  function getDueDate(dias) {
    let bilingDate = new Date(props.billing.date);
    let dueDate = new Date();
    dueDate.setDate(bilingDate.getDate() + dias + 1);

    return dueDate.toLocaleDateString("pt-BR");
  }

  async function sendInvoiceSAP() {

    try {
      let sugeitoIRRF = "N";
      let cnpj = false;
      let textoAlternativoRDC = null;
      setLoading(true);
      let index = "";
      if (props.billing.invoices[0].customer.cnpj !== "13.994.051/0001-28") {
        index = 0;
      } else {
        index = 1;
      }

      if (!props.billing.invoices[index].sapDocEntry) {
        let countItem = 0;
        let pn = 0;
        let quantidadeContas = 0; // quantidades de PN com Contas Cadastradas
        let countGolive = 0;
        let serviceNull = [];
        let i = 0;
        let countCostumer = props.billing.invoices.length; // QUANTIDADE DE NOTAS
        // for (const item of props.billing.invoices) {
        for (let linha = 0; linha < props.billing.invoices.length; linha++) {
          const item = props.billing.invoices[linha];


          if (item.customer.cnpj !== "13.994.051/0001-28") {
            let business;
            let documentoFormatado = item.customer.cnpj.replace(
              /[.\-\/_]/g,
              ""
            );

            let docCount = documentoFormatado.length;
            let cpf = 11;

            if (docCount === cpf) {
              let cpfFormatado =
                documentoFormatado.substring(0, 3) +
                "." +
                documentoFormatado.substring(3, 6) +
                "." +
                documentoFormatado.substring(6, 9) +
                "-" +
                documentoFormatado.substring(9, 11);
              business = await apiSap.get(
                `/$crossjoin(BusinessPartners,BusinessPartners/BPFiscalTaxIDCollection)?$expand=BusinessPartners($select=CardCode,HouseBankBranch,HouseBankAccount)&$filter=BusinessPartners/CardCode eq BusinessPartners/BPFiscalTaxIDCollection/BPCode and BusinessPartners/BPFiscalTaxIDCollection/TaxId4 eq '${cpfFormatado}' and BusinessPartners/CardType eq 'cCustomer'`

              );
              cnpj = false;
              sugeitoIRRF = "N";
              
            } else {
              business = await apiSap.get(
                `/$crossjoin(BusinessPartners,BusinessPartners/BPFiscalTaxIDCollection)?$expand=BusinessPartners($select=CardCode,HouseBankBranch,HouseBankAccount)&$filter=BusinessPartners/CardCode eq BusinessPartners/BPFiscalTaxIDCollection/BPCode and BusinessPartners/BPFiscalTaxIDCollection/TaxId0 eq '${item.customer.cnpj}' and BusinessPartners/CardType eq 'cCustomer'`
              );
              cnpj = true;
              sugeitoIRRF = "Y";
              
            }

            if (business.data.value?.length === 0) {
              //warningAlert(`Um ou Parceiro não estão cadastrados no SAP (${serviceNull.filter((este, i) => serviceNull.indexOf(este) === i)})`)
              warningAlert({
                color: "success",
                message:
                  "Um ou Parceiro não estão cadastrados no SAP! " +
                  item.customer.fullName,
              });
            } else {
              pn++;
            }

            if (
              business.data.value[0].BusinessPartners.HouseBankAccount ===
              null ||
              business.data.value[0].BusinessPartners.HouseBankBranch === null
            ) {
              //warningAlert(`Um ou Parceiro não estão cadastrados no SAP (${serviceNull.filter((este, i) => serviceNull.indexOf(este) === i)})`)
              warningAlert({
                color: "success",
                message:
                  "Conta bancária não estão cadastrados no SAP! " +
                  item.customer.fullName,
              });
            } else {
              quantidadeContas++;
            }

            const itemResponse = await apiSap.get(
              `/Items?$select=ItemName&$filter=(ItemCode eq '${item.service.code}')`
            );
            if (itemResponse.data.value?.length > 0) {
              countItem++;
            } else {
              serviceNull[i] = item.service.name;
              i++;
            }
          } else {
            countCostumer--;
          }
        }

        if (pn === countCostumer && quantidadeContas === countCostumer) {
          if (countItem === countCostumer) {
            let linha = 0;
            for (const invoice of props.billing.invoices) {


              if (invoice.customer.cnpj !== "13.994.051/0001-28") {
                let costumerResponse;
                //let cpfCnpf = invoice.customer.cnpj.replace('.', '').replace('/', '').replace('.', '').replace('-', '').replace('_','').replace('_','').replace('_','');

                let documentoFormatado2 = invoice.customer.cnpj.replace(
                  /[.\-\/_]/g,
                  ""
                );
                let countDoc2 = documentoFormatado2.length;

                if (countDoc2 === 11) {
                  let cpfFormatado =
                    documentoFormatado2.substring(0, 3) +
                    "." +
                    documentoFormatado2.substring(3, 6) +
                    "." +
                    documentoFormatado2.substring(6, 9) +
                    "-" +
                    documentoFormatado2.substring(9, 11);
                  costumerResponse = await apiSap.get(
                    `/$crossjoin(BusinessPartners,BusinessPartners/BPFiscalTaxIDCollection)?$expand=BusinessPartners($select=CardCode,HouseBankBranch,HouseBankAccount)&$filter=BusinessPartners/CardCode eq BusinessPartners/BPFiscalTaxIDCollection/BPCode and BusinessPartners/BPFiscalTaxIDCollection/TaxId4 eq '${cpfFormatado}' and BusinessPartners/CardType eq 'cCustomer'`
                  );
                  sugeitoIRRF = "N"; //validação para imposto
                } else {
                  costumerResponse = await apiSap.get(
                    `/$crossjoin(BusinessPartners,BusinessPartners/BPFiscalTaxIDCollection)?$expand=BusinessPartners($select=CardCode,HouseBankBranch,HouseBankAccount)&$filter=BusinessPartners/CardCode eq BusinessPartners/BPFiscalTaxIDCollection/BPCode and BusinessPartners/BPFiscalTaxIDCollection/TaxId0 eq '${invoice.customer.cnpj}' and BusinessPartners/CardType eq 'cCustomer'`
                  );
                  sugeitoIRRF = "Y"; //validação para imposto
                }

                //BUSCAR CARDCODE/AGENCIA/CONTA POR CNPJ

                const bank = await apiSap.get(
                  `HouseBankAccounts?$select=BankCode,AccNo,Branch,AccountCheckDigit&$filter=AccNo and AccNo eq '${costumerResponse.data.value[0].BusinessPartners.HouseBankAccount}' and Branch eq '${costumerResponse.data.value[0].BusinessPartners.HouseBankBranch} '`
                );

                //PEGA CADA FATURAMENTO
                let contractInstalmentResponse = await api.post(
                  "contractInstallment/search",
                  { _id: invoice.contractInstallments }
                );
                let serviceOrderResponse = await api.post(
                  "serviceOrder/search",
                  { _id: invoice.serviceOrders }
                );

                let BPLID = "";
                let branch = "";
                let obsNFS = "";

                let tamanho = contractInstalmentResponse?.data.length;

                if (tamanho === 0) {
                  branch = serviceOrderResponse.data[0].project.contract.branch;
                  obsNFS =
                    serviceOrderResponse.data[0].project.contract.invoiceNotice;
                }

                let contractResponse = "";
                let element = "";
                let sumInstallment = 0;

                for (
                  let index = 0;
                  index < contractInstalmentResponse.data.length;
                  index++
                ) {
                  //PEGA CADA CONTRATO DE ACORDO COM A PARCELA
                  contractResponse = await api.get(
                    "contract/searchWithInstallments/" +
                    contractInstalmentResponse.data[index].contract
                  );
                  //MONTA A DESCRIÇÃO RDC
                  element += `${contractResponse.data[0].name.toUpperCase()} - Parcela:  ${contractInstalmentResponse.data[index].code
                    }/${contractResponse.data[0].installments.length
                    } -  Valor: ${transformNumberToCurency(
                      contractInstalmentResponse.data[index].amount
                    )} - Vencimento:  ${convertMongoDate(
                      contractInstalmentResponse.data[index].dueDate
                    )} \n`;
                  sumInstallment +=
                    contractInstalmentResponse.data[index].amount;
                }

                //BUSCA FILIAL
                let branchResponse = "";
                let osElement = "";

                if (tamanho > 0) {
                  branchResponse = await apiSap.get(
                    `/BusinessPlaces(${contractResponse?.data[0]?.branch || "1"
                    })`
                  );
                  obsNFS = contractResponse.data[0].invoiceNotice;
                } else {
                  let dias =
                    serviceOrderResponse?.data[index]?.project?.contract
                      ?.dueDateDay;
                  const novaData = getDueDate(dias);

                  branchResponse = await apiSap.get(
                    `/BusinessPlaces(${branch || "1"})`
                  );

                  osElement += `${serviceOrderResponse.data[0].project.name.toUpperCase()} - Valor: ${transformNumberToCurency(
                    serviceOrderResponse.data[index]?.billing?.invoices[index]
                      ?.schedulesTotal
                  )} - Vencimento:  ${novaData} `;
                }
                console.log(serviceOrderResponse);
                if (
                  serviceOrderResponse?.data[0]?.billing?.invoices[linha]
                    ?.customer === "60411a8a856e790017f061d7"
                ) {
                  // pula primeiro faturamento se Golive
                  linha++;
                }

                let total =
                  parseFloat(
                    serviceOrderResponse?.data[0]?.billing?.invoices[linha]
                      ?.schedulesTotal || 0
                  ) + parseFloat(sumInstallment);
                linha++;

                let costingCode = "";
                if (invoice.service.name.indexOf("SAP") > 0) {
                  costingCode = "SAP"; // TROCAR  SAP
                } else if (invoice.service.name.indexOf("Protheus") > 0) {
                  costingCode = "PRO"; // TROCAR POR PRO
                } else if (
                  invoice.service.name.indexOf("Desenvolvimento") > 0
                ) {
                  costingCode = "DES"; // TROCAR POR DES
                } else {
                  costingCode = "SAP"; // TROCAR POR SAP
                }

                let data = new Date();
                let rdcOBS = invoice.rdcText;

                if (rdcOBS) {
                } else {
                  rdcOBS =
                    `PRESTAÇÃO DE SERVIÇO DE ${invoice.service?.name.toUpperCase()} \n` +
                    `\n ${element} \n` +
                    `\n ${osElement} \n` +
                    `\n Valor Total: ${transformNumberToCurency(total)} \n`;
                }
                if (!obsNFS) {
                  obsNFS = "";
                }
              
                if (invoice.rdcAlternativo) {
                  rdcOBS = invoice.rdcAlternativo;
                }
                else {
                  rdcOBS = rdcOBS.substring(rdcOBS.indexOf('\n') + 1) + '\n' + obsNFS;
                }
                debugger
                const dataString = invoice.rdcText;
                const regex = /(\d{2})\/(\d{2})\/(\d{4})/;
                const match = dataString.match(regex);
                let dataFormatada;
                if (match) {
                  const ano = match[3];
                  const mes = match[2];
                  const dia = match[1];
                  dataFormatada = `${ano}-${mes}-${dia}`;
                  console.log(dataFormatada);
                }
                const imposto = await apiSap.post(`SQLQueries('GetCRD11')/List?cardCode='${costumerResponse.data.value[0].BusinessPartners.CardCode}'`)
                if (imposto.data.value[0]?.TributType === 1 && cnpj) {
                  sugeitoIRRF = "N";
                }
                debugger
                const sapInvoice = {
                  CardCode:
                    costumerResponse.data.value[0].BusinessPartners.CardCode,
                  BPL_IDAssignedToInvoice: branchResponse.data.BPLID,
                  DocDueDate: dataFormatada,
                  U_GL_Faturamento: props.billing.code,
                  U_GL_OBSFAT:
                    rdcOBS,
                  DocumentLines: [
                    {
                      ItemCode: invoice.service.code,
                      Quantity: "1",
                      CostingCode: costingCode,
                      Price: total,
                      WTLiable: sugeitoIRRF,
                    },
                  ],
                };
                debugger
                let sapOrder = await apiSap.post("/Invoices", sapInvoice);
                invoice.sapDocEntry = sapOrder.data.DocEntry;
                await api.put(
                  "billing/update/" + props.billing._id,
                  props.billing
                );
              }
            }
            console.log("Faturamento transmitido com sucesso!");
            warningAlert({
              color: "success",
              message: "Faturamento transmitido com sucesso!",
            });
          } else {
            warningAlert({
              color: "success",
              message:
                "Um ou mais serviços não estão cadastrados no SAP! " +
                serviceNull.filter(
                  (este, i) => serviceNull.indexOf(este) === i
                ),
            });
            console.log(
              `Um ou mais serviços não estão cadastrados no SAP (${serviceNull.filter(
                (este, i) => serviceNull.indexOf(este) === i
              )})`
            );
          }
        }
        /*else {
          warningAlert(`Um ou Parceiro não estão cadastrados no SAP (${serviceNull.filter((este, i) => serviceNull.indexOf(este) === i)})`)      
          console.log(`Um ou Parceiro não estão cadastrados no SAP (${serviceNull.filter((este, i) => serviceNull.indexOf(este) === i)})`)
        }*/
      } else {
        warningAlert({
          color: "success",
          message: "O faturamento já foi transmitido",
        });
        //alert(`O faturamento foi transmitido")
        console.log("O faturamento já foi transmitido");
      }
    } catch (error) {
      warningAlert({ color: "success", message: "Erro: " + error.message });
      console.log("Ocorreu um erro\n " + error);
    } finally {
      setLoading(false);
      props.updateBillings();
      props.closeModal();
    }
  }
  // INICIANDO CANCELAMENT
  async function cancelInvoiceSAP() {
    try {
      setLoading(true);
      let countStatusNF = 0;
      for (const invoice of props.billing?.invoices) {
        if (invoice.customer.cnpj !== "13.994.051/0001-28") {
          if (invoice.sapDocEntry) {
            let statusNF = await apiSap.get(`/Invoices( ${invoice.sapDocEntry})`);
            if (statusNF.data.DocumentStatus === "bost_Close") {
              countStatusNF++;
            }
          }
        }
      }

      let index = "";
      if (props.billing.invoices[0].customer.cnpj !== "13.994.051/0001-28") {
        index = 0;
      } else {
        index = 1;
      }

      if (props.billing.invoices[index].sapDocEntry) {
        if (countStatusNF === 0) {
          for (const invoice of props.billing.invoices) {
            if (invoice.customer.cnpj !== "13.994.051/0001-28") {

              let updatedData = {
                U_TX_Just: "Cancelamento via SGL"
              };

              //let sapOrderId = 12345; // ID do pedido que deseja atualizar

              let sapOrderUpdate = await apiSap.patch(`/Invoices(${invoice.sapDocEntry})`, updatedData);


              //await apiSap.post(`/Invoices(${invoice.sapDocEntry})/Cancel`);
              invoice.sapDocEntry = "";
              await api.put(
                "billing/update/" + props.billing._id,
                props.billing
              );
            }
          }
          console.log("A transmissão do faturamento foi cancelada");
          warningAlert({
            color: "success",
            message: "A transmissão do faturamento foi cancelada",
          });
        } else {
          warningAlert({
            color: "success",
            message:
              "Faturamento não cancelado, contém nota fiscal já emitida!",
          });
        }
      } else {
        warningAlert({
          color: "success",
          message: "O faturamento já foi cancelado ou  não foi transmitido",
        });

        console.log("O faturamento já foi cancelado ou  não foi transmitido");
      }
    } catch (error) {
      warningAlert({ color: "success", message: "Ocorreu um erro\n " + error });
      console.log("Ocorreu um erro\n " + error);
    } finally {
      setLoading(false);
      props.updateBillings();
      props.closeModal();
    }
  }
  async function ExcluirFaturamento() {
    try {
      let index = "";
      if (props.billing.invoices[0].customer.cnpj !== "13.994.051/0001-28") {
        index = 0;
      } else {
        index = 1;
      }

      if (props.billing.invoices[index].sapDocEntry) {
        warningAlert({
          color: "success",
          message: "Faturamento não excluido, primeiro cancele a integração",
        });
      } else {
        setLoading(true);
        deleteBilling();
        setConfirmationModalAction({});
      }
    } catch (error) {
      warningAlert({ color: "success", message: "Ocorreu um erro\n " + error });
      console.log("Ocorreu um erro\n " + error);
    } finally {
      setLoading(false);
      props.updateBillings();
      props.closeModal();
    }
  }

  /*
  useEffect(() => {
    if (props.billing?.invoices?.length > 0) {
      let index = ''
      if (props.billing.invoices[0].customer.cnpj !== "13.994.051/0001-28") {
        index = 0
      } else { index = 1 }
      let status = props.billing.invoices[index].sapDocEntry
      setStatus(status)
    }
  }, [props.billing.invoices])


  const sefaz = useCallback(async () => {
    let countStatusNF = 0
    if (!props.billing?.invoices)
      return
    
    for (const invoice of props.billing?.invoices) {
      if (invoice.customer.cnpj !== "13.994.051/0001-28") {
        if (invoice.sapDocEntry) {
          let statusNF = await apiSap.get(`/Orders( ${invoice.sapDocEntry})`)
          if (statusNF.data.DocumentStatus === "bost_Close") {
            countStatusNF++
          }
        }
      }
    }
    setAutorizado(countStatusNF)
    return countStatusNF

  }, [props.billing?.invoices])


  useEffect(() => {

    sefaz();

  }, [sefaz])*/

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="xl"
        isOpen={props.open}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">
            Detalhamento do Faturamento de{" "}
            {convertMongoDate(props.billing?.date ?? "")}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => props.closeModal()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <div className="col">
          <div className="card-wrapper">
            <Card>
              <CardBody style={{ overflowX: "scroll" }}>
                <BillingDetailTable
                  billing={props.billing}
                  invoices={props.billing.invoices}
                  handleBillingClick={() => { }}
                />
              </CardBody>
            </Card>
          </div>
        </div>

        <div className="modal-footer">
          <Button
            color="danger"
            type="button"
            //disabled={!status && autorizado === 0 ? false : true}
            onClick={() => {
              setConfirmationModalOpen(true);
              setConfirmationModalAction({
                action: "delete",
                text: `Excluir Faturamento`,
              });
            }}
          >
            Excluir Faturamento
          </Button>

          <Button
            color="danger"
            type="button"
            //disabled={status && (autorizado === 0) ? false : true}
            onClick={() => {
              setOPeration("cancel");
              setsapLoginModalOpen(true);
            }}
          >
            Cancelar Integração
          </Button>
          <Button
            color="info"
            type="button"
            //disabled={!status && (autorizado === 0) ? false : true}
            onClick={() => {
              setOPeration("open");
              setsapLoginModalOpen(true);
            }}
          >
            Integrar SAP
          </Button>
        </div>
        <Loading modalOpened={loading}></Loading>
        <SapLoginModal
          isOpen={sapLoginModalOpen}
          title="Logar SAP"
          closeModal={() => {
            setsapLoginModalOpen(false);
          }}
          openConfirmationModal={() => {
            if (operation === "open")
              setConfirmationModalAction({ action: "open-invoice" });
            else if (operation === "cancel")
              setConfirmationModalAction({ action: "cancel-invoice" });
            setConfirmationModalOpen(true);
          }}
        ></SapLoginModal>
        <ConfirmationModal
          isOpen={confirmationModalOpen}
          action={confirmationModalAction}
          text={
            confirmationModalAction.action === "delete"
              ? "Você tem certeza que deseja excluir esse faturamento?"
              : confirmationModalAction.action === "open-invoice"
                ? "Deseja transmitir para o SAP?"
                : confirmationModalAction.action === "cancel-invoice"
                  ? "Deseja cancelar integração com o SAP?"
                  : ""
          }
          confirm={async () => {
            if (confirmationModalAction.action === "delete") {
              ExcluirFaturamento();
            } else if (confirmationModalAction.action === "open-invoice") {
              sendInvoiceSAP();
            } else if (confirmationModalAction.action === "cancel-invoice") {
              cancelInvoiceSAP();
            }
          }}
          closeModal={() => {
            setConfirmationModalOpen(false);
          }}
          deny={() => {
            setConfirmationModalOpen(false);
          }}
        />
      </Modal>
      {alert}{" "}
    </>
  );
}
export default BillingDetail;
