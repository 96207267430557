import React from "react"
import { Button, Col, Input, Row } from "reactstrap"

function BillingsTableHeader(props) {
  return (
    <>
      <Row>
        <Col lg="6">
          <h3 className="mb-0">Faturamentos</h3>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="mb-3" lg="3">
          <label className="form-control-label" htmlFor="code">
            Filtrar por código
          </label>
          <Input
            aria-describedby="inputGroupPrepend"
            id="activity"
            type="text"
            value={props.filters.code}
            onChange={(e) => {
              let number = e.target.value.replace(/\D/g, "")
              props.setFilters({ code: number })
            }}
          />
        </Col>
        <Col lg="6"></Col>
        <Col className="mt-3" lg="3" style={{ textAlignLast: "end" }}>
          <Button
            color="success"
            type="button"
            id="authorizeAll"
            className="mt-3"
            onClick={(e) => props.openCreateBilling()}
          >
            Adicionar Faturamento
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default BillingsTableHeader
