import AppointmentForm from "components/Forms/AppointmentForm"
import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Modal, Row } from "reactstrap"
import api from "services/api"
import { hoursToMinutes } from "utils/timeHandling"
import ConfirmationModal from "../ConfirmationModal"

const emptyAppointment = {
  activity: "",
  description: "",
  date: "",
  hours: "00:00",
}

function Appointment(props) {
  const [appointmentBody, setAppointmentBody] = useState(emptyAppointment)

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [confirmationModalAction, setConfirmationModalAction] = useState({})

  const [blockSave, setBlockSave] = useState(false)

  /**
   * closes modal
   */
  function closeModal() {
    props.closeModal()
    setAppointmentBody({
      ...props.appointment,
      date: props.appointment.date.split("T")[0],
    })
  }

  const deleteAppontment = async () => {
    try {
      await api.delete(`appointment/delete/${appointmentBody._id}`)
      props.updateActivities()
      closeModal()
    } catch (error) {
      closeModal()
    }
  }

  /**
   * saves appointment
   */
  const saveAppointment = async () => {
    setBlockSave(true)
    appointmentBody.osCreated = false
    appointmentBody.activity = props.activity._id

    try {
      await api.patch(`appointment/update/${appointmentBody._id}`, {
        ...appointmentBody,
        cost:
          (appointmentBody.hourlyRate * hoursToMinutes(appointmentBody.hours)) /
          60,
      })
      props.updateActivities()
      closeModal()
    } catch (error) {
      alert("Erro ao salvar o apontamento")
      closeModal()
    } finally {
      setBlockSave(false)
    }
  }

  /**
   * hook to mount appointment body based on property apointment
   */
  useEffect(() => {
    if (props.appointment?._id) {
      setAppointmentBody({
        ...props.appointment,
        date: props.appointment.date.split("T")[0],
      })
    }
  }, [props.appointment])

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={props.isOpen}
      autoFocus={false}
    >
      <div className="modal-header">
        <h5 className="modal-title">{props.title}</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={(e) => closeModal()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <Row>
        <div className="col">
          <div className="card-wrapper">
            <Card>
              <CardBody>
                <AppointmentForm
                  activity={props.activity}
                  readOnly={appointmentBody.osCreated}
                  appointmentBody={appointmentBody}
                  activityRemainingMinutes={props.activityRemainingMinutes}
                  setAppointmentBody={(prop) => {
                    setAppointmentBody((prev) => ({ ...prev, ...prop }))
                  }}
                />
              </CardBody>
            </Card>
          </div>
        </div>
      </Row>
      <div className="modal-footer">
        {!appointmentBody.osCreated && (
          <>
            <Button
              color="warning"
              data-dismiss="modal"
              type="button"
              disabled={appointmentBody.osCreated}
              onClick={(e) => {
                setConfirmationModalAction({
                  action: "delete",
                  text: `Excluir apontamento?`,
                })
                setConfirmationModalOpen(true)
              }}
            >
              Excluir
            </Button>
            <Button
              disabled={appointmentBody.osCreated || blockSave}
              color="success"
              type="button"
              onClick={(e) => saveAppointment()}
            >
              {props.action}
            </Button>
          </>
        )}
        <ConfirmationModal
          isOpen={confirmationModalOpen}
          action={confirmationModalAction}
          closeModal={() => {
            setConfirmationModalOpen(false)
          }}
          confirm={async () => {
            deleteAppontment()
            closeModal()
          }}
          deny={() => {
            setConfirmationModalOpen(false)
          }}
        />
      </div>
    </Modal>
  )
}
export default Appointment
