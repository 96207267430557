import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Col, Input, Modal, Row } from "reactstrap";

const RDCTextModal = ({ isOpen, onClose, title, defaultText, saveText, docEntry}) => {
  const [textValue, setTextValue] = useState("");
  let btSalvar = docEntry; 
  debugger
  useEffect(() => {
    if (defaultText) {
      setTextValue(defaultText);
    }
  }, [defaultText]);

  const handleTextChange = (event) => {
    setTextValue(event.target.value);
  };

  const handleSave = async () => {
    await saveText(textValue);
    onClose();
  };

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg" // Alterado para "lg" para deixar o modal maior
      isOpen={isOpen}
      autoFocus={false}
    >
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <button
          aria-label="Close"
          className="close"
          type="button"
          onClick={onClose}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <Card>
        <CardBody>
          <Row>
            <Col xs={12}>
              <label className="form-control-label">Observações de abertura da nota:</label>
              <Input
                type="textarea"
                placeholder="Digite aqui..."
                value={textValue}
                onChange={handleTextChange}
                style={{ height: "200px", width: "100%" }} // Largura ajustada para 100%
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <div className="modal-footer">
        <Button color="danger" onClick={onClose}>
          Cancelar
        </Button>
        {btSalvar ? "" :
        <Button color="success" onClick={handleSave}>
          Salvar
        </Button>}
      </div>
    </Modal>
  );
};

export default RDCTextModal;
