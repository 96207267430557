import React from "react";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

// core components
import LightHeader from "components/Headers/LightHeader.js";
import ReactBSAlert from "react-bootstrap-sweetalert";

import api from "services/api";
import Loading from "components/Modals/Loading";
import Service from "components/Modals/show-edit/Service";

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPage: 25,
  paginationTotalRenderer: (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" [ "} Mostrando linhas {from} a {to} de {size} ]
    </span>
  ),
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Mostrar{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        registros.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

class Services extends React.Component {
  state = {
    data: [],
    isLoading: true,
    saving: false,
    alert: null,

    //Modal user variables
    modalServiceOpened: false,
    modalTitle: "",
    id: "",
    crud: "",
    readOnly: false,
    code: "",
    name: "",
    description: "",
    active: false,
    needHourlyRate: false,

    //Modal user fields validations
    customStyles: {
      codeState: null,
      nameState: null,
      descriptionState: null,
    },
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({ isLoading: true });

    var response = await api.get("/service/all");

    this.setState({ data: response.data, isLoading: false });
  };

  openModalService = async (e, row, crud) => {
    this.setState({ crud: crud });

    if (crud === "C") {
      this.setState({ modalTitle: "Novo Serviço" });
    } else if (crud === "R") {
      this.setState({ modalTitle: "Visualizando Serviço" });
    } else if (crud === "U") {
      this.setState({ modalTitle: "Alterando Serviço" });
    } else if (crud === "D") {
      this.setState({ modalTitle: "Excluindo Serviço" });
    }

    if (crud === "C") {
      this.setState({
        crud: crud,
        code: "",
        name: "",
        description: "",
        active: true,
        needHourlyRate: true,
      });
    } else {
      this.setState({
        id: row._id,
        crud: crud,
        readOnly: crud === "R" || crud === "D",
        code: row.code,
        name: row.name,
        description: row.description,
        active: row.active,
        investment: row.investment,
        needHourlyRate: row.needHourlyRate,
      });
    }

    this.setState({ modalServiceOpened: true });
  };

  closeModalService(e) {
    this.setState({ modalServiceOpened: false, saving: false });
    this.clearModalState();
  }

  clearModalState() {
    this.setState({
      id: "",
      crud: "",
      readOnly: false,
      code: "",
      name: "",
      description: "",
      active: false,
      investment: false,
      needHourlyRate: false,

      //Validations
      customStyles: {
        codeState: null,
        nameState: null,
        descriptionState: null,
      },
    });
  }

  modalSave = async (e) => {
    this.setState({ saving: true });

    let newState = this.state;

    var letSave = true;
    var newData = {};
    var success = true;

    if (this.state.crud === "D") {
      try {
        await api.put("/service/update/" + this.state.id, { deleted: "Y" });
      } catch (err) {
        this.warningAlert(
          "Ocorreu um erro ao tentar excluir. Tente novamente."
        );
        success = false;
        this.setState({ saving: false });
        return;
      }

      if (success) {
        this.loadData();
        this.closeModalService();
      }
    } else if (this.state.crud === "C" || this.state.crud === "U") {
      //Field content validations
      if (newState.code === "") {
        newState.codeState = "invalid";
        letSave = false;
      } else {
        newState.codeState = "valid";
      }

      if (newState.name === "") {
        newState.nameState = "invalid";
        letSave = false;
      } else {
        newState.nameState = "valid";
      }

      if (newState.description === "") {
        newState.descriptionState = "invalid";
        letSave = false;
      } else {
        newState.descriptionState = "valid";
      }

      this.setState({
        customStyles: newState,
      });

      if (!letSave) {
        this.setState({ saving: false });
        return;
      } else {
        const existService = await api.post("/service/search", {
          code: this.state.code,
          deleted: "N",
        });

        if (existService.data.length > 0) {
          if (existService.data[0]._id !== this.state.id) {
            this.warningAlert(
              "Já existe um Serviço cadastrado com este código."
            );
            this.setState({ saving: false });
            return;
          }
        }

        newData = {
          code: this.state.code,
          name: this.state.name,
          description: this.state.description,
          active: this.state.active,
          needHourlyRate: this.state.needHourlyRate,
          investment: this.state.investment,
        };

        this.saveServiceData(newData);
      }
    }
  };

  saveServiceData = async (newData) => {
    var success = true;

    if (this.state.crud === "C") {
      try {
        await api.post("/service/create", newData);
      } catch (err) {
        this.warningAlert("Houve um problema ao cadastrar o serviço.");
        success = false;
        this.setState({ saving: false });
        return;
      }

      if (success) {
        this.loadData();
        this.closeModalService();
      }
    } else {
      try {
        await api.put("/service/update/" + this.state.id, newData);
      } catch (err) {
        this.warningAlert(
          "Ocorreu um erro ao tentar alterar. Tente novamente."
        );
        success = false;
        this.setState({ saving: false });
        return;
      }

      if (success) {
        this.loadData();
        this.closeModalService();
      }
    }
  };

  stylesForm = async (e, stateName, checkbox) => {
    if (checkbox) {
      let newCheck = this.state[stateName];
      newCheck = newCheck ? false : true;

      this.setState({
        [stateName]: newCheck,
      });

      return;
    }

    let newState = this.state.customStyles;

    if (e.target.value === null || e.target.value === "") {
      newState[stateName + "State"] = "invalid";
    } else {
      newState[stateName + "State"] = "valid";
    }

    this.setState({
      [stateName]: e.target.value,
      customStyles: newState,
    });
  };

  warningAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Atenção!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </ReactBSAlert>
      ),
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null,
    });
  };

  render() {
    return (
      <>
        <LightHeader
          title="Cadastro de Serviços"
          parent="Cadastros"
          menuTitle="Serviços"
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Lista de Serviços do SGL</h3>
                    </Col>
                    <Col className="text-right" xs="6">
                      <Button
                        className="btn-round btn-icon"
                        color="primary"
                        onClick={(e) => this.openModalService(e, null, "C")}
                        size="sm"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-plus" />
                        </span>
                        <span className="btn-inner--text">Novo Serviço</span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <ToolkitProvider
                  data={this.state.data}
                  keyField="_id"
                  columns={[
                    {
                      dataField: "actions",
                      editable: false,
                      text: "Ações",
                      isDummyField: true,
                      formatter: (cell, row, rowIndex) => {
                        return (
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              color=""
                              role="button"
                              size="sm"
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                onClick={(e) =>
                                  this.openModalService(e, row, "U")
                                }
                              >
                                <i className="fas fa-edit" />
                                <span>Alterar</span>
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) =>
                                  this.openModalService(e, row, "D")
                                }
                              >
                                <i className="fas fa-trash" />
                                <span>Excluir</span>
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) =>
                                  this.openModalService(e, row, "R")
                                }
                              >
                                <i className="fas fa-glasses" />
                                <span>Visualizar</span>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        );
                      },
                    },
                    {
                      dataField: "_id",
                      text: "Id",
                      hidden: true,
                    },
                    {
                      dataField: "code",
                      text: "Código",
                      sort: true,
                    },
                    {
                      dataField: "name",
                      text: "Nome do Serviço",
                      sort: true,
                    },
                    {
                      dataField: "active",
                      text: "Ativo",
                      sort: true,
                      formatter: (cell, row) => {
                        return (
                          <label className="custom-toggle custom-toggle-default">
                            <input
                              defaultChecked={row.active}
                              type="checkbox"
                              disabled
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="Não"
                              data-label-on="Sim"
                            />
                          </label>
                        );
                      },
                    },
                    {
                      dataField: "needHourlyRate",
                      text: "Exige Valor/Hora",
                      sort: true,
                      formatter: (cell, row) => {
                        return (
                          <label className="custom-toggle custom-toggle-default">
                            <input
                              defaultChecked={row.needHourlyRate}
                              type="checkbox"
                              disabled
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="Não"
                              data-label-on="Sim"
                            />
                          </label>
                        );
                      },
                    },
                    {
                      dataField: "investment",
                      text: "Investimento",
                      sort: true,
                      formatter: (cell, row) => {
                        return (
                          <label className="custom-toggle custom-toggle-default">
                            <input
                              defaultChecked={row.investment}
                              type="checkbox"
                              disabled
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="Não"
                              data-label-on="Sim"
                            />
                          </label>
                        );
                      },
                    },

                  ]}
                  search
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <Container fluid>
                        <Row>
                          <Col xs={12}>
                            <div
                              id="datatable-basic_filter"
                              className="dataTables_filter px-4 pb-1 float-left"
                            >
                              <label>
                                Pesquisar:
                                <SearchBar
                                  className="form-control-sm"
                                  placeholder="Pesquisar por..."
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                      <BootstrapTable
                        ref={(el) => (this.componentRef = el)}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        hover
                        id="react-bs-table"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Card>
            </div>
          </Row>
          <Service
            modalOpened={this.state.modalServiceOpened}
            closeModal={this.closeModalService.bind(this)}
            modalTitle={this.state.modalTitle}
            id={this.state.id}
            crud={this.state.crud}
            readOnly={this.state.readOnly}
            code={this.state.code}
            name={this.state.name}
            active={this.state.active}
            needHourlyRate={this.state.needHourlyRate}
            description={this.state.description}
            investment={this.state.investment}
            customStyles={this.state.customStyles}
            modalSave={this.modalSave.bind(this)}
            stylesForm={this.stylesForm.bind(this)}
            saving={this.state.saving}
          />
          <Loading modalOpened={this.state.isLoading} />
          {this.state.alert}
        </Container>
      </>
    );
  }
}

export default Services;
