import React, { useEffect, useState } from "react"
import { Spreadsheet } from "react-spreadsheet"
import { formatNumber } from "utils/formatNumber"

const ProjectIndicatorTable = ({dataset, onSpreadsheetChange}) => {
  const [formattedDataset, setFormattedDataset] = useState([])

  useEffect(()=>{
    const updatedDataset = dataset.map(nestedList =>nestedList.map(itemInList=>{

    if(isNaN(Number(itemInList.value))){
      return itemInList
    }
    
    return {...itemInList,value:formatNumber(itemInList.value)}
  }))

    setFormattedDataset(updatedDataset)
  },[dataset])

  useEffect(()=>console.log({formattedDataset}),[formattedDataset])

  return (
    <>
      <Spreadsheet
        data={formattedDataset}
        onChange={(e) => onSpreadsheetChange(e)}
      ></Spreadsheet>
    </>
  )
}
export default ProjectIndicatorTable
