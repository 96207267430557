import MenuAccess from "components/Modals/admin/MenuAccess"
import CreateMenuAccess from "components/Modals/create/CreateMenuAccess"
import React, { useState } from "react"
import { Table } from "reactstrap"

function MenuAccessTable(props) {
  const [menuAccessModalOpen, setMenuAccesModalOpen] = useState(false)
  const [menuAccess, setMenuAccess] = useState({})

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th style={{ width: "10%" }}>Código</th>
            <th>Nome</th>
          </tr>
        </thead>
        <tbody>
          {props.menuAccesses.map((ma, index) => {
            return (
              <tr
                key={index}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setMenuAccess(ma)
                  setMenuAccesModalOpen(true)
                }}
              >
                <td>{ma.code}</td>
                <td>{ma.name}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <MenuAccess
        menuAccess={menuAccess}
        isOpen={menuAccessModalOpen}
        title={"Detalhamento do perfil de acesso"}
        action={"Salvar"}
        closeModal={() => {
          setMenuAccesModalOpen(false)
          setMenuAccess({})
        }}
        refetchAccesses={() => {
          props.refetchAccesses()
        }}
      />
      <CreateMenuAccess
        isOpen={props.createMenuAccessModalOpen}
        title={"Criar perfil de acesso"}
        action={"Criar"}
        closeModal={() => {
          props.setCreateMenuAccesModalOpen(false)
        }}
        refetchAccesses={() => {
          props.refetchAccesses()
        }}
      />
    </>
  )
}

export default MenuAccessTable
