import CreateServiceOrder from "components/Modals/create/CreateServiceOrder"
import ServiceOrder from "components/Modals/show-edit/ServiceOrder"
import Tag from "components/Tag/Tag"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import Skeleton from "react-loading-skeleton"
import { Col, Collapse, Input, Table } from "reactstrap"
import api from "services/api"
import { userId } from "services/auth"
import { minutesToHours } from "utils/timeHandling"
import { hoursToMinutes } from "utils/timeHandling"
import AppointmentsTable from "./AppointmentsTable"
import "react-loading-skeleton/dist/skeleton.css"

const stickyTableHeader = {
  zIndex: "5",
  position: "sticky",
  left: "0",
  top: "0",
  background: "white",
}

function ActivitiesTable(props) {
  const [activities, setActivities] = useState([])
  const [loadingActivities, setLoadingActivities] = useState(false)
  const [filter, setFilter] = useState("")

  /**
   * Calcultes hours left
   * @param {Date} performed
   * @param {Date} estimated
   * @returns {String}
   */
  function hoursBank(performed, estimated) {
    let hoursPerformed = parseInt(hoursToMinutes(performed))
    let hourseEstimated = parseInt(hoursToMinutes(estimated))

    let finalHours = hourseEstimated - hoursPerformed

    return minutesToHours(finalHours)
  }

  /**
   * @param {*} status
   * @returns {String}
   */
  function statusFactory(status) {
    switch (status) {
      case "P":
        return "Pendente"
      case "I":
        return "Em andamento"
      default:
        return "Finalizada"
    }
  }

  /**
   * open activity
   * @param {*} index
   */
  const openActivity = (index) => {
    let newActivities = activities.map((activity) => {
      return activity
    })

    newActivities[index].open = !newActivities[index].open

    setActivities(newActivities)
  }

  /**
   * updates activity after appointment insertion/deletion/edition
   * maintanis open activities after appointment api call
   */
  const updateActivities = useCallback(async () => {
    if (props.schedule?._id) {
      setLoadingActivities(true)
      try {
        const projectActivitiesResponse = await api.post("activity/search", {
          project: props.schedule.project,
          deleted: "N",
        })
        const projectActivities = projectActivitiesResponse.data
        let activities = []
        projectActivities.forEach((pa) => {
          let consultantActivity = props.consultantActivities.find(
            (ac) => ac.activity._id === pa._id
          )
          if (consultantActivity) {
            pa = {
              ...pa,
              estimatedHours: consultantActivity.estimatedHours,
              performedHours: consultantActivity.performedHours,
              open: false,
            }
            activities.push(pa)
          }
        })
        setActivities(activities)
      } catch (error) {
        console.error(error)
      } finally {
        setLoadingActivities(false)
      }
    }
  }, [props.consultantActivities, props.schedule?._id, props.schedule.project])
  function getActivityProcess(activity) {
    let process = ""
    if (activity.parentActivities?.length > 0)
      for (const act of activity.parentActivities.sort((a, b) => {
        return a.code < b.code ? -1 : 1
      })) {
        process += act.code + "-" + act.process + "    >     "
      }
    process += activity.code + "-" + activity.process
    return process
  }

  // const filteredActivites = useMemo(
  //   () =>
  //     activities.filter((activity) =>
  //       getActivityProcess(activity)
  //         .toUpperCase()
  //         .includes(filter.toUpperCase())
  //         ? true
  //         : false
  //     ),
  //   [filter, activities]
  // )

  useEffect(() => {
    updateActivities()
  }, [updateActivities])
  return (
    <>
      <div style={{ overflowX: "auto" }}>
        {loadingActivities ? (
          <Skeleton count={activities.length} width="100%" />
        ) : (
          <>
            {/* <Col className="mb-3" xs="12" md="6">
              <label className="form-control-label" htmlFor="timeOthers">
                Filtrar
              </label>
              <Input
                aria-describedby="inputGroupPrepend"
                id="filter"
                type="text"
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value)
                }}
              />
            </Col> */}
            <Table>
              <thead>
                <tr>
                  <th style={{ ...stickyTableHeader, width: "10%" }}>Status</th>
                  <th style={{ ...stickyTableHeader, width: "50%" }}>
                    Atividade
                  </th>
                  <th style={{ ...stickyTableHeader, width: "15%" }}>
                    Hrs <br /> Realizadas
                  </th>
                  <th style={{ ...stickyTableHeader, width: "15%" }}>
                    Hrs
                    <br /> Estimadas
                  </th>
                  <th style={{ ...stickyTableHeader, width: "10%" }}>Saldo</th>
                </tr>
              </thead>
              <tbody>
                {activities.map((activity, activityIndex) => {
                  return (
                    <>
                      <tr
                        key={activityIndex}
                        style={{
                          cursor: "pointer",
                          backgroundColor: activity.open ? "lavander" : "white",
                        }}
                        onClick={() => {
                          openActivity(activityIndex)
                        }}
                      >
                        <td>
                          <Tag
                            backgroundColor={
                              activity.status === "P"
                                ? "var(--danger)"
                                : activity.status === "I"
                                ? "var(--info)"
                                : "var(--success)"
                            }
                          >
                            {statusFactory(activity.status)}
                          </Tag>
                        </td>
                        <td
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {getActivityProcess(activity)}
                        </td>
                        <td>{activity.performedHours}</td>
                        <td>{activity.estimatedHours}</td>
                        <td>
                          {hoursBank(
                            activity.performedHours,
                            activity.estimatedHours
                          )}
                        </td>
                      </tr>
                      <tr key={activities.length + activityIndex}>
                        <td colSpan={5} style={{ border: "0" }}>
                          <Collapse isOpen={activity.open} className={"px-3"}>
                            <AppointmentsTable
                              hasBalance={
                                hoursBank(
                                  activity.performedHours,
                                  activity.estimatedHours
                                ) !== "00:00"
                              }
                              appointments={activity.appointments.filter(
                                (ap) => {
                                  return ap.consultant === userId()
                                }
                              )}
                              schedule={props.schedule}
                              activity={activity}
                              updateActivities={() => updateActivities()}
                            />
                          </Collapse>
                        </td>
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </Table>
          </>
        )}
      </div>
      <CreateServiceOrder
        isOpen={props.createServiceOrderModalOpen}
        schedule={props.schedule}
        activities={activities}
        title={"Criar ordem de serviço"}
        closeModal={() => props.closeCreateServiceOrderModal()}
        updateActivities={() => updateActivities()}
        updateSchedule={() => props.updateSchedule()}
        canMakeOs={(value) => {
          props.canMakeOs(value)
        }}
      />
      {props.schedule && activities && (
        <ServiceOrder
          isOpen={props.serviceOrderModalOpen}
          title={"Ordem de serviço"}
          schedule={props.schedule}
          activities={activities}
          closeModal={() => props.closeServiceOrderModal()}
          updateActivities={() => updateActivities()}
          updateSchedule={() => props.updateSchedule()}
          canMakeOs={(value) => {
            props.canMakeOs(value)
          }}
        />
      )}
    </>
  )
}

export default ActivitiesTable
