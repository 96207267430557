import React from "react"
import { Link } from "react-router-dom"
import { Button, Col, Input, Row } from "reactstrap"
import { userId } from "services/auth"

function MobileTableHeader(props) {
  return (
    <Row>
      <Col xs="4">
        <h3 className="mb-0">Agenda Consultor</h3>
      </Col>
      <Col className="" xs="2">
        <label className="form-control-label" htmlFor="from">
          De
        </label>
        <Input
          id="from"
          type="date"
          value={props.from}
          onChange={(e) => {
            props.setFrom(e.target.value)
          }}
        />
      </Col>
      <Col className="pb-2" xs="2">
        <label className="form-control-label" htmlFor="from">
          Até
        </label>
        <Input
          id="to"
          type="date"
          value={props.to}
          onChange={(e) => {
            props.setTo(e.target.value)
          }}
        />
      </Col>

      <Col xs="4">
        <Row className="mb-2" style={{ justifyContent: "end" }}>
          <Button
            className="btn-round btn-icon"
            color="primary"
            onClick={(e) => props.handleOpenServiceOrdersReport(true)}
            size="sm"
          >
            <Link
              to={{
                pathname: `/document/${userId()}`,
                search: `type=os_report&dateTo=${props.to}&dateFrom=${props.from}`,
              }}
              target={"_blank"}
            >
              <span className="btn-inner--text" style={{ color: "white" }}>
                Extrato de OS
              </span>
            </Link>
          </Button>
        </Row>
        <Row className="mb-2" style={{ justifyContent: "end" }}>
          <Button
            className="btn-round btn-icon"
            color="primary"
            onClick={(e) => props.openScheduleRequisition(true)}
            size="sm"
          >
            <span className="btn-inner--text" style={{ color: "white" }}>
              Requisitar Agenda
            </span>
          </Button>
        </Row>

      </Col>
    </Row>
  )
}

export default MobileTableHeader
