import React from "react"
import { Col, Form, Input } from "reactstrap"
import PropTypes from "prop-types"
import { transformNumberToCurency } from "utils/currencyHandling"
import ReactInputMask from "react-input-mask"
import Select2 from "react-select2-wrapper"

function ResourceCostManagementForm(props) {
  return (
    <Form className="needs-validation" noValidate autoComplete="off">
      <div className="form-row px-4">
        {/* Date */}
        <Col className="mb-3" xs="12" md="6">
          <label className="form-control-label" htmlFor="consultant">
            Consultor
          </label>
          <Select2
            id="consultant"
            type="text"
            value={props.consultant?._id}
            onSelect={(e) => props.setConsultant(e.target.value)}
            valid={props.validation.consultant !== "invalid"}
            invalid={props.validation.consultant === "invalid"}
            options={{
              hidden: true,
              placeholder: "Selecione um consultor",
              language: {
                noResults: function () {
                  return "Nenhum consultor encontrado."
                },
              },
            }}
            data={props.consultants.map((consultant) => {
              return {
                id: consultant._id,
                text: consultant.fullName,
              }
            })}
          ></Select2>

          <div className="invalid-feedback">Selecione um consultor.</div>
        </Col>
        <Col className="mb-3" md="3">
          <label className="form-control-label" htmlFor="dateFrom">
            Data de
          </label>
          <Input
            aria-describedby="inputGroupPrepend"
            id="dateFrom"
            type="date"
            value={props.dateFrom}
            readOnly={props.readOnly}
            disabled={props.saving || !props.consultant}
            onChange={(e) => props.setDateFrom(e.target.value)}
          />
        </Col>
        <Col className="mb-3" md="3">
          <label className="form-control-label" htmlFor="dateTo">
            Data até
          </label>
          <Input
            aria-describedby="inputGroupPrepend"
            id="dateTo"
            type="date"
            value={props.dateTo}
            disabled={props.saving || !props.consultant}
            onChange={(e) => props.setDateTo(e.target.value)}
          />
        </Col>
      </div>
      <div className="form-row px-4">
        <Col className="mb-3" md="6"></Col>
        <Col className="mb-3" md="3">
          <label className="form-control-label" htmlFor="oldCost">
            Valor Antigo
          </label>
          <Input
            aria-describedby="inputGroupPrepend"
            id="oldCost"
            type="string"
            step={2}
            readOnly={true}
            value={transformNumberToCurency(
              props.consultant?.hourlyRate || 0.0
            )}
          />
        </Col>
        <Col className="mb-3" md="3">
          <label className="form-control-label" htmlFor="newCost">
            Novo Valor
          </label>
          <Input
            aria-describedby="inputGroupPrepend"
            id="newCost"
            type="number"
            tag={ReactInputMask}
            value={props.newValue}
            disabled={props.saving || !props.consultant}
            valid={props.validation.newValue === "valid"}
            invalid={props.validation.newValue === "invalid"}
            onChange={(e) => props.setNewValue(parseFloat(e.target.value))}
          />
          <div className="invalid-feedback">Novo Valor é obrigatório.</div>
        </Col>
      </div>

      {/* Date */}
    </Form>
  )
}
ResourceCostManagementForm.propTypes = {
  consultants: PropTypes.array.isRequired,
  consultant: PropTypes.object.isRequired,
  dateTo: PropTypes.string.isRequired,
  dateFrom: PropTypes.string.isRequired,
  newValue: PropTypes.number.isRequired,
  validation: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  loading: PropTypes.bool,
  setConsultant: PropTypes.func.isRequired,
  setDateFrom: PropTypes.func.isRequired,
  setDateTo: PropTypes.func.isRequired,
  setNewValue: PropTypes.func.isRequired,
}

export default ResourceCostManagementForm
