import React from "react";
// reactstrap components
import { Card, CardBody, Modal } from "reactstrap";

import { Progress } from "reactstrap";

class Loading extends React.Component {
  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          size="sm"
          isOpen={this.props.modalOpened}
          autoFocus={false}
        >
          <Card>
            <CardBody>
              <div className="text-center text-muted my-4">
                <span className="text-muted">Por favor, aguarde...</span>
                <Progress animated color="danger" value="100" />
              </div>
            </CardBody>
          </Card>
        </Modal>
      </>
    );
  }
}

export default Loading;
