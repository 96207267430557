import "moment/locale/pt-br"
import React, { useEffect, useMemo, useState } from "react"
import "react-big-calendar/lib/css/react-big-calendar.css"

import Select2 from "react-select2-wrapper"

import { Col, Input, Modal, ModalBody, Row, Table } from "reactstrap"
import Log from "./show-edit/Log"

const now = new Date()

export default function Logs(props) {
  const [consultant, setConsultant] = useState("")
  const [from, setFrom] = useState("")
  const [to, setTo] = useState("")
  const [onlyDeleted, setOnlyDeleted] = useState(false)
  const [schedules, setSchedules] = useState([])
  const [selectedSchedule, setSelectedSchedule] = useState({})
  const [logModalOpen, setLogModalOpen] = useState([])

  useMemo(async () => {
    setFrom(
      new Date(now.getFullYear(), now.getMonth(), 1).toISOString().split("T")[0]
    )
    setTo(
      new Date(now.getFullYear(), now.getMonth() + 1, 0)
        .toISOString()
        .split("T")[0]
    )
  }, [])

  useEffect(() => {
    if (consultant && props.consultants) {
      const selectedConsultant = props.consultants.find(
        (con) => con._id === consultant
      )
      let schedules = selectedConsultant.schedules
      schedules = schedules.filter((schedule) => {
        return (
          schedule.date <= new Date(to).toISOString() &&
          schedule.date >= new Date(from).toISOString()
        )
      })
      if (onlyDeleted) {
        schedules = schedules.filter((schedule) => {
          return schedule.deleted === "Y"
        })
      }

      setSchedules(schedules || [])
    }
  }, [consultant, from, props.consultants, to, onlyDeleted])

  /**
   *
   * @param {*} id
   * @returns
   */
  function getCustomer(id) {
    return props.customers.find((customer) => customer._id === id)
  }

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={props.isOpen}
      autoFocus={false}
    >
      <div className="modal-header">
        <h5 className="modal-title">{props.title}</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={(e) => props.closeModal()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <ModalBody>
        <Row className="pb-3">
          <Col className="" xs="4">
            <label className="form-control-label" htmlFor="consultant">
              Consultor
            </label>
            <Select2
              id="consultant"
              type="text"
              value={consultant}
              onSelect={(e) => {
                setConsultant(e.target.value)
              }}
              options={{
                hidden: true,
                placeholder: "Selecione um consultor",
                language: {
                  noResults: function () {
                    return "Nenhum consultor encontrado."
                  },
                },
              }}
              data={props.consultants.map((consultant) => {
                return {
                  id: consultant._id,
                  text: consultant.fullName,
                }
              })}
            ></Select2>
          </Col>
          <Col xs={"3"}>
            <label className="form-control-label" htmlFor="from">
              De
            </label>
            <Input
              id="from"
              type="date"
              value={from}
              onChange={(e) => {
                setFrom(e.target.value)
              }}
            />
          </Col>
          <Col xs={"3"}>
            <label className="form-control-label" htmlFor="to">
              até
            </label>
            <Input
              id="to"
              type="date"
              value={to}
              onChange={(e) => {
                setTo(e.target.value)
              }}
            />
          </Col>
          <Col className="mb-3" md="2">
            <label
              className="form-control-label"
              htmlFor="activityHoursSettled"
            >
              Apenas excluídos
            </label>
            <div>
              <label className="custom-toggle custom-toggle-default mr-1">
                <input
                  type="checkbox"
                  checked={onlyDeleted}
                  onChange={(e) => setOnlyDeleted(!onlyDeleted)}
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="Não"
                  data-label-on="Sim"
                />
              </label>
            </div>
          </Col>
        </Row>
        <Table hidden={schedules?.length < 1}>
          <thead>
            <tr>
              <th>Data</th>
              <th>Agenda</th>
            </tr>
          </thead>

          <tbody>
            {schedules.map((schedule, index) => {
              return (
                <tr
                  key={index}
                  style={{
                    cursor: "pointer",
                    color: schedule.deleted === "Y" ? "#E61035" : "",
                  }}
                  onClick={() => {
                    setSelectedSchedule(schedule)
                    setLogModalOpen(true)
                  }}
                >
                  <td>
                    <Row>
                      {new Date(
                        schedule.date.split("T")[0] + " 00:00"
                      ).toLocaleDateString("pt-BR")}
                    </Row>
                  </td>
                  <td>
                    <Row>{getCustomer(schedule.customer).fullName}</Row>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </ModalBody>
      {selectedSchedule.log && (
        <Log
          title={"Logs"}
          isOpen={logModalOpen}
          schedule={selectedSchedule}
          closeModal={() => {
            setLogModalOpen(false)
          }}
        />
      )}
    </Modal>
  )
}
