import SglTable from 'components/ToolkitTable/SglTable'
import React, { useMemo } from 'react'
import { Row, Table } from 'reactstrap'
import { convertMongoDate } from 'utils/dateHandling'

const columns = [
  {
    dataField: "_id",
    text: "Id",
    hidden: true,
  },
  {
    dataField: "action",
    text: "Autorizado",
    hidden: false,
  },
  {
    dataField: "date",
    text: "Data",
    sort: true,
    hidden: false,
  },
  {
    dataField: "projectName",
    text: "PRojeto",
    sort: true,
  },
  {
    dataField: "customer",
    text: "Cliente",
    sort: true,
  },
  {
    dataField: "consultant",
    text: "Consultor",
    sort: true,
  },
  {
    dataField: "service",
    text: "Serviço",
    sort: true,
  },
  {
    dataField: "timeFrom",
    text: "Hora de",
    sort: true,
  },
  {
    dataField: "timeTo",
    text: "Hora até",
    sort: true,
  },
  {
    dataField: "timeCommute",
    text: "Traslado",
    sort: true,
  },
  {
    dataField: "timeOthers",
    text: "Outros",
    sort: true,
  },
  {
    dataField: "timeTotal",
    text: "Total",
    sort: true,
  },
]


function ServiceOrderReleaseTable (props) {
  const serviceOrders = useMemo(()=>{
    return props.serviceOrders.map((serviceOrder, index)=>{
      return {
        action:   
          <label className='custom-toggle custom-toggle-default'>
            <input
              checked={serviceOrder.authorized}
              type='checkbox'
              id='billing'
              value={serviceOrder.authorized}
              onChange={() => {
                props.updateAuthorizedServiceOrders(index)
              }}
            />
            <span
              className='custom-toggle-slider rounded-circle'
              data-label-off='Não'
              data-label-on='Sim'
            />
        </label>,
        date: convertMongoDate(serviceOrder.date),
        projectName: serviceOrder.project.name,
        customer: serviceOrder.customer.shortName,
        consultant: serviceOrder.consultant.fullName,
        service: serviceOrder.project?.contract?.service?.name,
        timeFrom: serviceOrder.timeFrom,
        timeTo: serviceOrder.timeTo,
        timeCommute: serviceOrder.timeCommute,
        timeOthers: serviceOrder.timeOthers,
        timeTotal: serviceOrder.timeTotal
      }
    })
},[props])

  if (!props.serviceOrders) {
    return <></>
  }
  if (props.serviceOrders.length > 0) {
    return (
     <SglTable
        columns={columns}
        data={serviceOrders}
        rowClick={()=>{
        }}
      />
    )
  } else {
    return (
      <>
        <Row className='pl-4 mt-2'>
          <h3>Não há ordens de serviço, verifique os filtros</h3>
        </Row>
      </>
    )
  }
}
export default ServiceOrderReleaseTable
