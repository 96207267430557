import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row } from "reactstrap";
import Select2 from "react-select2-wrapper";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import { convertMongoDate } from "utils/dateHandling";

export default function ScheduleForm(props) {
  const [customerAddresses, setCustomerAddresses] = useState([]);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    let adresses = props.customerAddresses.filter((address) => {
      return address.customer === props.scheduleBody.customer;
    });

    let currentProject = props.projects.find(
      (proj) => proj._id === props.scheduleBody.project
    );
    //separeates current project if it is finished
    let projects = props.projects.filter((project) => {
      return (
        project.customer === props.scheduleBody.customer &&
        !project.endDate &&
        project._id !== currentProject?._id
      );
    });
    //re-ads current project for view
    if (currentProject) projects.push(currentProject);

    setCustomerAddresses(adresses);
    setProjects(projects);
  }, [
    props.customerAddresses,
    props.projects,
    props.scheduleBody.project,
    props.scheduleBody.customer,
    props,
  ]);

  return (
    <Form className="needs-validation" noValidate autoComplete="off">
      <div className="form-row">
        {/* Costumers */}

        <Col className="mb-3" lg="6" md="12">
          <label className="form-control-label" htmlFor="customer">
            Cliente
          </label>
          <Select2
            className="form-control"
            type="text"
            id="customer"
            value={props.scheduleBody.customer}
            disabled={
              props.readOnly ||
              props.saving ||
              props.editing ||
              props.scheduleBody.serviceOrder
            }
            valid={props.scheduleBody.customer !== ""}
            invalid={props.scheduleBody.customer === ""}
            options={{
              hidden: true,
              size: "small",
              placeholder: "Selecione um cliente",
              language: {
                noResults: function () {
                  return "Nenhum cliente encontrado.";
                },
              },
            }}
            data={props.customers.map((customer) => {
              return {
                id: customer._id,
                text: customer.shortName,
              };
            })}
            onSelect={(e) => {
              if (e.target.value === "60411a8a856e790017f061d7") {
                props.setScheduleBody({
                  billing: false,
                  customer: e.target.value,
                });
              } else {
                props.setScheduleBody({
                  customer: e.target.value,
                  billing: true,
                });
              }

              let defaultAddress = props.customerAddresses.find(
                (adress) =>
                  adress.customer === e.target.value && adress.defaultAddress
              );
              if (defaultAddress)
                props.setScheduleBody({
                  address: defaultAddress._id,
                  timeCommute: defaultAddress.transfer,
                });

              props.setScheduleBody({ customer: e.target.value });
            }}
          ></Select2>
          <div className="invalid-feedback">Selecione um cliente</div>
        </Col>
        {/* Address */}

        <Col className="mb-3" lg="6" md="12">
          <label className="form-control-label" htmlFor="address">
            Local
          </label>
          <Select2
            id="address"
            type="text"
            value={props.scheduleBody.address}
            disabled={
              !props.scheduleBody.customer ||
              props.readOnly ||
              props.saving ||
              props.editing ||
              props.scheduleBody.serviceOrder
            }
            valid={props.scheduleBody.address !== ""}
            invalid={props.scheduleBody.address === ""}
            options={{
              hidden: true,
              size: "small",
              placeholder: "Selecione um Local",
              language: {
                noResults: function () {
                  return "Nenhum local encontrado.";
                },
              },
            }}
            onSelect={(e) => {
              let address = customerAddresses.find(
                (address) => address._id === e.target.value
              );
              props.setScheduleBody({
                address: address._id,
                timeCommute: address.transfer,
              });
            }}
            data={customerAddresses.map((address) => {
              return {
                id: address._id,
                text: address.description,
              };
            })}
          ></Select2>
          <div className="invalid-feedback">Selecione um endereço.</div>
        </Col>
      </div>
      <div className="form-row">
        {/* Consultant */}
        <Col className="mb-3" lg="6" md="12">
          <label className="form-control-label" htmlFor="consultant">
            Consultor
          </label>
          <Select2
            id="consultant"
            type="text"
            value={props.scheduleBody.consultant}
            disabled={
              props.readOnly ||
              props.saving ||
              props.editing ||
              props.scheduleBody.serviceOrder ||
              props.readOnlyFields?.includes("consultant")
            }
            valid={props.scheduleBody.consultant !== ""}
            invalid={props.scheduleBody.consultant === ""}
            onSelect={(e) => {
              props.setScheduleBody({ consultant: e.target.value });
            }}
            options={{
              hidden: true,
              size: "small",
              placeholder: "Selecione um consultor",
              language: {
                noResults: function () {
                  return "Nenhum consultor encontrado.";
                },
              },
            }}
            data={props.consultants.map((consultant) => {
              return {
                id: consultant._id,
                text: consultant.fullName,
              };
            })}
          ></Select2>
          <div className="invalid-feedback">Selecione um Consultor</div>
        </Col>
        {/* Date */}
        <Col className="mb-3" lg="6" md="12">
          <label className="form-control-label" htmlFor="date">
            Data
          </label>
          <Input
            id="date"
            type="date"
            value={props.scheduleBody.date}
            readOnly={props.readOnly}
            disabled={props.saving || props.scheduleBody.serviceOrder}
            onChange={(e) => {
              props.setScheduleBody({ date: e.target.value });
            }}
            valid={props.scheduleBody.date !== ""}
            invalid={props.scheduleBody.date === ""}
          />
        </Col>
      </div>
      {!props.readOnly && (
        <div className="form-row">
          <Col className="mb-3" xs={6} md="2">
            <Button
              style={{ width: "100%" }}
              disabled={
                props.readOnly ||
                props.saving ||
                props.editing ||
                props.scheduleBody.serviceOrder
              }
              onClick={() => {
                props.setScheduleBody({ timeFrom: "08:00" });
                props.setScheduleBody({ timeTo: "12:00" });
                props.setScheduleBody({ timeOthers: "00:00" });
              }}
            >
              Manhã
            </Button>
          </Col>
          <Col className="mb-3" xs={6} md="2">
            <Button
              disabled={
                props.readOnly ||
                props.saving ||
                props.editing ||
                props.scheduleBody.serviceOrder
              }
              style={{ width: "100%" }}
              onClick={() => {
                props.setScheduleBody({ timeFrom: "14:00" });
                props.setScheduleBody({ timeTo: "18:00" });
                props.setScheduleBody({ timeOthers: "00:00" });
              }}
            >
              Tarde
            </Button>
          </Col>
          <Col className="mb-3" xs={6} md="2">
            <Button
              style={{ width: "100%" }}
              disabled={
                props.readOnly ||
                props.saving ||
                props.editing ||
                props.scheduleBody.serviceOrder
              }
              onClick={() => {
                props.setScheduleBody({ timeFrom: "08:00" });
                props.setScheduleBody({ timeTo: "18:00" });
                props.setScheduleBody({ timeOthers: "01:00" });
              }}
            >
              Comercial
            </Button>
          </Col>
          <Col className="mb-3" xs={6} md="2">
            <Button
              style={{ width: "100%" }}
              disabled={
                props.readOnly ||
                props.saving ||
                props.editing ||
                props.scheduleBody.serviceOrder
              }
              onClick={() => {
                props.setScheduleBody({ timeFrom: "19:00" });
                props.setScheduleBody({ timeTo: "22:00" });
                props.setScheduleBody({ timeOthers: "00:00" });
              }}
            >
              Noturno
            </Button>
          </Col>
        </div>
      )}

      <div className="form-row">
        <Col className="mb-3" xs="12" md="2">
          <label className="form-control-label" htmlFor="timeFrom">
            Início
          </label>
          <Input
            aria-describedby="inputGroupPrepend"
            id="timeFrom"
            type="time"
            value={props.scheduleBody.timeFrom}
            readOnly={props.readOnly}
            disabled={props.saving || props.scheduleBody.serviceOrder}
            valid={props.scheduleBody.timeFrom !== ""}
            invalid={props.scheduleBody.timeFrom === ""}
            onChange={(e) => {
              props.setScheduleBody({ timeFrom: e.target.value });
            }}
          />
          <div className="invalid-feedback">Digite uma hora.</div>
        </Col>
        <Col className="mb-3" xs="12" md="2">
          <label className="form-control-label" htmlFor="timeTo">
            Término
          </label>
          <Input
            aria-describedby="inputGroupPrepend"
            id="timeTo"
            type="time"
            value={props.scheduleBody.timeTo}
            readOnly={props.readOnly}
            disabled={props.saving || props.scheduleBody.serviceOrder}
            valid={props.scheduleBody.timeTo !== ""}
            invalid={props.scheduleBody.timeTo === ""}
            onChange={(e) => {
              props.setScheduleBody({ timeTo: e.target.value });
            }}
          />
          <div className="invalid-feedback">Digite uma hora.</div>
        </Col>
        <Col className="mb-3" xs="12" md="2">
          <label className="form-control-label" htmlFor="timeCommute">
            Traslado
          </label>
          <Input
            aria-describedby="inputGroupPrepend"
            id="timeCommute"
            type="text"
            value={props.scheduleBody.timeCommute}
            readOnly={true}
            disabled={props.saving || props.scheduleBody.serviceOrder}
          />
          <div className="invalid-feedback">Digite uma Hora.</div>
        </Col>
        <Col className="mb-3" xs="12" md="2">
          <label className="form-control-label" htmlFor="timeOthers">
            Outros
          </label>
          <Input
            aria-describedby="inputGroupPrepend"
            id="timeOthers"
            type="time"
            value={props.scheduleBody.timeOthers}
            readOnly={props.readOnly}
            disabled={props.saving || props.scheduleBody.serviceOrder}
            valid={props.scheduleBody.timeOthers !== ""}
            invalid={props.scheduleBody.timeOthers === ""}
            onChange={(e) => {
              props.setScheduleBody({ timeOthers: e.target.value });
            }}
          />
          <div className="invalid-feedback">Digite uma hora.</div>
        </Col>
        <Col className="mb-3" xs="12" md="2">
          <label
            className="form-control-label"
            htmlFor="validationCustomUsername"
          >
            Total
          </label>
          <Input
            aria-describedby="inputGroupPrepend"
            id="timeTotal"
            type="time"
            value={props.scheduleBody.timeTotal}
            readOnly={true}
            disabled={props.saving}
          />
        </Col>
      </div>

      <div className="form-row">
        <Col className="mb-2 pb-2" md="2">
          <label className="form-control-label" htmlFor="billling">
            Faturamento
          </label>
          <div>
            <label className="custom-toggle custom-toggle-default mt-2">
              <input
                checked={props.scheduleBody.billing}
                type="checkbox"
                id="billing"
                value={props.scheduleBody.billing}
                disabled={
                  props.saving ||
                  props.readOnly ||
                  props.readOnlyFields?.includes("billing")
                }
                onChange={(e) => {
                  props.setScheduleBody({
                    billing: !props.scheduleBody.billing,
                  });
                }}
                valid={props.scheduleBody.billing !== ""}
                invalid={props.scheduleBody.billing === ""}
              />
              <span
                className="custom-toggle-slider rounded-circle"
                data-label-off="Não"
                data-label-on="Sim"
              />
            </label>
          </div>
          <div className="invalid-feedback">Selecione uma opção.</div>
        </Col>
        <Col className="mb-3" lg="10" md="12">
          <label className="form-control-label" htmlFor="project">
            Projeto
          </label>
          <Select2
            type="text"
            value={props.scheduleBody.project}
            disabled={
              !props.scheduleBody.customer ||
              props.editing ||
              props.readOnly ||
              props.scheduleBody.serviceOrder
            }
            valid={props.scheduleBody.project !== ""}
            invalid={props.scheduleBody.project === ""}
            onSelect={(e) => {
              props.setScheduleBody({ project: e.target.value });
            }}
            options={{
              size: "small",
              placeholder: "Selecione um projeto",
              language: {
                noResults: function () {
                  return "Nenhum projeto encontrado.";
                },
              },
            }}
            data={projects.map((project) => {
              return {
                id: project._id,
                text: project.name + " - " + project.code,
              };
            })}
          ></Select2>
          <div className="invalid-feedback">Selecione um projeto</div>
        </Col>
      </div>
      <div className="form-row">
        <Col
          className="mb-3"
          md={
            props.scheduleBody.status === "PA" ||
            props.scheduleBody.status === "RJ"
              ? 9
              : 12
          }
        >
          <label className="form-control-label" htmlFor="activity">
            Atividade
          </label>
          <Input
            aria-describedby="inputGroupPrepend"
            id="activity"
            type="textarea"
            value={props.scheduleBody.activity}
            readOnly={props.readOnly}
            disabled={props.saving || props.scheduleBody.serviceOrder}
            valid={props.scheduleBody.activity !== ""}
            invalid={props.scheduleBody.activity === ""}
            onChange={(e) => {
              props.setScheduleBody({ activity: e.target.value });
            }}
          />
          <div className="invalid-feedback">Descreva a atividade</div>
        </Col>
        {props.scheduleBody.status === "PA" ||
        props.scheduleBody.status === "RJ" ? (
          <Col className="mb-3" md="3">
            <label className="form-control-label" htmlFor="activity">
              Requisitada em
            </label>
            <Input
              aria-describedby="inputGroupPrepend"
              id="activity"
              type="text"
              value={
                new Date(props.scheduleBody.createdAt).toLocaleDateString(
                  "pt-BR"
                ) +
                " às " +
                new Date(props.scheduleBody.createdAt).toLocaleTimeString(
                  "pt-BR",
                  { hour: "2-digit", minute: "2-digit" }
                )
              }
              readOnly={true}
              disabled={true}
            />
          </Col>
        ) : null}
      </div>

      <ConfirmationModal
        action={{
          text: "Rejeitar Agenda",
          blockConfirm: props.scheduleBody.rejectionReason === "",
        }}
        isOpen={props.confirmationModalOpen}
        confirm={() => {
          props.setConfirmationModalOpen(false);
          props.rejectSchedule();
        }}
        disa
        closeModal={() => props.setConfirmationModalOpen(false)}
        deny={() => props.setConfirmationModalOpen(false)}
      >
        <div className="form-row">
          <Col className="mb-3" md="12">
            <label className="form-control-label" htmlFor="activity">
              Motivo
            </label>
            <Input
              aria-describedby="inputGroupPrepend"
              id="activity"
              type="textarea"
              value={props.scheduleBody.rejectionReason}
              valid={props.scheduleBody.rejectionReason !== ""}
              invalid={props.scheduleBody.rejectionReason === ""}
              onChange={(e) => {
                props.setScheduleBody({ rejectionReason: e.target.value });
              }}
            />
            <div className="invalid-feedback">
              Descreva o motivo para rejeição
            </div>
          </Col>
        </div>
      </ConfirmationModal>
    </Form>
  );
}
