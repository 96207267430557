import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import React from 'react'
import { Button, Card, CardBody, CardHeader } from 'reactstrap'

const imageHeader = require('assets/img/brand/marca-original.png').default

const border = {
  borderStyle: 'solid',
  borderColor: 'black',
  borderWidth: '1px',
  padding: '0px 5px 0px 5px'
}
const blackTimes = {
  fontFamily: 'Times New Roman',
  color: 'black'
}

//TODO: Make the PDF itself a component
function OSs (props) {
  /** */
  async function printDocument () {
    const pdf = new jsPDF()
    for (let index = 0; index < props.serviceOrders.length; index++) {
      const input = document.getElementById(`divToPrint-${index}`)

      const canvas = await html2canvas(input)
      const imgData = canvas.toDataURL('image/png')
      pdf.addImage(imgData, 'PNG', 10, 10, 190, 297)
      if (index !== props.serviceOrders.length - 1) {
        pdf.addPage()
      }
    }
    pdf.save(`OSS_${props.invoice.customer.shortName}.pdf`)
  }

  return (
    <div>
      {props.serviceOrders?.length > 0 && (
        <>
          <Card style={{ width: '100%' }}>
            <CardHeader>
              <Button
                hidden={props.printAll}
                type='button'
                onClick={e => {
                  printDocument()
                }}
              >
                Baixar
              </Button>
            </CardHeader>
            <CardBody style={{ overflow: 'hidden' }}>
              <div>
                <div>
                  {props.serviceOrders.map((serviceOrder, index) => {
                    return (
                      <div
                        key={index}
                        id={`divToPrint-${index}`}
                        style={{
                          fontFamily: 'Times New Roman',
                          width: '210mm',
                          height: '297mm'
                        }}
                      >
                        <div style={{ margin: 'auto' }}>
                          <div style={{ ...border, marginBottom: '10px' }}>
                            <div
                              style={{
                                align: 'left',
                                display: 'inline-block',
                                width: '33%'
                              }}
                            ></div>
                            <div
                              style={{
                                align: 'center',
                                display: 'inline-block',
                                width: '33%',
                                textAlign: 'center',
                                fontWeight: 'bolder'
                              }}
                            >
                              <h2
                                style={{
                                  ...blackTimes,
                                  fontSize: '24px',
                                  fontWeight: 'bold'
                                }}
                              >
                                Ordem de Serviço
                              </h2>
                            </div>
                            <div
                              style={{
                                align: 'right',
                                display: 'inline-block',
                                textAlign: 'right',
                                width: '33%'
                              }}
                            >
                              <img
                                src={imageHeader}
                                alt={''}
                                style={{ maxWidth: '80%', maxHeight: '10%' }}
                              ></img>
                            </div>
                          </div>
                          {/* golive ldta  */}
                          <div
                            style={{
                              ...border,
                              marginBottom: '10px'
                            }}
                          >
                            <div
                              style={{
                                align: 'left',
                                display: 'inline-block',
                                textAlign: 'left',
                                width: '50%'
                              }}
                            >
                              <span
                                style={{
                                  ...blackTimes,
                                  fontWeight: 'bold',
                                  fontSize: '15px'
                                }}
                              >
                                GoLive Consultoria Ltda.
                              </span>
                            </div>

                            <div
                              style={{
                                align: 'right',
                                display: 'inline-block',
                                width: '50%',
                                textAlign: 'right'
                              }}
                            >
                              <span style={{ ...blackTimes, fontSize: '15px' }}>
                                CNPJ: 13.994.051/0001-28
                              </span>
                            </div>
                          </div>
                          <div style={{ ...border }}>
                            <span style={{ ...blackTimes, fontSize: '18px' }}>
                              Consultor: {serviceOrder.consultant.fullName}
                            </span>
                          </div>
                          <div style={{ ...border }}>
                            <span style={{ ...blackTimes, fontSize: '18px' }}>
                              Cliente: {serviceOrder.customer.shortName}
                            </span>
                          </div>
                          <div style={{ ...border, marginBottom: '10px' }}>
                            <span style={{ ...blackTimes, fontSize: '18px' }}>
                              CNPJ: {serviceOrder.customer.cnpj}
                            </span>
                          </div>
                          {/* Date and hours */}
                          <div>
                            <div
                              style={{
                                ...border,
                                width: '50%',
                                minHeight: '80px',
                                display: 'inline-block',
                                verticalAlign: 'top'
                              }}
                            >
                              <div
                                style={{
                                  display: 'inline-block',
                                  align: 'left',
                                  width: '20%',
                                  verticalAlign: 'top'
                                }}
                              >
                                <span style={{ ...blackTimes }}>
                                  {' '}
                                  Data O.S:
                                </span>
                              </div>
                              <div
                                style={{
                                  display: 'inline-block',
                                  align: 'Right',
                                  width: '80%',
                                  textAlign: 'center'
                                }}
                              >
                                <span
                                  style={{ ...blackTimes, fontSize: '30px' }}
                                >
                                  {new Date(
                                    serviceOrder.date.split('T')[0] + ' 00:00'
                                  ).toLocaleDateString('pt-BR')}
                                </span>
                              </div>
                            </div>
                            <div
                              style={{
                                ...border,
                                borderLeft: 'none',
                                borderBottom: 'none',
                                width: '50%',
                                display: 'inline-block',
                                minHeight: '80px'
                              }}
                            >
                              <span style={{ ...blackTimes }}>
                                Período: {serviceOrder.timeFrom} às{' '}
                                {serviceOrder.timeTo}
                              </span>
                              <br></br>
                              <span style={{ ...blackTimes }}>
                                Traslado: {serviceOrder.timeCommute}{' '}
                              </span>
                              <br></br>

                              <span style={{ ...blackTimes }}>
                                Outros: {serviceOrder.timeOthers}
                              </span>
                            </div>
                          </div>
                          <div style={{ marginBottom: '20px' }}>
                            <div
                              style={{
                                width: '50%',
                                display: 'inline-block',
                                verticalAlign: 'top'
                              }}
                            ></div>
                            <div
                              style={{
                                ...border,
                                width: '50%',
                                display: 'inline-block'
                              }}
                            >
                              <span style={{ ...blackTimes }}>Total :</span>{' '}
                              <span
                                style={{
                                  ...blackTimes,
                                  fontSize: '20px',
                                  fontWeight: 'bold'
                                }}
                              >
                                {serviceOrder.timeTotal}
                              </span>
                            </div>
                          </div>
                          {/* Description */}

                          <div style={{ ...border, marginBottom: '20px' }}>
                            <span style={{ ...blackTimes, fontWeight: 'bold' }}>
                              Descrição de Atividades:
                            </span>
                          </div>
                          <div style={{ ...border, marginBottom: '20px' }}>
                            <span style={{ ...blackTimes }}>
                              <div
                                style={{ whiteSpace: 'pre-wrap' }}
                                dangerouslySetInnerHTML={{
                                  __html: serviceOrder.description
                                }}
                              />
                            </span>{' '}
                          </div>
                          <div style={{ ...border, marginBottom: '50px' }}>
                            <span style={{ ...blackTimes }}>
                              Eventuais restrições quanto ao conteúdo,
                              apontamentos ou outros dados contidos nas Ordens
                              de Serviços aqui listadas devem ser apontadas no
                              verso deste documento. O cliente ainda terá um
                              prazo adicional de 48 horas, contados a partir da
                              emissão desta, para apontar restrições.
                            </span>
                          </div>
                          {/* warning */}
                          <div>
                            <div
                              style={{
                                align: 'left',
                                width: '50%',
                                display: 'inline-block',
                                textAlign: 'center'
                              }}
                            >
                              <span>_____________________________________</span>
                              <br></br>
                              <span
                                style={{
                                  ...blackTimes,
                                  fontSize: '20px',
                                  fontWeight: 'bold'
                                }}
                              >
                                {serviceOrder.consultant.fullName}
                              </span>
                            </div>
                            <div
                              style={{
                                align: 'right',
                                width: '50%',
                                display: 'inline-block',
                                textAlign: 'center'
                              }}
                            >
                              <span>_____________________________________</span>
                              <br></br>
                              <span
                                style={{
                                  ...blackTimes,
                                  fontSize: '20px',
                                  fontWeight: 'bold'
                                }}
                              >
                                {serviceOrder.customer.fullName}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </CardBody>
          </Card>
        </>
      )}
    </div>
  )
}

export default OSs
