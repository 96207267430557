import PropTypes from "prop-types"
import SglTable from "components/ToolkitTable/SglTable"
import React, { useState } from "react"
import { Col, Row, Table } from "reactstrap"
import { convertMongoDate } from "utils/dateHandling"
import Skeleton from "react-loading-skeleton"
/**
 * Returns week day in text
 *
 * @param {number} day
 */
function weekDayFactory(day) {
  switch (day) {
    case 0:
      return "Domingo"
    case 1:
      return "Segunda-Feira"
    case 2:
      return "Terça-Feira"
    case 3:
      return "Quarta-Feira"
    case 4:
      return "Quinta-Feira"
    case 5:
      return "Sexta-Feira"
    case 6:
      return "Sábado"
    default:
      return "Domingo"
  }
}
function HollidaysTable({ hollidays, rowClick, isLoading }) {
  const columns = [
    {
      dataField: "date",
      text: "Data",
      sort: true,
      hidden: false,
      formatter: (cell) => {
        return convertMongoDate(cell)
      },
    },
    {
      dataField: "date",
      text: "Dia da Semana",
      formatter: (cell) => {
        const weekDay = new Date(cell.split("T"[0])).getDay() + 1 //get day is returning 1 day late...
        return weekDayFactory(weekDay)
      },
    },
    {
      dataField: "reason",
      text: "Motivo",
      sort: true,
    },
    {
      dataField: "hours",
      text: "Horas",
      sort: true,
      hidden: false,
    },
  ]

  if (isLoading)
    return (
      <center>
        <Skeleton
          count={10}
          width="95%"
          height={30}
          borderRadius="5px"
          inline
        />
      </center>
    )
  else if (!Array.isArray(hollidays) || hollidays.length < 1)
    return <h2 className="mx-4 my-2">Não há feriados cadastrados</h2>
  else
    return (
      <>
        <Row>
          <Col xs={12}>
            {hollidays.length > 0 && (
              <SglTable
                columns={columns}
                data={hollidays.map((holliday) => ({
                  ...holliday,
                }))}
                rowClick={(row) => rowClick(row)}
              />
            )}
          </Col>
        </Row>
      </>
    )
}

HollidaysTable.propTypes = {
  hollidays: PropTypes.shape({
    length: PropTypes.number,
  }),
  rowClick: PropTypes.func,
}

export default HollidaysTable
