import React from "react"
import { Button, Col, Input, Row } from "reactstrap"
import Select2 from "react-select2-wrapper"

function ProjectsReportsTableHeader(props) {
  return (
    <>
      <Row>
        <Col lg="6">
          <h3 className="mb-0">Projeto Previsto x Realizado</h3>
        </Col>
      </Row>
      <Row style={{ paddingTop: "3vh" }}>
        {" "}
        <Col className="mb-3" lg="4">
          <label className="form-control-label" htmlFor="customer">
            Cliente
          </label>
          <Select2
            type="text"
            id="customer"
            defaultValue={props.customer?._id}
            value={props.customer?._id}
            onChange={(e) => {
              props.selectCustomer(e.target.value)
            }}
            options={{
              size: "small",
              placeholder: "Selecione um Cliente",
              language: {
                noResults: function () {
                  return "Nenhum cliente encontrado."
                },
              },
            }}
            data={props.customers.map((customer) => {
              return {
                id: customer._id,
                text: customer.shortName,
              }
            })}
          />
        </Col>
        <Col className="mb-3" lg="4">
          <label className="form-control-label" htmlFor="service">
            Projeto
          </label>
          <Select2
            disabled={!props.customer}
            className="form-control"
            type="text"
            value={props.project}
            onSelect={(e) => props.setProject(e.target.value)}
            options={{
              placeholder: "Selecione um projeto",
              language: {
                noResults: function () {
                  return "Nenhum projeto encontrado."
                },
              },
            }}
            data={props.projects.map((project) => {
              return {
                id: project._id,
                text: project.name + " | " + project.code,
              }
            })}
          />
        </Col>
        <Col lg="2 mt-3">
          <Button
            type="button"
            id="authorizeAll"
            className="mt-3"
            onClick={(e) => props.fetchConsultants()}
            disabled={!props.project}
          >
            Filtrar
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg="2">
          <label className="form-control-label" htmlFor="dateTo">
            Data de
          </label>
          <Input
            aria-describedby="inputGroupPrepend"
            id="dateFrom"
            type="date"
            value={props.dateFrom}
            onChange={(e) => props.setDateFrom(e.target.value)}
          />
        </Col>
        <Col lg="2">
          <label className="form-control-label" htmlFor="dateTo">
            Data até
          </label>
          <Input
            aria-describedby="inputGroupPrepend"
            id="dateTo"
            type="date"
            onChange={(e) => props.setDateTo(e.target.value)}
          />
        </Col>

        <Col className="mt-3" lg="3" style={{ textAlignLast: "end" }}></Col>
      </Row>
    </>
  )
}

export default ProjectsReportsTableHeader
