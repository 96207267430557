import React from "react"
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  CardBody,
  Form,
  Input,
  ListGroup,
} from "reactstrap"

// core components
import LightHeader from "components/Headers/LightHeader.js"
import ReactBSAlert from "react-bootstrap-sweetalert"
import InputMask from "react-input-mask"

import ListGroupItemContact from "components/ListGroupItems/ListGroupItemContact.js"
import ListGroupItemAddress from "components/ListGroupItems/ListGroupItemAddress"

import api from "services/api"
import Loading from "components/Modals/Loading"

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPage: 25,
  paginationTotalRenderer: (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" [ "} Mostrando linhas {from} a {to} de {size} ]
    </span>
  ),
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Mostrar{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        registros.
      </label>
    </div>
  ),
})

const { SearchBar } = Search

class Clients extends React.Component {
  state = {
    data: [],
    isLoading: true,
    saving: false,
    alert: null,

    //Client panel data variables
    dataPanelTitle: "",
    crud: "",
    readOnly: false,
    id: "",
    cnpj: "",
    oldCnpj: "",
    ie: "",
    fullName: "",
    shortName: "",
    active: true,
    addresses: [],
    contacts: [],
    //New contact
    creatingContact: false,
    updatingContact: false,
    newContactName: "",
    newContactPhone: "",
    newContactEmail: "",
    newContactDepartment: "",
    newContactNotes: "",
    newContactBilling: false,
    newContactNfse: false,
    //New address
    creatingAddress: false,
    updatingAddress: false,
    newAddressDescription: "",
    newAddressAddress: "",
    newAddressRegion: "",
    newAddressCity: "",
    newAddressAdditionalInfo: "",
    newAddressState: "",
    newAddressPostalCode: "",
    newAddressTransfer: "",
    newAddressDefaultAddress: false,

    //Client data fields validations
    customStyles: {
      cnpjState: null,
      ieState: null,
      fullNameState: null,
      shortNameState: null,
      //New contact
      newContactNameState: null,
      newContactPhoneState: null,
      newContactEmailState: null,
      newContactDepartmentState: null,
      //New address
      newAddressDescriptionState: null,
      newAddressAddressState: null,
      newAddressRegionState: null,
      newAddressCityState: null,
      newAddressAdditionalInfoState: null,
      newAddressStateState: null,
    },
  }

  componentDidMount() {
    this.loadData()
  }

  loadData = async () => {
    this.setState({
      isLoading: true,
      crud: "",
    })

    var response = await api.get("/customer/all")

    this.setState({ data: response.data, isLoading: false })
  }

  openDataPanel = async (e, row, crud) => {
    let newState = this.state

    newState.crud = crud

    if (crud === "C") {
      newState.dataPanelTitle = "Novo Cliente"
    } else if (crud === "R") {
      newState.dataPanelTitle = "Visualizando Cliente"
    } else if (crud === "U") {
      newState.dataPanelTitle = "Alterando Cliente"
    } else if (crud === "D") {
      newState.dataPanelTitle = "Excluindo Cliente"
    }

    if (crud === "C") {
      newState.id = ""
      newState.cnpj = ""
      newState.oldCnpj = ""
      newState.ie = ""
      newState.fullName = ""
      newState.shortName = ""
      newState.active = true
      newState.addresses = []
      newState.contacts = []
    } else {
      newState.readOnly = crud === "R" || crud === "D"
      newState.id = row._id
      newState.cnpj = row.cnpj
      newState.oldCnpj = row.cnpj
      newState.ie = row.ie
      newState.fullName = row.fullName
      newState.shortName = row.shortName
      newState.active = row.active

      var response = await api.post("/customerContact/search", {
        deleted: "N",
        customer: row._id,
      })

      newState.contacts = response.data

      for (let i = 0; i < newState.contacts.length; i++) {
        newState.contacts[i] = {
          ...newState.contacts[i],
          contactCrud: "R",
          neededAction: "",
        }
      }

      response = await api.post("/customerAddress/search", {
        deleted: "N",
        customer: row._id,
      })

      newState.addresses = response.data

      for (let i = 0; i < newState.addresses.length; i++) {
        newState.addresses[i] = {
          ...newState.addresses[i],
          addressCrud: "R",
          neededAction: "",
        }
      }
    }

    this.setState(newState)
  }

  closeDataPanel(e) {
    this.setState({
      crud: "",
      readOnly: false,
      saving: false,
      id: "",
      cnpj: "",
      oldCnpj: "",
      ie: "",
      fullName: "",
      shortName: "",
      active: true,
      contacts: [],
      addresses: [],
      //New contact
      creatingContact: false,
      updatingContact: false,
      newContactName: "",
      newContactPhone: "",
      newContactEmail: "",
      newContactDepartment: "",
      newContactNotes: "",
      newContactBilling: false,
      newContactNfse: false,
      //New address
      creatingAddress: false,
      updatingAddress: false,
      newAddressDescription: "",
      newAddressAddress: "",
      newAddressRegion: "",
      newAddressCity: "",
      newAddressAdditionalInfo: "",
      newAddressState: "",
      newAddressPostalCode: "",
      newAddressTransfer: "",
      newAddressDefaultAddress: false,

      //Client data fields validations
      customStyles: {
        cnpjState: null,
        ieState: null,
        fullNameState: null,
        shortNameState: null,
        //New contact
        newContactNameState: null,
        newContactPhoneState: null,
        newContactEmailState: null,
        newContactDepartmentState: null,
        //New address
        newAddressDescriptionState: null,
        newAddressAddressState: null,
        newAddressRegionState: null,
        newAddressCityState: null,
        newAddressAdditionalInfoState: null,
        newAddressStateState: null,
      },
    })
  }

  saveClientData = async (e) => {
    this.setState({ saving: true })

    let newState = this.state

    var letSave = true
    var newData = {}
    var success = true

    if (this.state.crud === "D") {
      const projects = await api.post("/project/search", {
        customer: this.state.id,
      })

      if (projects.data.length > 0) {
        this.warningAlert("Este cliente precisa ser excluído via Smartflow.")
        success = false
        this.setState({ saving: false })
        return
      }

      try {
        //Delete contacts
        for (let i = 0; i < newState.contacts.length; i++) {
          await api.put("/customerContact/update/" + newState.contacts[i]._id, {
            deleted: "Y",
          })
        }

        //Delete addresses
        for (let i = 0; i < newState.addresses.length; i++) {
          await api.put(
            "/customerAddress/update/" + newState.addresses[i]._id,
            { deleted: "Y" }
          )
        }

        //Delete client
        await api.put("/customer/update/" + this.state.id, { deleted: "Y" })
      } catch (err) {
        this.warningAlert("Ocorreu um erro ao tentar excluir. Tente novamente.")
        success = false
        this.setState({ saving: false })
        return
      }

      if (success) {
        this.loadData()
        this.closeDataPanel()
      }
    } else if (this.state.crud === "C" || this.state.crud === "U") {
      var clientId = this.state.id

      //Field content validations
      if (newState.fullName === "") {
        newState.fullNameState = "invalid"
        letSave = false
      } else {
        newState.fullNameState = "valid"
      }

      if (newState.shortName === "") {
        newState.shortNameState = "invalid"
        letSave = false
      } else {
        newState.shortNameState = "valid"
      }

      if (newState.cnpj === "") {
        newState.cnpjState = "invalid"
        letSave = false
      } else {
        newState.cnpjState = "valid"
      }

      if (newState.ie === "") {
        newState.ieState = "invalid"
        letSave = false
      } else {
        newState.ieState = "valid"
      }

      this.setState({
        customStyles: newState,
      })

      if (!letSave) {
        this.setState({ saving: false })
        return
      } else {
        const existCustomer = await api.post("/customer/search", {
          cnpj: this.state.cnpj,
          deleted: "N",
        })

        if (existCustomer.data.length > 0) {
          if (existCustomer.data[0]._id !== this.state.id) {
            this.warningAlert("Já existe um cliente cadastrado com este CNPJ.")
            this.setState({ saving: false })
            return
          }
        }

        newData = {
          cnpj: this.state.cnpj,
          oldCnpj: this.state.oldCnpj,
          ie: this.state.ie,
          fullName: this.state.fullName,
          shortName: this.state.shortName,
          active: this.state.active,
        }

        success = true

        if (this.state.crud === "C") {
          try {
            const newClient = await api.post("/customer/create", newData)

            clientId = newClient.data._id
          } catch (err) {
            this.warningAlert("Houve um problema ao cadastrar o cliente.")
            success = false
            this.setState({ saving: false })
            return
          }
        } else {
          try {
            await api.put("/customer/update/" + this.state.id, newData)
          } catch (err) {
            this.warningAlert(
              "Ocorreu um erro ao tentar alterar. Tente novamente."
            )
            success = false
            this.setState({ saving: false })
            return
          }
        }

        if (success) {
          //Saving contacts data
          for (let i = 0; i < newState.contacts.length; i++) {
            if (newState.contacts[i].neededAction !== "") {
              let contactData = {
                customer: clientId,
                name: newState.contacts[i].name,
                department: newState.contacts[i].department,
                notes: newState.contacts[i].notes,
                phone: newState.contacts[i].phone,
                email: newState.contacts[i].email,
                nfse: newState.contacts[i].nfse,
                billing: newState.contacts[i].billing,
              }

              if (newState.contacts[i].neededAction === "C") {
                await api.post("/customerContact/create", contactData)
              } else {
                if (newState.contacts[i].neededAction === "U") {
                  await api.put(
                    "/customerContact/update/" + newState.contacts[i]._id,
                    contactData
                  )
                } else {
                  if (newState.contacts[i]._id) {
                    await api.put(
                      "/customerContact/update/" + newState.contacts[i]._id,
                      { deleted: "Y" }
                    )
                  }
                }
              }
            }
          }

          //Saving addresses data
          for (let i = 0; i < newState.addresses.length; i++) {
            if (newState.addresses[i].neededAction !== "") {
              let addressData = {
                customer: clientId,
                description: newState.addresses[i].description,
                address: newState.addresses[i].address,
                region: newState.addresses[i].region,
                city: newState.addresses[i].city,
                additionalInfo: newState.addresses[i].additionalInfo,
                state: newState.addresses[i].state,
                postalCode: newState.addresses[i].postalCode,
                transfer: newState.addresses[i].transfer,
                defaultAddress: newState.addresses[i].defaultAddress,
              }

              if (newState.addresses[i].neededAction === "C") {
                await api.post("/customerAddress/create", addressData)
              } else {
                if (newState.addresses[i].neededAction === "U") {
                  await api.put(
                    "/customerAddress/update/" + newState.addresses[i]._id,
                    addressData
                  )
                } else {
                  if (newState.addresses[i]._id) {
                    await api.put(
                      "/customerAddress/update/" + newState.addresses[i]._id,
                      { deleted: "Y" }
                    )
                  }
                }
              }
            }
          }

          this.closeDataPanel()
          this.loadData()
        }
      }
    }
  }

  stylesForm(e, stateName, checkbox) {
    if (checkbox) {
      let newCheck = this.state[stateName]
      newCheck = newCheck ? false : true

      this.setState({
        [stateName]: newCheck,
      })

      return
    }

    let newState = this.state
    let newCustomStyles = newState.customStyles
    let newValue = e.target.value

    if (newValue === null || newValue === "") {
      newCustomStyles[stateName + "State"] = "invalid"
    } else {
      newCustomStyles[stateName + "State"] = "valid"
    }

    if (stateName === "newContactPhone") {
      newValue = this.adjustMaskValue(newValue)
    }

    newState.customStyles = newCustomStyles
    newState[stateName] = newValue

    this.setState(newState)
  }

  warningAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Atenção!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </ReactBSAlert>
      ),
    })
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    })
  }

  adjustMaskValue = (v) => {
    const digitos = !v ? "" : v.replace(/[^\d]/g, "")
    if (!digitos || digitos.length < 10) return v
    const corte = digitos.length === 10 ? 6 : 7
    const max = digitos.length > 11 ? 11 : digitos.length
    return `(${digitos.substring(0, 2)}) ${digitos.substring(
      2,
      corte
    )}-${digitos.substring(corte, max)}`
  }

  phoneMaskBuilder = (v) => {
    if (!v || v.length === 0) return ""
    const a = this.adjustMaskValue(v)
    return a.length >= 6 && a[5] === "9" ? "(99) 99999-9999" : "(99) 9999-9999"
  }

  getContacts = (contacts) => {
    return contacts.map((prop, key) => {
      return (
        <ListGroupItemContact
          key={key}
          id={prop._id}
          crud={this.state.crud}
          name={prop.name}
          department={prop.department}
          notes={prop.notes}
          phone={prop.phone}
          email={prop.email}
          nfse={prop.nfse}
          billing={prop.billing}
          contactCrud={prop.contactCrud}
          neededAction={prop.neededAction}
          newContactName={this.state.newContactName}
          newContactPhone={this.state.newContactPhone}
          newContactEmail={this.state.newContactEmail}
          newContactDepartment={this.state.newContactDepartment}
          newContactNotes={this.state.newContactNotes}
          newContactBilling={this.state.newContactBilling}
          newContactNfse={this.state.newContactNfse}
          customStyles={this.state.customStyles}
          creatingContact={this.state.creatingContact}
          updatingContact={this.state.updatingContact}
          stylesForm={this.stylesForm.bind(this)}
          cancelNewContact={this.cancelNewContact.bind(this)}
          saveNewContact={this.saveNewContact.bind(this)}
          deleteContact={this.deleteContact.bind(this)}
          cancelContactDelete={this.cancelContactDelete.bind(this)}
          updateContact={this.updateContact.bind(this)}
          cancelContactUpdate={this.cancelContactUpdate.bind(this)}
          phoneMaskBuilder={this.phoneMaskBuilder.bind(this)}
        />
      )
    })
  }

  getAddresses = (addresses) => {
    return addresses.map((prop, key) => {
      return (
        <ListGroupItemAddress
          key={key}
          crud={this.state.crud}
          id={prop._id}
          description={prop.description}
          address={prop.address}
          additionalInfo={prop.additionalInfo}
          region={prop.region}
          city={prop.city}
          state={prop.state}
          postalCode={prop.postalCode}
          defaultAddress={prop.defaultAddress}
          transfer={prop.transfer}
          addressCrud={prop.addressCrud}
          neededAction={prop.neededAction}
          newAddressDescription={this.state.newAddressDescription}
          newAddressAddress={this.state.newAddressAddress}
          newAddressRegion={this.state.newAddressRegion}
          newAddressCity={this.state.newAddressCity}
          newAddressAdditionalInfo={this.state.newAddressAdditionalInfo}
          newAddressState={this.state.newAddressState}
          newAddressPostalCode={this.state.newAddressPostalCode}
          newAddressTransfer={this.state.newAddressTransfer}
          newAddressDefaultAddress={this.state.newAddressDefaultAddress}
          creatingAddress={this.state.creatingAddress}
          updatingAddress={this.state.updatingAddress}
          customStyles={this.state.customStyles}
          stylesForm={this.stylesForm.bind(this)}
          cancelNewAddress={this.cancelNewAddress.bind(this)}
          saveNewAddress={this.saveNewAddress.bind(this)}
          deleteAddress={this.deleteAddress.bind(this)}
          cancelAddressDelete={this.cancelAddressDelete.bind(this)}
          updateAddress={this.updateAddress.bind(this)}
          cancelAddressUpdate={this.cancelAddressUpdate.bind(this)}
        />
      )
    })
  }

  createNewContact(e) {
    let newState = this.state
    let currentContacts = newState.contacts
    let newContacts = [
      {
        contactCrud: "C",
        name: "",
        phone: "",
        email: "",
        department: "",
        notes: "",
        billing: false,
        nf: false,
      },
    ]

    for (let i = 0; i < currentContacts.length; i++) {
      newContacts.push(currentContacts[i])
    }

    newState.contacts = newContacts
    newState.creatingContact = true

    this.setState(newState)
  }

  cancelNewContact(e) {
    let newState = this.state
    let newContacts = newState.contacts

    newContacts.shift()

    this.setState({
      contacts: newContacts,
      creatingContact: false,
      newContactName: "",
      newContactPhone: "",
      newContactEmail: "",
      newContactDepartment: "",
      newContactNotes: "",
      newContactNfse: false,
      newContactBilling: false,
      customStyles: {
        newContactNameState: null,
        newContactPhoneState: null,
        newContactEmailState: null,
        newContactDepartmentState: null,
      },
    })
  }

  saveNewContact(e, contact) {
    if (contact.contactCrud === "C" || contact.contactCrud === "U") {
      let newState = this.state
      let letSave = true

      if (newState.newContactName === "") {
        newState.customStyles.newContactNameState = "invalid"
        letSave = false
      } else {
        newState.customStyles.newContactNameState = "valid"
      }

      if (newState.newContactPhone === "") {
        newState.customStyles.newContactPhoneState = "invalid"
        letSave = false
      } else {
        newState.customStyles.newContactPhoneState = "valid"
      }

      if (newState.newContactEmail === "") {
        newState.customStyles.newContactEmailState = "invalid"
        letSave = false
      } else {
        newState.customStyles.newContactEmailState = "valid"
      }

      if (newState.newContactDepartment === "") {
        newState.customStyles.newContactDepartmentState = "invalid"
        letSave = false
      } else {
        newState.customStyles.newContactDepartmentState = "valid"
      }

      this.setState(newState)

      if (!letSave) {
        return
      }

      let d = new Date()

      newState.contacts[0].contactCrud = ""
      newState.contacts[0].neededAction = "C"
      newState.contacts[0]._id =
        "NEW_CONTACT_" + d.getHours() + d.getMinutes() + d.getSeconds()
      newState.contacts[0].name = newState.newContactName
      newState.contacts[0].phone = newState.newContactPhone
      newState.contacts[0].email = newState.newContactEmail
      newState.contacts[0].department = newState.newContactDepartment
      newState.contacts[0].notes = newState.newContactNotes
      newState.contacts[0].billing = newState.newContactBilling
      newState.contacts[0].nfse = newState.newContactNfse

      newState.creatingContact = false
      newState.newContactName = ""
      newState.newContactPhone = ""
      newState.newContactEmail = ""
      newState.newContactDepartment = ""
      newState.newContactNotes = ""
      newState.newContactBilling = false
      newState.newContactNfse = false

      this.setState(newState)
    }
  }

  deleteContact(e, contact) {
    let newState = this.state

    for (let i = 0; i < newState.contacts.length; i++) {
      if (newState.contacts[i]._id === contact.id) {
        if (newState.contacts[i].contactCrud !== "D") {
          newState.contacts[i].contactCrud = "D"
        } else {
          newState.contacts[i].neededAction = "D"
          newState.contacts[i].contactCrud = ""
        }
        break
      }
    }
    this.setState(newState)
  }

  cancelContactDelete(e, contact) {
    let newState = this.state

    for (let i = 0; i < newState.contacts.length; i++) {
      if (newState.contacts[i]._id === contact.id) {
        newState.contacts[i].contactCrud = ""
        break
      }
    }
    this.setState(newState)
  }

  updateContact(e, contact) {
    let newState = this.state

    for (let i = 0; i < newState.contacts.length; i++) {
      if (newState.contacts[i]._id === contact.id) {
        if (newState.contacts[i].contactCrud !== "U") {
          newState.contacts[i].contactCrud = "U"
          newState.updatingContact = true
          newState.newContactName = contact.name
          newState.newContactPhone = contact.phone
          newState.newContactEmail = contact.email
          newState.newContactDepartment = contact.department
          newState.newContactNotes = contact.notes
          newState.newContactBilling = contact.billing
          newState.newContactNfse = contact.nfse
        } else {
          if (newState.contacts[i].neededAction !== "C") {
            newState.contacts[i].neededAction = "U"
          }
          newState.contacts[i].contactCrud = ""
          newState.updatingContact = false
          newState.contacts[i].name = newState.newContactName
          newState.contacts[i].phone = newState.newContactPhone
          newState.contacts[i].email = newState.newContactEmail
          newState.contacts[i].department = newState.newContactDepartment
          newState.contacts[i].notes = newState.newContactNotes
          newState.contacts[i].billing = newState.newContactBilling
          newState.contacts[i].nfse = newState.newContactNfse

          newState.newContactName = ""
          newState.newContactPhone = ""
          newState.newContactEmail = ""
          newState.newContactDepartment = ""
          newState.newContactNotes = ""
          newState.newContactBilling = false
          newState.newContactNfse = false
        }
        break
      }
    }
    this.setState(newState)
  }

  cancelContactUpdate(e, contact) {
    let newState = this.state

    for (let i = 0; i < newState.contacts.length; i++) {
      if (newState.contacts[i]._id === contact.id) {
        newState.updatingContact = false
        newState.contacts[i].contactCrud = ""
        break
      }
    }

    newState.newContactName = ""
    newState.newContactPhone = ""
    newState.newContactEmail = ""
    newState.newContactDepartment = ""
    newState.newContactNotes = ""
    newState.newContactBilling = false
    newState.newContactNfse = false

    this.setState(newState)
  }

  createNewAddress(e) {
    let newState = this.state
    let currentAddresses = newState.addresses
    let newAddresses = [
      {
        addressCrud: "C",
        description: "",
        address: "",
        region: "",
        city: "",
        additionalInfo: "",
        state: "",
        postalCode: "",
        transfer: "",
        defaultAddress: false,
      },
    ]

    for (let i = 0; i < currentAddresses.length; i++) {
      newAddresses.push(currentAddresses[i])
    }

    newState.addresses = newAddresses
    newState.creatingAddress = true

    this.setState(newState)
  }

  cancelNewAddress(e) {
    let newState = this.state
    let newAddresses = newState.addresses

    newAddresses.shift()

    this.setState({
      addresses: newAddresses,
      creatingAddress: false,
      newAddressDescription: "",
      newAddressAddress: "",
      newAddressRegion: "",
      newAddressCity: "",
      newAddressAdditionalInfo: "",
      newAddressState: "",
      newAddressPostalCode: "",
      newAddressTransfer: "",
      newAddressDefaultAddress: false,

      customStyles: {
        newAddressDescriptionState: null,
        newAddressAddressState: null,
        newAddressRegionState: null,
        newAddressCityState: null,
        newAddressAdditionalInfoState: null,
        newAddressStateState: null,
      },
    })
  }

  saveNewAddress(e, address) {
    if (address.addressCrud === "C" || address.addressCrud === "U") {
      let newState = this.state
      let letSave = true

      if (newState.newAddressDescription === "") {
        newState.customStyles.newAddressDescriptionState = "invalid"
        letSave = false
      } else {
        newState.customStyles.newAddressDescriptionState = "valid"
      }

      if (newState.newAddressAddress === "") {
        newState.customStyles.newAddressAddressState = "invalid"
        letSave = false
      } else {
        newState.customStyles.newAddressAddressState = "valid"
      }

      if (newState.newAddressRegion === "") {
        newState.customStyles.newAddressRegionState = "invalid"
        letSave = false
      } else {
        newState.customStyles.newAddressRegionState = "valid"
      }

      if (newState.newAddressCity === "") {
        newState.customStyles.newAddressCityState = "invalid"
        letSave = false
      } else {
        newState.customStyles.newAddressCityState = "valid"
      }

      if (newState.newAddressState === "") {
        newState.customStyles.newAddressStateState = "invalid"
        letSave = false
      } else {
        newState.customStyles.newAddressStateState = "valid"
      }

      this.setState(newState)

      if (!letSave) {
        return
      }

      let d = new Date()

      newState.addresses[0].addressCrud = ""
      newState.addresses[0].neededAction = "C"
      newState.addresses[0]._id =
        "NEW_ADDRESS_" + d.getHours() + d.getMinutes() + d.getSeconds()
      newState.addresses[0].description = newState.newAddressDescription
      newState.addresses[0].address = newState.newAddressAddress
      newState.addresses[0].region = newState.newAddressRegion
      newState.addresses[0].city = newState.newAddressCity
      newState.addresses[0].additionalInfo = newState.newAddressAdditionalInfo
      newState.addresses[0].state = newState.newAddressState
      newState.addresses[0].postalCode = newState.newAddressPostalCode
      newState.addresses[0].transfer = newState.newAddressTransfer
      newState.addresses[0].defaultAddress = newState.newAddressDefaultAddress

      if (newState.newAddressDefaultAddress) {
        for (let i = 1; i < newState.addresses.length; i++) {
          newState.addresses[i].defaultAddress = false
        }
      }

      newState.creatingAddress = false
      newState.newAddressDescription = ""
      newState.newAddressAddress = ""
      newState.newAddressRegion = ""
      newState.newAddressCity = ""
      newState.newAddressAdditionalInfo = ""
      newState.newAddressState = ""
      newState.newAddressPostalCode = ""
      newState.newAddressTransfer = ""
      newState.newAddressDefaultAddress = false

      this.setState(newState)
    }
  }

  deleteAddress(e, address) {
    let newState = this.state

    for (let i = 0; i < newState.addresses.length; i++) {
      if (newState.addresses[i]._id === address.id) {
        if (newState.addresses[i].addressCrud !== "D") {
          newState.addresses[i].addressCrud = "D"
        } else {
          newState.addresses[i].neededAction = "D"
          newState.addresses[i].addressCrud = ""
        }
        break
      }
    }

    this.setState(newState)
  }

  cancelAddressDelete(e, address) {
    let newState = this.state

    for (let i = 0; i < newState.addresses.length; i++) {
      if (newState.addresses[i]._id === address.id) {
        newState.addresses[i].addressCrud = ""
        break
      }
    }
    this.setState(newState)
  }

  updateAddress(e, address) {
    let newState = this.state

    if (newState.updatingAddress) {
      if (newState.newAddressDefaultAddress) {
        for (let i = 0; i < newState.addresses.length; i++) {
          newState.addresses[i].defaultAddress = false
        }
      }
    }

    for (let i = 0; i < newState.addresses.length; i++) {
      if (newState.addresses[i]._id === address.id) {
        if (newState.addresses[i].addressCrud !== "U") {
          newState.addresses[i].addressCrud = "U"
          newState.updatingAddress = true
          newState.newAddressDescription = address.description
          newState.newAddressAddress = address.address
          newState.newAddressRegion = address.region
          newState.newAddressCity = address.city
          newState.newAddressAdditionalInfo = address.additionalInfo
          newState.newAddressState = address.state
          newState.newAddressPostalCode = address.postalCode
          newState.newAddressTransfer = address.transfer
          newState.newAddressDefaultAddress = address.defaultAddress
        } else {
          if (newState.addresses[i].neededAction !== "C") {
            newState.addresses[i].neededAction = "U"
          }
          newState.addresses[i].addressCrud = ""
          newState.updatingAddress = false
          newState.addresses[i].description = newState.newAddressDescription
          newState.addresses[i].address = newState.newAddressAddress
          newState.addresses[i].region = newState.newAddressRegion
          newState.addresses[i].city = newState.newAddressCity
          newState.addresses[i].additionalInfo =
            newState.newAddressAdditionalInfo
          newState.addresses[i].state = newState.newAddressState
          newState.addresses[i].postalCode = newState.newAddressPostalCode
          newState.addresses[i].transfer = newState.newAddressTransfer
          newState.addresses[i].defaultAddress =
            newState.newAddressDefaultAddress

          newState.newAddressDescription = ""
          newState.newAddressAddress = ""
          newState.newAddressRegion = ""
          newState.newAddressCity = ""
          newState.newAddressAdditionalInfo = ""
          newState.newAddressState = ""
          newState.newAddressPostalCode = ""
          newState.newAddressTransfer = ""
          newState.newAddressDefaultAddress = false
        }
        break
      }
    }
    this.setState(newState)
  }

  cancelAddressUpdate(e, address) {
    let newState = this.state

    for (let i = 0; i < newState.addresses.length; i++) {
      if (newState.addresses[i]._id === address.id) {
        newState.updatingAddress = false
        newState.addresses[i].addressCrud = ""
        break
      }
    }

    newState.newAddressDescription = ""
    newState.newAddressAddress = ""
    newState.newAddressRegion = ""
    newState.newAddressCity = ""
    newState.newAddressAdditionalInfo = ""
    newState.newAddressState = ""
    newState.newAddressPostalCode = ""
    newState.newAddressTransfer = ""
    newState.newAddressDefaultAddress = false

    this.setState(newState)
  }

  render() {
    return (
      <>
        <LightHeader
          title="Cadastro de Clientes"
          parent="Cadastros"
          menuTitle="Clientes"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col>
              <Card hidden={this.state.crud !== ""}>
                <CardHeader>
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Lista de Clientes</h3>
                    </Col>
                    <Col className="text-right" xs="6">
                      <Button
                        className="btn-round btn-icon"
                        color="primary"
                        onClick={(e) => this.openDataPanel(e, null, "C")}
                        size="sm"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-plus" />
                        </span>
                        <span className="btn-inner--text">Novo Cliente</span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <ToolkitProvider
                  data={this.state.data}
                  keyField="_id"
                  columns={[
                    {
                      dataField: "actions",
                      editable: false,
                      text: "Ações",
                      isDummyField: true,
                      formatter: (cell, row, rowIndex) => {
                        return (
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              color=""
                              role="button"
                              size="sm"
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                onClick={(e) => this.openDataPanel(e, row, "U")}
                              >
                                <i className="fas fa-edit" />
                                <span>Alterar</span>
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) => this.openDataPanel(e, row, "D")}
                              >
                                <i className="fas fa-trash" />
                                <span>Excluir</span>
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) => this.openDataPanel(e, row, "R")}
                              >
                                <i className="fas fa-glasses" />
                                <span>Visualizar</span>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )
                      },
                    },
                    {
                      dataField: "_id",
                      text: "Id",
                      hidden: true,
                    },
                    {
                      dataField: "fullName",
                      text: "Razão Social",
                      sort: true,
                    },
                    {
                      dataField: "shortName",
                      text: "Nome Fantasia",
                      sort: true,
                    },
                    {
                      dataField: "cnpj",
                      text: "CNPJ",
                      sort: true,
                    },
                    {
                      dataField: "ie",
                      text: "Inscrição Estadual",
                      sort: true,
                    },
                    {
                      dataField: "active",
                      text: "Ativo",
                      sort: true,
                      formatter: (cell, row) => {
                        return (
                          <label className="custom-toggle custom-toggle-default">
                            <input
                              defaultChecked={row.active}
                              type="checkbox"
                              disabled
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="Não"
                              data-label-on="Sim"
                            />
                          </label>
                        )
                      },
                    },
                  ]}
                  search
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <Container fluid>
                        <Row>
                          <Col xs={12}>
                            <div
                              id="datatable-basic_filter"
                              className="dataTables_filter px-4 pb-1 float-left"
                            >
                              <label>
                                Pesquisar:
                                <SearchBar
                                  className="form-control-sm"
                                  placeholder="Pesquisar por..."
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                      <BootstrapTable
                        ref={(el) => (this.componentRef = el)}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        hover
                        id="react-bs-table"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Card>
              <Card hidden={this.state.crud === ""}>
                <CardHeader>
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">{this.state.dataPanelTitle}</h3>
                    </Col>
                    <Col className="text-right" xs="6">
                      <Button
                        className="btn-round btn-icon"
                        color="danger"
                        onClick={(e) => this.closeDataPanel(e)}
                        disabled={this.state.saving}
                        size="sm"
                      >
                        {this.state.crud === "R" ? (
                          <span className="btn-inner--icon mr-1">
                            <i className="ni ni-bold-left" />
                          </span>
                        ) : null}
                        <span className="btn-inner--text">
                          {this.state.crud === "R"
                            ? "Voltar para o Browser"
                            : "Cancelar"}
                        </span>
                      </Button>
                      <Button
                        className="btn-round btn-icon"
                        color="success"
                        onClick={(e) => this.saveClientData(e)}
                        size="sm"
                        hidden={this.state.crud === "R"}
                        disabled={this.state.saving}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i
                            className={
                              this.state.crud === "D"
                                ? "fas fa-trash"
                                : "ni ni-check-bold"
                            }
                          />
                        </span>
                        <span className="btn-inner--text">
                          {this.state.crud === "D" ? "Excluir" : "Salvar"}
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    noValidate
                    autoComplete="off"
                  >
                    <div className="form-row">
                      <Col className="mb-3" md="6">
                        <label
                          className="form-control-label"
                          htmlFor="fullName"
                        >
                          Razão Social
                        </label>
                        <Input
                          autoFocus
                          id="fullName"
                          placeholder="Razão Social do Cliente"
                          type="text"
                          value={this.state.fullName}
                          readOnly={this.state.readOnly}
                          disabled={this.state.saving}
                          valid={
                            this.state.customStyles.fullNameState === "valid"
                          }
                          invalid={
                            this.state.customStyles.fullNameState === "invalid"
                          }
                          onChange={(e) => this.stylesForm(e, "fullName")}
                        />
                        <div className="invalid-feedback">
                          Digite a razão social do cliente.
                        </div>
                      </Col>
                      <Col className="mb-3" md="6">
                        <label
                          className="form-control-label"
                          htmlFor="shortName"
                        >
                          Nome Fantasia
                        </label>
                        <Input
                          autoFocus
                          id="shortName"
                          placeholder="Nome Fantasia do Cliente"
                          type="text"
                          value={this.state.shortName}
                          readOnly={this.state.readOnly}
                          disabled={this.state.saving}
                          valid={
                            this.state.customStyles.shortNameState === "valid"
                          }
                          invalid={
                            this.state.customStyles.shortNameState === "invalid"
                          }
                          onChange={(e) => this.stylesForm(e, "shortName")}
                        />
                        <div className="invalid-feedback">
                          Digite o nome fantasia do cliente.
                        </div>
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col className="mb-3" md="6">
                        <label className="form-control-label" htmlFor="cnpj">
                          CNPJ
                        </label>
                        <Input
                          autoFocus
                          id="cnpj"
                          placeholder="CNPJ"
                          type="tel"
                          mask="99.999.999/9999-99"
                          tag={InputMask}
                          value={this.state.cnpj}
                          readOnly={this.state.readOnly}
                          disabled={this.state.saving}
                          valid={this.state.customStyles.cnpjState === "valid"}
                          invalid={
                            this.state.customStyles.cnpjState === "invalid"
                          }
                          onChange={(e) => this.stylesForm(e, "cnpj")}
                        />
                        <div className="invalid-feedback">
                          Digite o CNPJ do cliente.
                        </div>
                      </Col>
                      <Col className="mb-3" md="6">
                        <label className="form-control-label" htmlFor="ie">
                          Inscrição Estadual
                        </label>
                        <Input
                          autoFocus
                          id="ie"
                          placeholder=""
                          type="text"
                          value={this.state.ie}
                          readOnly={this.state.readOnly}
                          disabled={this.state.saving}
                          valid={this.state.customStyles.ieState === "valid"}
                          invalid={
                            this.state.customStyles.ieState === "invalid"
                          }
                          onChange={(e) => this.stylesForm(e, "ie")}
                        />
                        <div className="invalid-feedback">
                          Digite a Inscrição Estadual do cliente.
                        </div>
                      </Col>
                    </div>
                    <div className="form-row">
                      <Col className="mb-3" md="2">
                        <label className="form-control-label" htmlFor="active">
                          Ativo
                        </label>
                        <div>
                          <label className="custom-toggle custom-toggle-default mr-1">
                            <input
                              defaultChecked={this.state.active}
                              type="checkbox"
                              checked={this.state.active}
                              disabled={
                                this.state.readOnly || this.state.saving
                              }
                              onChange={(e) =>
                                this.stylesForm(e, "active", true)
                              }
                            />
                            <span
                              className="custom-toggle-slider rounded-circle"
                              data-label-off="Não"
                              data-label-on="Sim"
                            />
                          </label>
                        </div>
                      </Col>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row hidden={this.state.crud === ""}>
            <Col xs="6">
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Contatos</h3>
                    </Col>
                    <Col
                      className="text-right"
                      xs="6"
                      hidden={
                        this.state.creatingContact || this.state.updatingContact
                      }
                    >
                      <Button
                        className="btn-round btn-icon"
                        color="primary"
                        onClick={(e) => this.createNewContact(e)}
                        size="sm"
                        disabled={this.state.saving}
                        hidden={
                          this.state.crud !== "C" && this.state.crud !== "U"
                        }
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-plus" />
                        </span>
                        <span className="btn-inner--text">Novo Contato</span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <ListGroup flush>
                    {this.getContacts(this.state.contacts)}
                  </ListGroup>
                </CardBody>
              </Card>
            </Col>
            <Col xs="6">
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Endereços</h3>
                    </Col>
                    <Col
                      className="text-right"
                      xs="6"
                      hidden={
                        this.state.creatingAddress || this.state.updatingAddress
                      }
                    >
                      <Button
                        className="btn-round btn-icon"
                        color="primary"
                        onClick={(e) => this.createNewAddress(e)}
                        size="sm"
                        disabled={this.state.saving}
                        hidden={
                          this.state.crud !== "C" && this.state.crud !== "U"
                        }
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-plus" />
                        </span>
                        <span className="btn-inner--text">Novo Endereço</span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <ListGroup flush>
                    {this.getAddresses(this.state.addresses)}
                  </ListGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Loading modalOpened={this.state.isLoading} />
          {this.state.alert}
        </Container>
      </>
    )
  }
}

export default Clients
