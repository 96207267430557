import React from "react"
import { Link } from "react-router-dom"
import { Button, Modal, Table } from "reactstrap"
import { transformNumberToCurency } from "utils/currencyHandling"
import SapLoginModal from "components/Modals/admin/SapLoginModal"
import { useCallback, useEffect, useState } from "react"
import apiSap from "services/apiSap"
import api from "services/api"
import Loading from "components/Modals/Loading"
import ConfirmationModal from "components/Modals/ConfirmationModal"
import ReactBSAlert from "react-bootstrap-sweetalert"
import "react-loading-skeleton/dist/skeleton.css"
import { convertMongoDate } from "utils/dateHandling"
import { result, toInteger } from "lodash"
import Billings from "views/pages/browsers/admin/Billings"
import RDCTextModal from "./RDCTextModal"

function BillingDetailTable(props) {
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(null)
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [confirmationModalAction, setConfirmationModalAction] = useState({})
  const [sapLoginModalOpen, setsapLoginModalOpen] = useState(false)
  const [operation, setOPeration] = useState("")
  const [invoice, setInvoice] = useState(null)
  const [invoiceIndex, setInvoiceIndex] = useState(-1)

  /**botão */
  const [isRDCTextModalOpen, setIsRDCTextModalOpen] = useState(false);
  const [defaultText, setDefaultText] = useState("");
  const [docEntry, setDocEntry] = useState("");


  const openRDCTextModal = (text) => {
    setDefaultText(text);
    setDocEntry(docEntry);
    setIsRDCTextModalOpen(true);
  };
  

  const closeRDCTextModal = () => {
    setIsRDCTextModalOpen(false);
  };

  /**botão */

  /**
   *
   * @param {Object} invoice
   * @returns
   */

  const warningAlert = (props) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block" }}
        title="Atenção!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle={props.color}
        confirmBtnText="Ok"
        btnSize=""
      >
        {props.message}
      </ReactBSAlert>
    )
  }
  const hideAlert = () => {
    setAlert(null)
  }

  function getInvoiceTotal(invoice) {
    const total =
      parseFloat(invoice.schedulesTotal) +
      parseFloat(invoice.installmentsTotal) +
      Number.EPSILON
    return total
  }
  //SALVA DESCRIÇÃO ALTERNATIVA RDC
  async function GetIvoiceNotice(contrato) {
    try {

      return await api.get(
        "contract/searchWithInstallments/" +
          contrato);
      
    }
    catch (error) {
      warningAlert({ color: "success", message: "Ocorreu um erro\n " + error })
      console.log("Ocorreu um erro\n " + error)
    }
  }
  //SALVA DESCRIÇÃO ALTERNATIVA RDC
  async function SetDscricaoRDCalternativa(text) {
    try {

      invoice.rdcAlternativo = text;
      await api.put("billing/update/" + props.billing._id, props.billing);
      
    }
    catch (error) {
      warningAlert({ color: "success", message: "Ocorreu um erro\n " + error })
      console.log("Ocorreu um erro\n " + error)
    }
  }

  // INICIANDO CANCELAMENTO
  async function cancelInvoiceSAP() {
    
    console.log(invoice)
    try {
      setLoading(true)

      if (invoice.sapDocEntry) {

        let statusNF = await apiSap.get(`/Invoices( ${invoice.sapDocEntry})`)

        if (statusNF.data.DocumentStatus === "bost_Close" && statusNF.data.Cancelled === "tNO") {
          warningAlert({ color: "success", message: "Nota Fiscal já emitida! Cancele a Nota fiscal e tente novamente." })
        }
        else {
          if (statusNF.data.Cancelled === "tNO") {
            let updatedData = {
              U_TX_Just: "Cancelamento via SGL"
            };          
          
            
            let sapOrderUpdate = await apiSap.patch(`/Invoices(${invoice.sapDocEntry})`, updatedData);
            //await apiSap.post(`/Invoices(${invoice.sapDocEntry})/Cancel`)
          }

          let newBilling = { ...props.billing }
          const deleteInvoice = newBilling.invoices[invoiceIndex]
          newBilling.invoices.splice(invoiceIndex, 1)
          console.log(newBilling)
          await api.put("billing/update/" + props.billing._id, newBilling)

          await Promise.all(
            deleteInvoice.serviceOrders.map(async (so) => {
              return await api.patch("serviceOrder/patch/" + so, {
                billed: false,
                billing: null,
              })
            })
          )
          await Promise.all(
            deleteInvoice.contractInstallments.map(async (ca) => {
              return await api.patch("contractInstallment/patch/" + ca, {
                status: "P",
                billing: null,
                billingDate: null,
              })
            })
          )
          warningAlert({ color: "success", message: "A transmissão do faturamento foi cancelada" })
          console.log("A transmissão do faturamento foi cancelada")
        }
      }
      else {
        warningAlert({ color: "success", message: "O faturamento não integrado!" })
        console.log("O faturamento não integrado!")
      }

    } catch (error) {
      warningAlert({ color: "success", message: "Ocorreu um erro\n " + error })
      console.log("Ocorreu um erro\n " + error)
    }
    finally {
      setLoading(false)
      //props.UpdateInvoice()
      //props.closeModal()
    }
  }

  return (
    <>
      {props.invoices?.length > 0 && (
        <Table>
          <thead>
            <tr>
              <th>Imprimir</th>
              <th>Cliente</th>
              <th>Nome Fantasia</th>
              <th>Serviço</th>
              <th>
                Total <br></br> Horas
              </th>
              <th>
                Total <br></br> Agendas
              </th>
              <th>
                Total <br></br> Parcelas
              </th>
              <th>
                Total <br></br> Faturamento
              </th>

              <th>Descrição personalizada</th>
              <th>Cancelar</th>
            </tr>
          </thead>
          <tbody>


            {props.invoices.map((invoice, index) => {

              return (

                <tr
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    props.handleBillingClick(invoice)
                  }}
                  hidden={!invoice.service}
                >
                  <td>
                    <Button size="sm" hidden={!invoice.service}>
                      <Link
                        to={{
                          pathname: `/invoiceDocument/${props.billing._id}`,
                          search: `type=rdc&invoiceIndex=${index}`,
                        }}
                        target={"_blank"}
                      >
                        <span style={{ color: "black" }}>RDC</span>
                      </Link>
                    </Button>
                    <Button
                      size="sm"
                      hidden={
                        invoice.serviceOrders.length < 1 || !invoice.service
                      }
                    >
                      <Link
                        to={{
                          pathname: `/invoiceDocument/${props.billing._id}`,
                          search: `type=os&invoiceIndex=${index}`,
                        }}
                        target={"_blank"}
                      >
                        <span style={{ color: "black" }}>OS</span>
                      </Link>
                    </Button>
                  </td>
                  <td>{invoice.customer.fullName}</td>
                  <td>{invoice.customer.shortName}</td>
                  <td>{invoice.service?.name || "NA"}</td>
                  <td>{invoice.hoursTotal}</td>
                  <td>{transformNumberToCurency(invoice.schedulesTotal)}</td>
                  <td>{transformNumberToCurency(invoice.installmentsTotal)}</td>
                  <td>{transformNumberToCurency(getInvoiceTotal(invoice))}</td>
                  <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                    <Button id="story" name="story"
                      color= {invoice.rdcAlternativo ? "success" : "primary"}
                      size="sm"
                      
                      onClick={async () => {
                        setInvoice(invoice);
                        setInvoiceIndex(index);
                        debugger
                        let teste = props.billing;
                        let contractResponse = await GetIvoiceNotice(invoice.contract)
                            debugger
                        openRDCTextModal(invoice.rdcAlternativo || (invoice.rdcText.substring(invoice.rdcText.indexOf('\n') + 1) + '\n' + contractResponse.data[0].invoiceNotice), invoice.sapDocEntry);

                      }}
                    >
                      Editar descrição
                    </Button>
                    <RDCTextModal
                      isOpen={isRDCTextModalOpen}
                      onClose={closeRDCTextModal}
                      title="Alterar descrição da RDC"
                      defaultText={defaultText}
                      docEntry={invoice.sapDocEntry} // Passa o texto 
                      saveText={SetDscricaoRDCalternativa}
                    />
                  </td>



                  <td><Button size="sm"
                    color="danger"
                    type="button"
                    onClick={() => {
                      setInvoice(invoice)
                      setInvoiceIndex(index)
                      setOPeration("cancel")
                      setsapLoginModalOpen(true)
                    }}
                  > Cancelar
                  </Button></td>
                </tr>
              )

            })}
          </tbody>
          {alert}
        </Table>

      )}
      <Loading modalOpened={loading}></Loading>
      <SapLoginModal isOpen={sapLoginModalOpen} title="Logar SAP" closeModal={() => { setsapLoginModalOpen(false) }} openConfirmationModal={() => {
        if (operation === 'cancel')
          setConfirmationModalAction({ action: "cancel-invoice" })
        setConfirmationModalOpen(true)
      }}></SapLoginModal>
      <ConfirmationModal
        isOpen={confirmationModalOpen}
        action={confirmationModalAction}
        text={confirmationModalAction.action === "cancel-invoice" ? "Deseja cancelar integração com o SAP?" : ""}
        confirm={async () => {
          if (confirmationModalAction.action === "cancel-invoice") {
            ///AQUI VAI A CHAMADA DA FUNÇÃO
            cancelInvoiceSAP()

          }
        }}
        closeModal={() => {
          setConfirmationModalOpen(false)
        }}
        deny={() => {
          setConfirmationModalOpen(false)
        }}

      />
      {alert}
    </>

  )


}
// INICIANDO CANCELAMENT

export default BillingDetailTable
