import React from "react";

const style = {
  borderStyle: "solid",
  borderColor: "var(--primary)",
  borderRadius: "12px",
  backgroundColor: "var(--primary)",
  width: "12vh",
  innerHeight: "1px",
  textAlign: "center",
  margin: "0.2vh 0.05vh 0.2vh 0.05vh",
  padding: "0vh 0.4vh 0vh 0.4vh",
  cursor: "pointer",
  overflow: "hidden",
  color: "white",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
};
export default function Tag(props) {
  return (
    <div style={{...style, backgroundColor: props.backgroundColor? props.backgroundColor :"var(--primary)", borderColor: props.backgroundColor? props.backgroundColor :"var(--primary)" }}>
      <span
        style={{ fontSize: "11px", fontFamily: "Open Sans", width: "100%" }}
      >
        {props.children}
      </span>
    </div>
  );
}
