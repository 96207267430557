import "moment/locale/pt-br"
import React, { useEffect, useState } from "react"
import "react-big-calendar/lib/css/react-big-calendar.css"
import { Button, Card, CardBody, Col, Input, Modal, Row } from "reactstrap"
import api from "services/api"
import PropTypes from "prop-types"

import ConfirmationModal from "../ConfirmationModal"

EmailRoutineModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default function EmailRoutineModal(props) {
  const [confirmationModalAction, setConformationModalAction] = useState({
    text: "",
    blockConfirm: false,
  })
  const [confirmationModalText, setConformationModalText] = useState("")
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  const [emailRoutine, setEmailRoutine] = useState({})

  /**
   * updates email routine
   */
  const updateRoutine = async () => {
    try {
      await api.put("emailRoutine/update/" + emailRoutine._id, emailRoutine)
      alert("Horário da rotina de envio de e-mail alterada")
      props.closeModal()
    } catch (error) {
      console.error(error)
      alert("Ocorreu um erro")
    }
  }

  /**
   *
   */
  const closeConfirmationModal = () => {
    setIsConfirmationModalOpen(false)
    setConformationModalAction({ text: "", blockConfirm: false })
    setConformationModalText(``)
  }
  /**
   *
   */
  const openConfirmationModal = () => {
    setConformationModalAction({ text: "Envio de email", blockConfirm: false })
    setConformationModalText(`Enviar email para todos os consultores?`)
    setIsConfirmationModalOpen(true)
  }

  useEffect(() => {
    async function fetchEmailRoutine() {
      const emailRoutineResponse = await api.post("emailRoutine/search", {})
      setEmailRoutine(emailRoutineResponse.data[0])
    }
    fetchEmailRoutine()
    return () => {
      setEmailRoutine({})
    }
  }, [])

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={props.isOpen}
      autoFocus={false}
    >
      <div className="modal-header">
        <h5 className="modal-title">Atualização de rotina</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={(e) => props.closeModal()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <Card>
        <CardBody>
          <Row>
            <Col xs={2}>
              <label className="form-control-label" htmlFor="send_time">
                Horário de envio
              </label>
              <Input
                id="send_time"
                type="time"
                value={emailRoutine.send_time}
                onChange={(e) =>
                  setEmailRoutine((prev) => ({
                    ...prev,
                    send_time: e.target.value,
                  }))
                }
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <label className="form-control-label" htmlFor="recipients">
                Remetentes (separados por vírgula)
              </label>
              <Input
                id="recipients"
                type="text"
                value={emailRoutine.recipients}
                onChange={(e) =>
                  setEmailRoutine((prev) => ({
                    ...prev,
                    recipients: e.target.value,
                  }))
                }
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <div className="modal-footer">
        <Button
          color="success"
          type="button"
          onClick={(e) => openConfirmationModal()}
        >
          Atualizar
        </Button>
        <ConfirmationModal
          isOpen={isConfirmationModalOpen}
          action={confirmationModalAction}
          text={
            "Você tem certeza que quer alterar o horário de envio do email?"
          }
          confirm={() => updateRoutine()}
          closeModal={() => closeConfirmationModal()}
          deny={() => closeConfirmationModal()}
        />
      </div>
    </Modal>
  )
}
