import React from "react";
import Select2 from "react-select2-wrapper";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Row,
  Col,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Badge,
} from "reactstrap";
import ActivitiesPanel from "./ActivitiesPanel";
import { transformNumberToCurency } from "utils/currencyHandling";
import ReactInputMask from "react-input-mask";
debugger
class ProjectPanel extends React.Component {
  render() {
    return (
      <>
        <Card hidden={this.props.crudProject === ""}>
          <CardHeader>
            <Row>
              <Col xs="6">
                <h3 className="mb-0">{this.props.dataPanelTitle}</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button
                  className="btn-round btn-icon"
                  color="danger"
                  onClick={(e) => this.props.closeDataPanel(e)}
                  disabled={this.props.saving}
                  size="sm"
                >
                  {this.props.crudProject === "R" ||
                  this.props.crudProject === "A" ? (
                    <span className="btn-inner--icon mr-1">
                      <i className="ni ni-bold-left" />
                    </span>
                  ) : null}
                  <span className="btn-inner--text">
                    {this.props.crudProject === "R" ||
                    this.props.crudProject === "A"
                      ? "Voltar para o Browser"
                      : "Cancelar"}
                  </span>
                </Button>
                {this.props.crudProject !== "A" ? (
                  <Button
                    className="btn-round btn-icon"
                    color="success"
                    onClick={(e) => this.props.saveProjectData(e)}
                    size="sm"
                    hidden={this.props.crudProject === "R"}
                    disabled={this.props.saving}
                  >
                    <span className="btn-inner--icon mr-1">
                      <i
                        className={
                          this.props.crudProject === "D"
                            ? "fas fa-trash"
                            : "ni ni-check-bold"
                        }
                      />
                    </span>
                    <span className="btn-inner--text">
                      {this.props.crudProject === "D" ? "Excluir" : "Salvar"}
                    </span>
                  </Button>
                ) : null}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Form className="needs-validation" noValidate autoComplete="off">
              <div className="form-row">
                <Col className="mb-3" md="2">
                  <label className="form-control-label" absolute htmlFor="code">
                    Código
                  </label>
                  <Input
                    autoFocus
                    size={this.props.activitiesMaintenence ? "sm" : ""}
                    id="code"
                    type="text"
                    placeholder="Código do projeto"
                    value={this.props.code}
                    readOnly={this.props.projectReadOnly}
                    disabled={this.props.saving}
                    valid={this.props.customStyles.codeState === "valid"}
                    invalid={this.props.customStyles.codeState === "invalid"}
                    onChange={(e) => this.props.stylesForm(e, "code")}
                  />
                  <div className="invalid-feedback">
                    Digite o código do projeto.
                  </div>
                </Col>
                <Col className="mb-3" md="4">
                  <label className="form-control-label" absolute htmlFor="name">
                    Nome do Projeto
                  </label>
                  <Input
                    size={this.props.activitiesMaintenence ? "sm" : ""}
                    id="name"
                    placeholder="Nome do projeto"
                    type="text"
                    value={this.props.name}
                    readOnly={this.props.projectReadOnly}
                    disabled={this.props.saving}
                    valid={this.props.customStyles.nameState === "valid"}
                    invalid={this.props.customStyles.nameState === "invalid"}
                    onChange={(e) => this.props.stylesForm(e, "name")}
                  />
                  <div className="invalid-feedback">
                    Digite o nome do projeto.
                  </div>
                </Col>
                <Col className="mb-3" md="4">
                  <label
                    className="form-control-label"
                    absolute
                    htmlFor="projectType"
                  >
                    Tipo
                  </label>
                  <Input
                    size={this.props.activitiesMaintenence ? "sm" : ""}
                    id="projectType"
                    type="select"
                    value={this.props.projectType}
                    disabled={this.props.projectReadOnly || this.props.saving}
                    valid={this.props.customStyles.projectTypeState === "valid"}
                    invalid={
                      this.props.customStyles.projectTypeState === "invalid"
                    }
                    onChange={(e) => this.props.stylesForm(e, "projectType")}
                  >
                    <option value={null}></option>
                    <option value="B">Body Shop</option>
                    <option value="D">Desenvolvimento/Customização</option>
                    <option value="I">Implantação/Consultoria</option>
                    <option value="S">Suporte Pontual</option>
                    <option value="U">Upgrade/Migração</option>
                    <option value="C">
                      SLA - Contrato Serviços de Valor Agregado
                    </option>
                  </Input>
                  <div className="invalid-feedback">Escolha um tipo.</div>
                </Col>
                <Col className="mb-3" md="2">
                  <label
                    className="form-control-label"
                    absolute
                    htmlFor="status"
                  >
                    Status
                  </label>
                  <InputGroup>
                    <InputGroupAddon addonType="append">
                      <Badge
                        className="badge-lg"
                        color={
                          !this.props.beginDate ||
                          this.props.beginDate === null ||
                          this.props.beginDate === ""
                            ? "danger"
                            : !this.props.endDate ||
                              this.props.endDate === null ||
                              this.props.endDate === ""
                            ? "warning"
                            : "success"
                        }
                        pill
                      >
                        {!this.props.beginDate ||
                        this.props.beginDate === null ||
                        this.props.beginDate === ""
                          ? "Não Iniciado"
                          : !this.props.endDate ||
                            this.props.endDate === null ||
                            this.props.endDate === ""
                          ? "Em Andamento"
                          : "Concluído"}
                      </Badge>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
              </div>
              <div className="form-row">
                <Col className="mb-3" md="6">
                  <label
                    className="form-control-label"
                    absolute
                    htmlFor="client"
                  >
                    Cliente
                  </label>
                  {!this.props.activitiesMaintenence ? (
                    <>
                      <Select2
                        className="form-control"
                        type="text"
                        value={this.props.customer}
                        disabled={
                          this.props.saving ||
                          this.props.projectReadOnly ||
                          (this.props.endDate !== null &&
                            this.props.endDate !== "")
                        }
                        onSelect={(e) => this.props.stylesForm(e, "customer")}
                        options={{
                          hidden: true,
                          size: "small",
                          placeholder: "Selecione um cliente",
                          language: {
                            noResults: function () {
                              return "Nenhum cliente encontrado.";
                            },
                          },
                        }}
                        data={this.props.customersList}
                      />
                      <Input
                        hidden
                        valid={
                          this.props.customStyles.customerState === "valid"
                        }
                        invalid={
                          this.props.customStyles.customerState === "invalid"
                        }
                      />
                      <div className="invalid-feedback">
                        Cliente inválido ou não encontrado.
                      </div>
                    </>
                  ) : (
                    <Input
                      value={this.props.customerShortName}
                      readOnly
                      size="sm"
                    />
                  )}
                </Col>
                <Col className="mb-3" md="6">
                  <label
                    className="form-control-label"
                    absolute
                    htmlFor="coordinator"
                  >
                    Coordenador
                  </label>
                  {!this.props.activitiesMaintenence ? (
                    <>
                      <Select2
                        className="form-control"
                        type="text"
                        value={this.props.coordinator}
                        disabled={
                          this.props.saving ||
                          this.props.projectReadOnly ||
                          (this.props.endDate !== null &&
                            this.props.endDate !== "")
                        }
                        onSelect={(e) =>
                          this.props.stylesForm(e, "coordinator")
                        }
                        options={{
                          placeholder: "Selecione um coordenador",
                          language: {
                            noResults: function () {
                              return "Nenhum coordenador encontrado.";
                            },
                          },
                        }}
                        data={this.props.coordinatorsList}
                      />
                      <Input
                        hidden
                        valid={
                          this.props.customStyles.coordinatorState === "valid"
                        }
                        invalid={
                          this.props.customStyles.coordinatorState === "invalid"
                        }
                      />
                      <div className="invalid-feedback">
                        Coordenador inválido ou não encontrado.
                      </div>
                    </>
                  ) : (
                    <Input
                      value={this.props.coordinatorFullName}
                      readOnly
                      size="sm"
                    />
                  )}
                </Col>
              </div>
              <div className="form-row">
                <Col className="mb-3" md="6">
                  <label
                    className="form-control-label"
                    absolute
                    htmlFor="contract"
                  >
                    Contrato
                  </label>
                  {!this.props.activitiesMaintenence ? (
                    <Select2
                      className="form-control"
                      type="text"
                      value={this.props.contract}
                      disabled={
                        this.props.saving ||
                        this.props.projectReadOnly ||
                        this.props.customer === "" ||
                        (this.props.endDate !== null &&
                          this.props.endDate !== "")
                      }
                      onSelect={(e) => this.props.stylesForm(e, "contract")}
                      options={{
                        placeholder: "Selecione um contrato",
                        language: {
                          noResults: function () {
                            return "Nenhum contrato encontrado.";
                          },
                        },
                      }}
                      data={this.props.contractsList}
                    />
                  ) : (
                    <Input value={this.props.contractName} readOnly size="sm" />
                  )}
                </Col>
                <Col className="mb-3" md="2">
                  <label
                    className="form-control-label"
                    absolute
                    htmlFor="soldHours"
                  >
                    Horas Vendidas
                  </label>
                  <Input
                    size={this.props.activitiesMaintenence ? "sm" : ""}
                    id="soldHours"
                    type="text"
                    mask="9999:99"
                    tag={ReactInputMask}
                    value={this.props.soldHours}
                    valid={this.props.customStyles.soldHours === "valid"}
                    invalid={this.props.customStyles.soldHours === "invalid"}
                    onChange={(e) => this.props.stylesForm(e, "soldHours")}
                  />
                  <div className="invalid-feedback">
                    As horas vendidas estão num formato inválido.
                  </div>
                </Col>
                <Col className="mb-3" md="2">
                  <label
                    className="form-control-label"
                    absolute
                    htmlFor="referenceHours"
                  >
                    Horas Referência
                  </label>
                  <Input
                    size={this.props.activitiesMaintenence ? "sm" : ""}
                    id="referenceHours"
                    type="text"
                    value={this.props.referenceHours}
                    disabled
                  />
                </Col>

                <Col className="mb-3" md="2">
                  <label
                    className="form-control-label"
                    absolute
                    htmlFor="estimatedHours"
                  >
                    Horas Estimadas
                  </label>
                  <Input
                    size={this.props.activitiesMaintenence ? "sm" : ""}
                    id="estimatedHours"
                    type="text"
                    value={this.props.estimatedHours}
                    disabled
                  />
                </Col>
              </div>
              <div className="form-row"></div>
            </Form>
          </CardBody>
          <CardFooter>
            <Form>
              <div className="form-row">
                <Col className="mb-3" md="6">
                  <label
                    className="form-control-label"
                    absolute
                    htmlFor="estimatedBeginDate"
                  >
                    Previsão Início
                  </label>
                  <Input
                    size={this.props.activitiesMaintenence ? "sm" : ""}
                    readOnly={this.props.projectReadOnly}
                    id="estimatedBeginDate"
                    type="date"
                    value={this.props.estimatedBeginDate}
                    onChange={(e) =>
                      this.props.stylesForm(e, "estimatedBeginDate")
                    }
                  />
                </Col>
                <Col className="mb-3" md="6">
                  <label
                    className="form-control-label"
                    absolute
                    htmlFor="estimatedEndDate"
                  >
                    Previsão Término
                  </label>
                  <Input
                    size={this.props.activitiesMaintenence ? "sm" : ""}
                    readOnly={this.props.projectReadOnly}
                    id="estimatedEndDate"
                    type="date"
                    value={this.props.estimatedEndDate}
                    onChange={(e) =>
                      this.props.stylesForm(e, "estimatedEndDate")
                    }
                  />
                </Col>
              </div>
              <div className="form-row">
                <Col className="mb-3" md="6">
                  <label
                    className="form-control-label"
                    absolute
                    htmlFor="beginDate"
                  >
                    Início
                  </label>
                  <Input
                    size={this.props.activitiesMaintenence ? "sm" : ""}
                    readOnly={this.props.projectReadOnly}
                    disabled
                    id="beginDate"
                    type="date"
                    value={this.props.beginDate}
                    onChange={(e) => this.props.stylesForm(e, "beginDate")}
                  />
                </Col>
                <Col className="mb-3" md="6">
                  <label
                    className="form-control-label"
                    absolute
                    htmlFor="endDate"
                  >
                    Término
                  </label>
                  <Input
                    size={this.props.activitiesMaintenence ? "sm" : ""}
                    readOnly={this.props.projectReadOnly}
                    disabled
                    id="endDate"
                    type="date"
                    value={this.props.endDate}
                    onChange={(e) => this.props.stylesForm(e, "endDate")}
                  />
                </Col>
              </div>
            </Form>
          </CardFooter>
        </Card>

        {this.props.crudProject === "A" ? (
          <Card>
            <CardHeader>
              <Row>
                <div style={{ display: "inline-flex" }}>
                  <div className="projects-filter-header w-1/5">
                    <label
                      className="form-control-label"
                      htmlFor="appointmentsDateFilterFrom"
                    >
                      De
                    </label>
                    <Input
                      size={"md"}
                      id="appointmentsDateFilterFrom"
                      type="date"
                      value={this.props.appointmentDateFilterFrom}
                      onChange={(e) =>
                        this.props.changeAppointmentsFilter(
                          e,
                          "appointmentDateFilterFrom"
                        )
                      }
                    />
                  </div>
                  <div className="projects-filter-header w-1/5">
                    <label
                      className="form-control-label"
                      htmlFor="appointmentDateFilterTo"
                    >
                      Até
                    </label>
                    <Input
                      size={"md"}
                      id="appointmentDateFilterTo"
                      type="date"
                      value={this.props.appointmentDateFilterTo}
                      onChange={(e) =>
                        this.props.changeAppointmentsFilter(
                          e,
                          "appointmentDateFilterTo"
                        )
                      }
                    />
                  </div>
                  <div className="projects-filter-header w-1/4">
                    <label className="form-control-label" htmlFor="billling">
                      Consultor
                    </label>
                    <Select2
                      id="consultant"
                      value={this.props.consultantFilter}
                      onSelect={(e) =>
                        this.props.setConsultantFilter(e.target.value)
                      }
                      options={{
                        hidden: true,

                        placeholder: "Selecione um consultor",
                        language: {
                          noResults: function () {
                            return "Nenhum consultor encontrado.";
                          },
                        },
                      }}
                      data={[
                        ...this.props.consultantsList,
                        { id: "all", text: "Todos" },
                      ]}
                    />
                  </div>
                  <div
                    className="projects-filter-header w-1/10 "
                    style={{ paddingTop: "35px" }}
                  >
                    <Button
                      disabled={this.props.isLoadingFilteredActivities}
                      className="btn-round btn-icon"
                      onClick={() => this.props.filterAppointments()}
                    >
                      Filtrar
                    </Button>
                  </div>
                  <div className="projects-filter-header w-1/8">
                    <label className="form-control-label" htmlFor="cost">
                      Custo
                    </label>
                    <Input
                      id="cost"
                      type="text"
                      value={transformNumberToCurency(this.props.projectTotal)}
                      disabled
                    />
                  </div>
                  <div className="projects-filter-header w-1/8">
                    <label
                      className="form-control-label"
                      htmlFor="performedHours"
                    >
                      Realizado
                    </label>
                    <Input
                      id="performedHours"
                      type="text"
                      value={this.props.projectTotalHours}
                      disabled
                    />
                  </div>
                </div>
              </Row>
              <Row style={{ marginTop: "50px" }}>
                <div className="projects-filter-header w-1/10">
                  <label className="form-control-label" htmlFor="billling">
                    Somente Realizado
                  </label>
                  <div className="projects-filter-header">
                    <label className="custom-toggle custom-toggle-default">
                      <input
                        type="checkbox"
                        id="billing"
                        checked={this.props.filterNonPerformed}
                        onChange={(e) => {
                          this.props.changeAppointmentsFilter(
                            e,
                            "filterNonPerformed"
                          );
                          this.props.refreshActivities();
                        }}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="Não"
                        data-label-on="Sim"
                      />
                    </label>
                  </div>
                </div>
                <div className="projects-filter-header w-1/10">
                  <label className="form-control-label" htmlFor="billling">
                    Somente Em Andamento
                  </label>
                  <div className="projects-filter-header">
                    <label className="custom-toggle custom-toggle-default">
                      <input
                        type="checkbox"
                        id="billing"
                        checked={this.props.filterNotInProgress}
                        onChange={(e) => {
                          this.props.changeAppointmentsFilter(
                            e,
                            "filterNotInProgress"
                          );
                          this.props.refreshActivities();
                        }}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="Não"
                        data-label-on="Sim"
                      />
                    </label>
                  </div>
                </div>
              </Row>
            </CardHeader>
          </Card>
        ) : null}
        {this.props.crudProject === "A" ? (
          <ActivitiesPanel
            isLoadingActivities={
              this.props.isLoadingActivities ||
              this.props.isLoadingFilteredActivities
            }
            openedActivities={this.props.openedActivities}
            endDate={this.props.endDate}
            activities={(this.props.activities || [])
              .filter((activity) => {
                let shouldPersist = true;

                if (this?.props?.filterNonPerformed) {
                  shouldPersist = activity.performedMinutes > 0;
                }

                if (shouldPersist && this?.props?.filterNotInProgress) {
                  shouldPersist = activity.status === "I";
                }

                return shouldPersist;
              })
              .map((activity) => {
                const updatedActivity = {
                  ...activity,
                  subActivities: (activity?.subActivities || []).filter(
                    (subActivity) => {
                      let shouldPersist = true;

                      if (this?.props?.filterNonPerformed) {
                        shouldPersist = subActivity.performedMinutes > 0;
                      }

                      if (shouldPersist && this?.props?.filterNotInProgress) {
                        shouldPersist = subActivity.status === "I";
                      }

                      return shouldPersist;
                    }
                  ),
                };

                return updatedActivity;
              })}
            showOrHideActivity={this.props.showOrHideActivity.bind(this)}
            activitiesMaintenence={this.props.activitiesMaintenence}
            sabing={this.props.saving}
            allActivitiesOpened={this.props.allActivitiesOpened}
            showAllActivities={this.props.showAllActivities.bind(this)}
            hideAllActivities={this.props.hideAllActivities.bind(this)}
            openModalActivity={this.props.openModalActivity.bind(this)}
            openModalConsultant={this.props.openModalConsultant.bind(this)}
            openAppointmentsModal={this.props.openAppointmentsModal.bind(this)}
          />
        ) : null}
      </>
    );
  }
}

export default ProjectPanel;
