import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Form,
  Input,
  Modal,
  Row,
  Col,
} from "reactstrap";

class Service extends React.Component {
  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.props.modalOpened}
          autoFocus={false}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {this.props.modalTitle}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeModal(e)}
              disabled={this.props.saving}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardBody>
                    <Form
                      className="needs-validation"
                      noValidate
                      autoComplete="off"
                    >
                      <div className="form-row">
                        <Col className="mb-3" md="3">
                          <label className="form-control-label" htmlFor="code">
                            Código
                          </label>
                          <Input
                            autoFocus
                            id="code"
                            placeholder="Código"
                            type="text"
                            value={this.props.code}
                            readOnly={this.props.readOnly}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.codeState === "valid"
                            }
                            invalid={
                              this.props.customStyles.codeState === "invalid"
                            }
                            onChange={(e) => this.props.stylesForm(e, "code")}
                          />
                          <div className="invalid-feedback">
                            Digite o código do serviço.
                          </div>
                        </Col>
                        <Col className="mb-3" md="9">
                          <label className="form-control-label" htmlFor="name">
                            Nome
                          </label>
                          <Input
                            autoFocus
                            id="name"
                            placeholder="Nome do serviço"
                            type="text"
                            value={this.props.name}
                            readOnly={this.props.readOnly}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.nameState === "valid"
                            }
                            invalid={
                              this.props.customStyles.nameState === "invalid"
                            }
                            onChange={(e) => this.props.stylesForm(e, "name")}
                          />
                          <div className="invalid-feedback">
                            Digite o nome do serviço.
                          </div>
                        </Col>
                      </div>
                      <div className="form-row">
                        <Col className="mb-3" md="12">
                          <label className="form-control-label" htmlFor="name">
                            Texto Nota Fiscal
                          </label>
                          <Input
                            autoFocus
                            id="description"
                            placeholder="Nome do serviço"
                            type="textarea"
                            rows="3"
                            value={this.props.description}
                            readOnly={this.props.readOnly}
                            disabled={this.props.saving}
                            valid={
                              this.props.customStyles.descriptionState ===
                              "valid"
                            }
                            invalid={
                              this.props.customStyles.descriptionState ===
                              "invalid"
                            }
                            onChange={(e) =>
                              this.props.stylesForm(e, "description")
                            }
                          />
                          <div className="invalid-feedback">
                            Digite o texto para a nota fiscal.
                          </div>
                        </Col>
                      </div>
                      <div className="form-row">
                        <Col className="mt-3" md="2">
                          <label
                            className="form-control-label"
                            htmlFor="active"
                          >
                            Ativo
                          </label>
                          <div>
                            <label className="custom-toggle custom-toggle-default mr-1">
                              <input
                                defaultChecked={this.props.active}
                                type="checkbox"
                                value={this.props.active}
                                disabled={
                                  this.props.readOnly || this.props.saving
                                }
                                onChange={(e) =>
                                  this.props.stylesForm(e, "active", true)
                                }
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="Não"
                                data-label-on="Sim"
                              />
                            </label>
                          </div>
                        </Col>
                        <Col className="mb-0" md="2">
                          <label
                            className="form-control-label"
                            htmlFor="needHourlyRate"
                          >
                            Exige <br></br> Valor/Hora
                          </label>
                          <div>
                            <label className="custom-toggle custom-toggle-default mr-1">
                              <input
                                defaultChecked={this.props.needHourlyRate}
                                type="checkbox"
                                value={this.props.needHourlyRate}
                                disabled={
                                  this.props.readOnly || this.props.saving
                                }
                                onChange={(e) =>
                                  this.props.stylesForm(
                                    e,
                                    "needHourlyRate",
                                    true
                                  )
                                }
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="Não"
                                data-label-on="Sim"
                              />
                            </label>
                          </div>
                        </Col>
                        <Col className="mt-3" md="1">
                          <label
                            className="form-control-label"
                            htmlFor="needHourlyRate"
                          >
                            Investimento
                          </label>
                          <div>
                            <label className="custom-toggle custom-toggle-default mr-1">
                              <input
                                defaultChecked={this.props.investment}
                                type="checkbox"
                                value={this.props.investment}
                                disabled={
                                  this.props.readOnly || this.props.saving
                                }
                                onChange={(e) =>
                                  this.props.stylesForm(
                                    e,
                                    "investment",
                                    true
                                  )
                                }
                              />
                              <span
                                className="custom-toggle-slider rounded-circle"
                                data-label-off="Não"
                                data-label-on="Sim"
                              />
                            </label>
                          </div>
                        </Col>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
          <div className="modal-footer">
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.props.closeModal(e, "abort")}
              disabled={this.props.saving}
            >
              Cancelar
            </Button>
            <Button
              color="success"
              type="button"
              onClick={(e) => this.props.modalSave(e)}
              hidden={this.props.crud === "R"}
              disabled={this.props.saving}
            >
              {this.props.crud === "D" ? "Excluir" : "Salvar"}
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default Service;
