import SglTable from 'components/ToolkitTable/SglTable'
import React, { useMemo } from 'react'
import { Badge, Label, Table } from 'reactstrap'
import { transformNumberToCurency } from 'utils/currencyHandling'
import { convertMongoDateAndHour } from 'utils/dateHandling'
import { convertMongoDate } from 'utils/dateHandling'

const columns = [
  {
    dataField: "_id",
    text: "Id",
    hidden: true,
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    hidden: false,
  },
  {
    dataField: "createdAt",
    text: "Criado em",
    sort: true,
    hidden: false,
  },
  {
    dataField: "code",
    text: "Código",
    sort: true,
  },
  {
    dataField: "month",
    text: "Mês",
    sort: true,
  },
  {
    dataField: "year",
    text: "Ano",
    sort: true,
  },
  {
    dataField: "date",
    text: "Data",
    sort: true,
  },
  {
    dataField: "hoursTotal",
    text: "Total Horas",
    sort: true,
  },
  {
    dataField: "billingTotal",
    text: "Total Faturamento",
    sort: true,
  },
]

function BillingsTable(props) {
  const billings = useMemo(() => {
  if (Array.isArray(props.billings) && props.billings.length > 0)    
    return props.billings.map((billing, index) => {
      let status = ''
      let bilingDate = new Date(billing.date)
      let dataProducao = new Date('20230601')  
      console.log(billing.invoices)  

     if (billing.invoices?.length <= 0) 
       return null

        if (billing.invoices[0].customer.cnpj !== "13.994.051/0001-28") {

          status = billing.invoices[0].sapDocEntry ? "Transmitido" : "";
        }
        else {
          status = billing.invoices[1].sapDocEntry ? "Transmitido" : "";
        }      

      return {
        _id: billing._id,
        createdAt: convertMongoDateAndHour(billing.createdAt),
        status: <label> <Badge
          className="badge-lg"
          color={
            !status ? "danger" : "success"
          }
          pill
        >
         {!status ? "Não Enviado" : "Enviado"} </Badge>  </label>,
        code: billing.code,
        month: billing.month,
        year: billing.year,
        date: convertMongoDate(billing.date),
        hoursTotal: billing.hoursTotal,
        billingTotal: transformNumberToCurency(billing.total),
      }
    }).filter(item=>item)
    
    return []
  }, [props])
  return (
    <>
      {props.billings?.length > 0 && (
        <SglTable
          columns={columns}
          data={billings}
          rowClick={(row) => {
            props.handleBillingClick(props.billings.find(billing => billing._id === row._id))
          }}
        />
      )}
    </>
  )
}
export default BillingsTable
