import React from "react";
// reactstrap components
import { ListGroupItem, Form, Row, Col, Input } from "reactstrap";
import InputMask from "react-input-mask";
import brazilianStates from "variables/brazilianStates.json";

class ListGroupItemAddress extends React.Component {
  getBrazilianStates = (uf) => {
    return uf.map((prop, key) => {
      return <option key={key}>{prop.sigla}</option>;
    });
  };

  render() {
    return (
      <>
        {this.props.addressCrud === "C" || this.props.addressCrud === "U" ? (
          <ListGroupItem
            className="list-group-item-action flex-column align-items-start py-4 px-4"
            tag="a"
          >
            <div className="d-flex w-100 justify-content-center">
              <div>
                <div className="d-flex w-100 align-items-center">
                  <h4 className="mb-1">
                    {this.props.addressCrud === "C"
                      ? "Novo Endereço"
                      : "Alterando Endereço"}
                  </h4>
                </div>
              </div>
            </div>
            <Form className="needs-validation" noValidate autoComplete="off">
              <Row>
                <Col className="mb-3" md="12">
                  <label
                    className="form-control-label"
                    htmlFor="newAddressDescription"
                  >
                    Descrição
                  </label>
                  <Input
                    className="form-control-sm"
                    id="newAddressDescription"
                    placeholder="Ex.: Entrega, Cobrança"
                    type="text"
                    value={this.props.newAddressDescription}
                    disabled={this.props.saving}
                    valid={
                      this.props.customStyles.newAddressDescriptionState ===
                      "valid"
                    }
                    invalid={
                      this.props.customStyles.newAddressDescriptionState ===
                      "invalid"
                    }
                    onChange={(e) =>
                      this.props.stylesForm(e, "newAddressDescription")
                    }
                  />
                  <div className="invalid-feedback">
                    Digite a descrição do endereço.
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3" md="12">
                  <label
                    className="form-control-label"
                    htmlFor="newAddressAddress"
                  >
                    Endereço
                  </label>
                  <Input
                    className="form-control-sm"
                    id="newAddressAddress"
                    placeholder="Ex.: Rua XV de Novembro, 1234"
                    type="text"
                    value={this.props.newAddressAddress}
                    disabled={this.props.saving}
                    valid={
                      this.props.customStyles.newAddressAddressState === "valid"
                    }
                    invalid={
                      this.props.customStyles.newAddressAddressState ===
                      "invalid"
                    }
                    onChange={(e) =>
                      this.props.stylesForm(e, "newAddressAddress")
                    }
                  />
                  <div className="invalid-feedback">Digite o endereço.</div>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3" md="12">
                  <label
                    className="form-control-label"
                    htmlFor="newAddressAdditionalInfo"
                  >
                    Complemento
                  </label>
                  <Input
                    className="form-control-sm"
                    id="newAddressAdditionalInfo"
                    placeholder="Ex.: Complexo Industrial ABC"
                    type="text"
                    value={this.props.newAddressAdditionalInfo}
                    disabled={this.props.saving}
                    onChange={(e) =>
                      this.props.stylesForm(e, "newAddressAdditionalInfo")
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-3" md="12">
                  <label
                    className="form-control-label"
                    htmlFor="newAddressRegion"
                  >
                    Bairro
                  </label>
                  <Input
                    className="form-control-sm"
                    id="newAddressRegion"
                    placeholder="Nome do Bairro"
                    type="text"
                    value={this.props.newAddressRegion}
                    disabled={this.props.saving}
                    valid={
                      this.props.customStyles.newAddressRegionState === "valid"
                    }
                    invalid={
                      this.props.customStyles.newAddressRegionState ===
                      "invalid"
                    }
                    onChange={(e) =>
                      this.props.stylesForm(e, "newAddressRegion")
                    }
                  />
                  <div className="invalid-feedback">
                    Digite o nome do bairro.
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3" md="12">
                  <label
                    className="form-control-label"
                    htmlFor="newAddressCity"
                  >
                    Cidade
                  </label>
                  <Input
                    className="form-control-sm"
                    id="newAddressCity"
                    placeholder="Nome da Cidade"
                    type="text"
                    value={this.props.newAddressCity}
                    disabled={this.props.saving}
                    valid={
                      this.props.customStyles.newAddressCityState === "valid"
                    }
                    invalid={
                      this.props.customStyles.newAddressCityState === "invalid"
                    }
                    onChange={(e) => this.props.stylesForm(e, "newAddressCity")}
                  />
                  <div className="invalid-feedback">
                    Digite o nome da cidade.
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="mb-3" md="4">
                  <label
                    className="form-control-label"
                    htmlFor="newAddressState"
                  >
                    Estado
                  </label>
                  <Input
                    className="form-control-sm"
                    id="newAddressState"
                    type="select"
                    value={this.props.newAddressState}
                    disabled={this.props.saving}
                    onChange={(e) =>
                      this.props.stylesForm(e, "newAddressState")
                    }
                    valid={
                      this.props.customStyles.newAddressStateState === "valid"
                    }
                    invalid={
                      this.props.customStyles.newAddressStateState === "invalid"
                    }
                  >
                    {this.getBrazilianStates(brazilianStates.uf)}
                  </Input>
                  <div className="invalid-feedback">
                    Estado não selecionado.
                  </div>
                </Col>
                <Col className="mb-3" md="4">
                  <label
                    className="form-control-label"
                    htmlFor="newAddressPostalCode"
                  >
                    CEP
                  </label>
                  <Input
                    className="form-control-sm"
                    id="newAddressPostalCode"
                    placeholder="00000-000"
                    mask="99999-999"
                    type="tel"
                    tag={InputMask}
                    value={this.props.newAddressPostalCode}
                    disabled={this.props.saving}
                    onChange={(e) =>
                      this.props.stylesForm(e, "newAddressPostalCode")
                    }
                  />
                </Col>
                <Col className="mb-3" md="4">
                  <label
                    className="form-control-label"
                    htmlFor="newAddressTransfer"
                  >
                    Traslado (Horas)
                  </label>
                  <Input
                    className="form-control-sm"
                    id="newAddressTransfer"
                    placeholder=""
                    type="time"
                    value={this.props.newAddressTransfer}
                    disabled={this.props.saving}
                    onChange={(e) =>
                      this.props.stylesForm(e, "newAddressTransfer")
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-3" md="6" lg="4">
                  <label
                    className="form-control-label"
                    htmlFor="newAddressDefaultAddress"
                  >
                    Endereço Padrão
                  </label>
                  <div>
                    <label className="custom-toggle custom-toggle-default mr-1">
                      <input
                        defaultChecked={this.props.newAddressDefaultAddress}
                        type="checkbox"
                        checked={this.props.newAddressDefaultAddress}
                        disabled={this.props.saving}
                        onChange={(e) =>
                          this.props.stylesForm(
                            e,
                            "newAddressDefaultAddress",
                            true
                          )
                        }
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="Não"
                        data-label-on="Sim"
                      />
                    </label>
                  </div>
                </Col>
              </Row>
            </Form>
            {this.props.addressCrud === "C" ? (
              <div className="d-flex w-100 justify-content-between">
                <div>
                  <div className="d-flex w-100 align-items-center">
                    <h4 className="mb-1"> </h4>
                  </div>
                  <div className="d-flex w-100 align-items-center">
                    <small>
                      <a
                        href="#saveNewAddress"
                        className="text-success"
                        onClick={(e) =>
                          this.props.saveNewAddress(e, this.props)
                        }
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-check-bold" />
                        </span>
                        Salvar
                      </a>
                      <a
                        href="#cancelNewAddress"
                        className="text-danger"
                        onClick={(e) => this.props.cancelNewAddress(e)}
                      >
                        <span className="btn-inner--icon mr-1">
                          {" | "}
                          <i className="ni ni-fat-remove" />
                        </span>
                        Cancelar
                      </a>
                    </small>
                  </div>
                </div>
              </div>
            ) : null}
            {this.props.addressCrud === "U" ? (
              <div className="d-flex w-100 justify-content-between">
                <div>
                  <div className="d-flex w-100 align-items-center">
                    <h4 className="mb-1"> </h4>
                  </div>
                  <div className="d-flex w-100 align-items-center">
                    <small>
                      <a
                        href="#updateAddress"
                        className="text-success"
                        onClick={(e) => this.props.updateAddress(e, this.props)}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="ni ni-check-bold" />
                        </span>
                        Salvar Alterações
                      </a>
                      <a
                        href="#cancelNewAddress"
                        className="text-danger"
                        onClick={(e) =>
                          this.props.cancelAddressUpdate(e, this.props)
                        }
                      >
                        <span className="btn-inner--icon mr-1">
                          {" | "}
                          <i className="ni ni-fat-remove" />
                        </span>
                        Cancelar Alterações
                      </a>
                    </small>
                  </div>
                </div>
              </div>
            ) : null}
          </ListGroupItem>
        ) : (
          <ListGroupItem
            className="list-group-item-action flex-column align-items-start py-4 px-4"
            tag="a"
            hidden={this.props.neededAction === "D"}
          >
            <div className="d-flex w-100 justify-content-between">
              <div>
                <div className="d-flex w-100 align-items-center">
                  <h4 className="mb-1">{this.props.description}</h4>
                </div>
              </div>
              <small className="text-info" hidden={!this.props.defaultAddress}>
                <span className="btn-inner--icon mr-1">
                  <i className="ni ni-pin-3" />
                </span>
                Padrão
              </small>
            </div>
            <h5 className="mb-0">
              Endereço:
              <span className="text-muted">{" " + this.props.address}</span>
            </h5>
            <h5 className="mb-0">
              Complemento:
              <span className="text-muted">
                {" " + this.props.additionalInfo}
              </span>
            </h5>
            <h5 className="mb-0">
              Bairro | Cidade | UF | CEP:
              <span className="text-muted">
                {" " +
                  this.props.region +
                  " | " +
                  this.props.city +
                  " | " +
                  this.props.state +
                  " | " +
                  this.props.postalCode}
              </span>
            </h5>
            <h5 className="mb-0">
              Traslado:
              <span className="text-muted">
                {" " + this.props.transfer && this.props.transfer !== null
                  ? this.props.transfer
                  : ""}
              </span>
            </h5>
            {(this.props.crud === "C" || this.props.crud === "U") &&
            this.props.addressCrud !== "D" &&
            !this.props.creatingAddress &&
            !this.props.updatingAddress ? (
              <div className="d-flex w-100 justify-content-between">
                <div>
                  <div className="d-flex w-100 align-items-center">
                    <h4 className="mb-1"> </h4>
                  </div>
                  <div className="d-flex w-100 align-items-center">
                    <small>
                      <a
                        href="#updateAddress"
                        onClick={(e) => this.props.updateAddress(e, this.props)}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-user-edit" />
                        </span>
                        Alterar
                      </a>
                      <a
                        href="#deleteAddress"
                        className="text-danger"
                        onClick={(e) => this.props.deleteAddress(e, this.props)}
                      >
                        <span className="btn-inner--icon mr-1">
                          {" | "}
                          <i className="fas fa-trash" />
                        </span>
                        Excluir
                      </a>
                    </small>
                  </div>
                </div>
              </div>
            ) : null}
            {this.props.addressCrud === "D" ? (
              <div className="d-flex w-100 justify-content-between">
                <div>
                  <div className="d-flex w-100 align-items-center">
                    <h4 className="mb-1"> </h4>
                  </div>
                  <div className="d-flex w-100 align-items-center">
                    <small>
                      <a
                        href="#deleteAddress"
                        className="text-success"
                        onClick={(e) => this.props.deleteAddress(e, this.props)}
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-trash" />
                        </span>
                        Confirmar Exclusão
                      </a>
                      <a
                        href="#cancelAddressDelete"
                        className="text-danger"
                        onClick={(e) =>
                          this.props.cancelAddressDelete(e, this.props)
                        }
                      >
                        <span className="btn-inner--icon mr-1">
                          {" | "}
                          <i className="ni ni-fat-remove" />
                        </span>
                        Cancelar
                      </a>
                    </small>
                  </div>
                </div>
              </div>
            ) : null}
          </ListGroupItem>
        )}
      </>
    );
  }
}

export default ListGroupItemAddress;
