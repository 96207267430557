import CreateAppointment from "components/Modals/create/CreateAppointment"
import Appointment from "components/Modals/show-edit/Appointment"
import Flag from "components/Tag/Flag"
import React, { useEffect, useState } from "react"
import { Col } from "react-grid"
import { Button, Container, Row } from "reactstrap"
import { hoursToMinutes } from "utils/timeHandling"

function AppointmentsTable(props) {
  const [selectedAppointment, setSelectedAppointment] = useState({})
  const [activityRemainingMinutes, setActivityRemainingMinutes] = useState(0)

  const [createAppointmentModalOpen, setCreateAppointmentModalOpen] =
    useState(false)
  const [appointmentModalOpen, setAppointmentModalOpen] = useState(false)

  useEffect(() => {
    const totalPerformedMinutes = hoursToMinutes(props.activity.performedHours)
    const totalEstmatedMinutes = hoursToMinutes(props.activity.estimatedHours)

    setActivityRemainingMinutes(totalEstmatedMinutes - totalPerformedMinutes)
  }, [props.activity, activityRemainingMinutes])

  return (
    <>
      <Row className="my-3" style={{ justifyContent: "right" }}>
        <Button
          className="btn-round btn-icon"
          color="primary"
          onClick={(e) => {
            const dateUsed = props.appointments.filter((app) => {
              return app.date.split("T")[0] === props.schedule.date
            })
            if (dateUsed.length > 0) {
              alert(
                "Não pode haver dois apontamentos da mesma tarefa no mesmo dia"
              )
            } else {
              setCreateAppointmentModalOpen(true)
            }
          }}
          disabled={
            props.appointments.filter((app) => {
              return app.date.split("T")[0] === props.schedule.date
            }).length > 0 ||
            props.schedule.serviceOrder ||
            props.activity.status === "F" ||
            (!props.hasBalance && props.activity.hoursSettled)
          }
          size="sm"
        >
          <span className="btn-inner--icon mr-1">
            <i className="fas fa-plus" />
          </span>
          <span className="btn-inner--text">Adicionar Apontamento</span>
        </Button>
      </Row>
      {props.appointments.length > 0 && (
        <Container className="mb-6">
          <Row>
            <Col xs={3}>
              <h4>Data</h4>{" "}
            </Col>
            <Col xs={7}>
              <h4>Descricão</h4>
            </Col>
            <Col xs={1}>
              <h4>Horas</h4>
            </Col>
            <Col xs={1}>
              <h4>OS</h4>
            </Col>
          </Row>
          {props.appointments.map((appointment, index) => {
            return (
              <Row
                key={index}
                className={"my-3"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setAppointmentModalOpen(true)
                  setSelectedAppointment(appointment)
                }}
              >
                <Col xs={3}>
                  {new Date(
                    appointment.date.split("T")[0] + " 00:00"
                  ).toLocaleDateString("pt-BR")}
                </Col>
                <Col
                  xs={7}
                  style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  {appointment.description.slice(0, 40)}
                </Col>
                <Col xs={1}>{appointment.hours}</Col>
                <Col xs={1} style={{ textAlign: "-webkit-center" }}>
                  {appointment.osCreated ? (
                    <Flag backgroundColor="green">v</Flag>
                  ) : (
                    <Flag backgroundColor="red">x</Flag>
                  )}
                </Col>
              </Row>
            )
          })}
        </Container>
      )}
      {props.appointments.length <= 0 && (
        <h3> Não há apontamentos criados para essa atividade</h3>
      )}
      <CreateAppointment
        isOpen={createAppointmentModalOpen}
        activityRemainingMinutes={activityRemainingMinutes}
        schedule={props.schedule}
        activity={props.activity}
        title={"Criar Apontamento"}
        action={"Criar"}
        updateActivities={() => {
          props.updateActivities()
        }}
        closeModal={() => {
          setCreateAppointmentModalOpen(false)
        }}
      />
      <Appointment
        isOpen={appointmentModalOpen}
        activityRemainingMinutes={activityRemainingMinutes}
        schedule={props.schedule}
        activity={props.activity}
        appointment={selectedAppointment}
        title={"Editar Apontamento"}
        action={"Salvar"}
        updateActivities={() => {
          props.updateActivities()
        }}
        closeModal={() => {
          setAppointmentModalOpen(false)
        }}
      />
    </>
  )
}

export default AppointmentsTable
