import React, { useEffect } from "react";
import { Table } from "reactstrap";
import PropTypes from "prop-types";
import { convertMongoDate } from "utils/dateHandling";

function PendenciesDetailTable(props) {
  return (
    <Table>
      <thead>
        <tr>
          <th>
            Data <br></br> Agenda
          </th>
          {props.pendency?.pendencies?.schedulings?.length > 0 && (
            <th>Apontado Em</th>
          )}
          <th>Início</th>
          <th>Fim</th>
          <th>Total</th>
          <th>Cliente</th>
          <th>Projeto</th>
        </tr>
      </thead>
      <tbody>
        {props.pendency?.pendencies?.pointings?.map?.((pointing, index) => {
          return (
            <tr key={index}>
              <td>{convertMongoDate(pointing.date)}</td>
              <td>{pointing.timeFrom}</td>
              <td>{pointing.timeTo}</td>
              <td>{pointing.timeTotal}</td>
              <td>{pointing.customer.fullName}</td>
              <td>{pointing.project.name}</td>
            </tr>
          );
        })}
        {props.pendency?.pendencies?.schedulings?.map?.((schedule, index) => {
          return (
            <tr key={index}>
              <td>{schedule.date}</td>
              <td>{schedule.createdAt}</td>
              <td>{schedule.timeFrom}</td>
              <td>{schedule.timeTo}</td>
              <td>{schedule.timeTotal}</td>
              <td>{schedule.customer}</td>
              <td>{schedule.project}</td>     
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

PendenciesDetailTable.propTypes = {
  pendency: PropTypes.object.isRequired,
};
export default PendenciesDetailTable;
