import React from "react"
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Badge,
} from "reactstrap"

// core components
import LightHeader from "components/Headers/LightHeader.js"
import ReactBSAlert from "react-bootstrap-sweetalert"
import Moment from "moment"

import api from "services/api"
import Loading from "components/Modals/Loading"
import ProjectPanel from "../registrations/ProjectPanel"
import Activity from "components/Modals/show-edit/Activity"
import ActivityConsultant from "components/Modals/show-edit/ActivityConsultant"
import ConfirmationModal from "components/Modals/ConfirmationModal"
import FinishProjectModal from "components/Modals/FinishProjectModal"
import { convertMongoDate } from "utils/dateHandling"
import { hoursToMinutes } from "utils/timeHandling"
import { minutesToHours } from "utils/timeHandling"
import Appointments from "components/Modals/show-edit/Appointments"
import { getMidnightThisDay } from "utils/dateHandling"

const projectTypes = {
  B: "Body Shop",
  D: "Desenvolvimento/Customização",
  I: "Implantação/Consultoria",
  S: "Suporte Pontual",
  U: "Upgrade/Migração",
  C: "SLA - Contrato Serviços de Valor Agregado",
}

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPage: 25,
  paginationTotalRenderer: (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {" [ "} Mostrando linhas {from} a {to} de {size} ]
    </span>
  ),
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Mostrar{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        registros.
      </label>
    </div>
  ),
})

const { SearchBar } = Search

class Projects extends React.Component {
  state = {
    data: [],
    isLoading: true,
    saving: false,
    alert: null,
    consultantFilter: "all",

    //Project panel data variables
    dataPanelTitle: "",
    crudProject: "",
    selectedproject: {},
    projectReadOnly: false,
    id: "",
    code: "",
    name: "",
    projectType: null,
    customer: "",
    contract: null,
    coordinator: null,
    soldHours: "0000:00",
    referenceHours: "0000:00",
    estimatedHours: "0000:00",
    performedHours: "0000:00",
    estimatedBeginDate: "",
    estimatedEndDate: "",
    beginDate: "",
    endDate: "",
    appointmentDateFilterFrom: null,
    appointmentDateFilterTo: null,
    filterNonPerformed: false,
    flterConsultant: null,
    projectTotal: 0,
    projectTotalHours: "00:00",
    customersList: [],
    coordinatorsList: [],
    contractsList: [],
    isAppointmentsModalOpen: false,
    consultantActivitiAppointments: [],
    consultantActivityActivity: {},
    customStyles: {
      //Project data fields validations
      codeState: null,
      nameState: null,
      projectTypeState: null,
      customerState: null,
      coordinatorState: null,
      //Activity data fields validations
      activityProcessState: null,
      activitySoldHoursState: null,
      //Consultant
      consultantState: null,
      soldHours: null,
    },

    //Activities
    activitiesMaintenence: false,
    selectedActivity: {},
    customerShortName: "",
    contractName: "",
    coordinatorFullName: "",
    activities: [],
    allActivitiesOpened: false,
    openedActivities: [],

    //Modal Activity
    modalActivityOpened: false,
    modalActivityTitle: "",
    crudActivity: "",
    activityActivity: null,
    activityCode: "",
    activityProcess: "",
    activityRequester: "",
    activitySoldHours: 0,
    activityEstimatedHours: 0,
    activityPerformedHours: 0,
    activityHoursSettled: false,
    activityHasActivities: false,
    activityStatus: "",
    activityConclusion: "",
    //Modal Consultant
    modalConsultantOpen: false,
    modalConsultantTitle: "",
    consultantsList: [],
    crudConsultant: "",
    consultant: "",
    consultantActivity: "",
    consultantEstimatedHours: "0000:00",
    selectedActivityConsultant: {},

    //Confirmation Modal

    confirmationModalOpen: false,
    confirmationModalText: "",
    confirmationModalAction: {},
    selectedProject: {},

    //Finish project modal
    finishProjectModalOpen: false,
  }

  componentDidMount() {
    this.loadData()
    this.loadCustomersList()
    this.loadCoordinatorsList()
    this.loadConsultantsList()
  }

  loadData = async () => {
    this.setState({
      isLoading: true,
      crudProject: "",
    })

    const response = await api.post("/project/searchSgl", {
      deleted: "N",
    })

    let projects = response.data

    for (let i = 0; i < projects.length; i++) {
      if (!projects[i].coordinator) {
        projects[i] = {
          ...projects[i],
          coordinator: {
            _id: null,
            fullName: "",
          },
        }
      }
      if (!projects[i].contract) {
        projects[i] = {
          ...projects[i],
          contract: {
            _id: null,
            name: "",
          },
        }
      }
    }
    this.setState({ data: projects, isLoading: false, openedActivities: [] })
  }

  loadCustomersList = async () => {
    const customers = await api.get("/customer/all")

    var customer = {}
    var customersList = []

    for (let i = 0; i < customers.data.length; i++) {
      customer = {
        id: customers.data[i]._id,
        text: customers.data[i].shortName,
      }

      if (customersList.length === 0) {
        customersList = [customer]
      } else {
        customersList.push(customer)
      }
    }

    this.setState({
      customersList: customersList,
    })
  }

  loadCoordinatorsList = async () => {
    const coordinators = await api.post("/user/search", {
      deleted: "N",
      class: "CG",
      kind: "M",
      active: true,
    })

    var coordinator = {}
    var coordinatorsList = []

    for (let i = 0; i < coordinators.data.length; i++) {
      coordinator = {
        id: coordinators.data[i]._id,
        text: coordinators.data[i].fullName,
      }

      if (coordinatorsList.length === 0) {
        coordinatorsList = [coordinator]
      } else {
        coordinatorsList.push(coordinator)
      }
    }

    this.setState({
      coordinatorsList: coordinatorsList,
      isLoading: false,
    })
  }

  loadConsultantsList = async () => {
    const consultants = await api.post("/user/search", {
      deleted: "N",
      $or: [{ sglProfile: "C" }, { sglProfile: "O" }, { sglProfile: "Z" }],
      active: true,
    })

    var consultantsList = []

    for (let i = 0; i < consultants.data.length; i++) {
      consultantsList.push({
        id: consultants.data[i]._id,
        text: consultants.data[i].fullName,
      })
    }

    this.setState({
      consultantsList: consultantsList,
      isLoading: false,
    })
  }

  loadContractsList = async () => {
    this.setState({ loading: true })

    const contracts = await api.post("/contract/search", {
      deleted: "N",
      customer: this.state.customer,
    })

    var contract = {}
    var contractsList = []

    for (let i = 0; i < contracts.data.length; i++) {
      contract = {
        id: contracts.data[i]._id,
        text: contracts.data[i].code + " - " + contracts.data[i].name,
      }

      if (contractsList.length === 0) {
        contractsList = [contract]
      } else {
        contractsList.push(contract)
      }
    }
    this.setState({
      contractsList: contractsList,
      loading: false,
    })
  }

  openDataPanel = async (e, row, crudProject) => {
    let newState = this.state
    newState.crudProject = crudProject
    newState.selectedproject = row

    if (crudProject === "C") {
      newState.dataPanelTitle = "Novo Projeto"
    } else if (crudProject === "R") {
      newState.dataPanelTitle = "Visualizando Projeto"
    } else if (crudProject === "U") {
      newState.dataPanelTitle = "Alterando Projeto"
    } else if (crudProject === "D") {
      newState.dataPanelTitle = "Excluindo Projeto"
    }

    if (crudProject === "C") {
      newState.id = ""
      newState.code = ""
      newState.name = ""
      newState.customer = ""
      newState.projectType = null
      newState.contract = null
      newState.coordinator = null
      newState.soldHours = "0000:00"
      newState.referenceHours = "0000:00"
      newState.estimatedHours = "0000:00"
      newState.performedHours = "0000:00"
      newState.estimatedBeginDate = ""
      newState.estimatedEndDate = ""
      newState.beginDate = ""
      newState.endDate = ""
      newState.contractsList = []

      this.setState(newState)
    } else {
      newState.projectReadOnly = crudProject === "R" || crudProject === "D"
      newState.id = row._id
      newState.code = row.code
      newState.name = row.name
      newState.customer = row.customer._id
      if (row.projectType && row.projectType !== "") {
        newState.projectType = row.projectType
      } else {
        newState.projectType = null
      }
      newState.contract = row.contract._id
      if (row.coordinator._id && row.coordinator._id !== "") {
        newState.coordinator = row.coordinator._id
      } else {
        newState.coordinator = null
      }
      newState.soldHours = row.soldHours

      newState.referenceHours = row.referenceHours || "0000:00"
      newState.estimatedHours = row.estimatedHours
      newState.performedHours = row.performedHours
      if (
        row.estimatedBeginDate &&
        row.estimatedBeginDate !== null &&
        row.estimatedBeginDate !== ""
      ) {
        newState.estimatedBeginDate = Moment.utc(row.estimatedBeginDate).format(
          "YYYY-MM-DD"
        )
      } else {
        newState.estimatedBeginDate = ""
      }
      if (
        row.estimatedEndDate &&
        row.estimatedEndDate !== null &&
        row.estimatedEndDate !== ""
      ) {
        newState.estimatedEndDate = Moment.utc(row.estimatedEndDate).format(
          "YYYY-MM-DD"
        )
      } else {
        newState.estimatedEndDate = ""
      }
      if (row.beginDate && row.beginDate !== null && row.beginDate !== "") {
        newState.beginDate = Moment.utc(row.beginDate).format("YYYY-MM-DD")
      } else {
        newState.beginDate = ""
      }
      if (row.endDate && row.endDate !== null && row.endDate !== "") {
        newState.endDate = Moment.utc(row.endDate).format("YYYY-MM-DD")
      } else {
        newState.endDate = ""
      }

      this.setState(newState)
      this.loadContractsList()
    }
  }

  closeDataPanel(e) {
    let newState = this.state
    let crudProject = newState.crudProject

    newState.activitiesMaintenence = false
    newState.openedActivities = []
    newState.crudProject = ""
    newState.selectedproject = {}
    newState.projectReadOnly = false
    newState.saving = false
    newState.id = ""
    newState.code = ""
    newState.name = ""
    newState.customer = ""
    newState.customerShortName = ""
    newState.projectType = ""
    newState.contract = null
    newState.contractName = ""
    newState.coordinator = null
    newState.coordinatorFullName = ""
    newState.soldHours = "0000:00"
    newState.referenceHours = "0000:00"
    newState.estimatedHours = "0000:00"
    newState.performedHours = "0000:00"
    newState.estimatedBeginDate = ""
    newState.estimatedEndDate = ""
    newState.beginDate = ""
    newState.endDate = ""
    newState.contractsList = []
    newState.allActivitiesOpened = false
    newState.activities = []
    newState.projectTotal = 0
    newState.projectTotalHours = 0
    newState.customStyles = {
      customerState: null,
      codeState: null,
      nameState: null,
      projectTypeState: null,
      coordinators: null,
    }

    this.setState(newState)

    if (crudProject === "A") {
      this.loadData()
    }
  }

  saveProjectData = async (e) => {
    this.setState({ saving: true })

    let newState = this.state
    let newCustomStyles = newState.customStyles

    var letSave = true
    var newData = {}
    var success = true

    if (this.state.crudProject === "D") {
      //In the future add exclusion dependencies validations
      //...

      try {
        //Delete project
        await api.put("/project/update/" + this.state.id, { deleted: "Y" })
      } catch (err) {
        this.warningAlert("Ocorreu um erro ao tentar excluir. Tente novamente.")
        success = false
        this.setState({ saving: false })
        return
      }

      if (success) {
        this.loadData()
        this.closeDataPanel()
      }
    } else if (
      this.state.crudProject === "C" ||
      this.state.crudProject === "U"
    ) {
      //Field content validations
      if (newState.name === "") {
        newCustomStyles.nameState = "invalid"
        letSave = false
      } else {
        newCustomStyles.nameState = "valid"
      }
      if (newState.code === "") {
        newCustomStyles.codeState = "invalid"
        letSave = false
      } else {
        newCustomStyles.codeState = "valid"
      }

      if (newState.customer === "") {
        newCustomStyles.customerState = "invalid"
        letSave = false
      } else {
        newCustomStyles.customerState = "valid"
      }

      if (newState.projectType === "" || newState.projectType === null) {
        newCustomStyles.projectTypeState = "invalid"
        letSave = false
      } else {
        newCustomStyles.projectTypeState = "valid"
      }

      if (!newState.coordinator || newState.coordinator === null) {
        newCustomStyles.coordinatorState = "invalid"
        letSave = false
      } else {
        newCustomStyles.coordinatorState = "valid"
      }

      if (!newState.soldHours || newState.soldHours.search("_") >= 0) {
        newCustomStyles.soldHours = "invalid"
        letSave = false
      } else {
        newCustomStyles.soldHours = "valid"
      }

      this.setState({
        customStyles: newCustomStyles,
      })

      if (!letSave) {
        this.setState({ saving: false })
        return
      } else {
        let customerResponse = await api.get(
          "/customer/read/" + this.state.customer
        )

        newData = {
          name: this.state.name,
          code: this.state.code,
          oldCode: this.state.code,
          customer: this.state.customer,
          soldHours: this.state.soldHours,
          customerShortName: customerResponse.data.shortName,
          projectType: this.state.projectType,
          coordinator: this.state.coordinator,
          estimatedBeginDate: this.state.estimatedBeginDate,
          estimatedEndDate: this.state.estimatedEndDate,
          beginDate: this.state.beginDate,
          endDate: this.state.endDate,
        }

        if (this.state.contract && this.state.contract !== null) {
          newData = {
            ...newData,
            contract: this.state.contract,
          }
        }

        success = true

        var projectId

        if (this.state.crudProject === "C") {
          try {
            const newProject = await api.post("/project/create", newData)

            projectId = newProject.data._id
          } catch (err) {
            this.warningAlert("Houve um problema ao cadastrar o projeto.")
            success = false
            this.setState({ saving: false })
            return
          }
        } else {
          projectId = this.state.id

          try {
            await api.put("/project/update/" + projectId, newData)
          } catch (err) {
            this.warningAlert(
              "Ocorreu um erro ao tentar alterar. Tente novamente."
            )
            success = false
            this.setState({ saving: false })
            return
          }
        }

        if (success) {
          this.closeDataPanel()
          this.loadData()
        }
      }
    }
  }

  openActivitiesPanel = async (e, row) => {
    this.setState({
      isLoading: true,
    })
    let newState = this.state

    newState.crudProject = "A"
    newState.selectedproject = row
    newState.activitiesMaintenence = true
    newState.customerShortName = row.customer.shortName
    newState.contractName = row.contract.name
    newState.coordinatorFullName = row.coordinator.fullName

    newState.dataPanelTitle = "Informações do Projeto"

    newState.projectReadOnly = true
    newState.id = row._id
    newState.code = row.code
    newState.name = row.name
    newState.customer = row.customer._id

    if (row.projectType && row.projectType !== "") {
      newState.projectType = row.projectType
    } else {
      newState.projectType = null
    }

    newState.contract = row.contract._id

    if (row.coordinator._id && row.coordinator._id !== "") {
      newState.coordinator = row.coordinator._id
    } else {
      newState.coordinator = null
    }

    newState.soldHours = row.soldHours
    newState.referenceHours = row.referenceHours
    newState.estimatedHours = row.estimatedHours
    newState.performedHours = row.performedHours

    if (
      row.estimatedBeginDate &&
      row.estimatedBeginDate !== null &&
      row.estimatedBeginDate !== ""
    ) {
      newState.estimatedBeginDate = Moment.utc(row.estimatedBeginDate).format(
        "YYYY-MM-DD"
      )
    } else {
      newState.estimatedBeginDate = ""
    }

    if (
      row.estimatedEndDate &&
      row.estimatedEndDate !== null &&
      row.estimatedEndDate !== ""
    ) {
      newState.estimatedEndDate = Moment.utc(row.estimatedEndDate).format(
        "YYYY-MM-DD"
      )
    } else {
      newState.estimatedEndDate = ""
    }

    if (row.beginDate && row.beginDate !== null && row.beginDate !== "") {
      newState.beginDate = Moment.utc(row.beginDate).format("YYYY-MM-DD")
    } else {
      newState.beginDate = ""
    }

    if (row.endDate && row.endDate !== null && row.endDate !== "") {
      newState.endDate = Moment.utc(row.endDate).format("YYYY-MM-DD")
    } else {
      newState.endDate = ""
    }
    const respTree = await api.post("/project/getProjectFullData", {
      dateTo: this.state.appointmentDateFilterTo,
      dateFrom: this.state.appointmentDateFilterFrom,
      project: newState.id,
      consultant: this.state.consultantFilter,
    })
    newState.estimatedHours = minutesToHours(respTree.data.estimatedMinutes)
    newState.projectTotalHours = minutesToHours(respTree.data.performedMinutes)
    newState.projectTotal = respTree.data.cost
    console.log(newState.activities)
    newState.activities = respTree.data.activities
    console.log(newState.activities)
    // newState.activities =  maintainActivitiesOpen(newState.activities, respTree.data.activities)
    // await this.getActivitiesCostsAndTotalHours(newState)
    newState.isLoading = respTree.false
    this.setState(newState)
  }

  openModalActivity = async (e, crud, activity) => {
    let newState = this.state

    newState.modalActivityOpened = true
    newState.crudActivity = crud
    newState.selectedActivity = activity

    switch (crud) {
      case "C":
        newState.modalActivityTitle = "Nova Atividade"
        break
      case "R":
        newState.modalActivityTitle = "Visualizando Atividade"
        break
      case "U":
        newState.modalActivityTitle = "Alterando Atividade"
        break
      default:
        newState.modalActivityTitle = "Excluindo Atividade"
    }

    if (crud === "C") {
      const respMaxCode = await api.post("/activity/maxCode", {
        deleted: "N",
        project: newState.id,
        activity: newState.selectedActivity
          ? newState.selectedActivity._id
          : null,
      })

      let activityCode = ""
      let maxCode = ""

      if (respMaxCode.data.length > 0) {
        maxCode = respMaxCode.data[0].code
      } else {
        maxCode = newState.selectedActivity
          ? newState.selectedActivity.code + ".0"
          : "0"
      }

      while (String(maxCode).indexOf(".") > 0) {
        activityCode += String(maxCode).substring(
          0,
          String(maxCode).indexOf(".") + 1
        )
        maxCode = String(maxCode).substring(
          String(maxCode).indexOf(".") + 1,
          String(maxCode).length
        )
      }

      let sequence = Number(maxCode) + 1

      activityCode += String(sequence)
      newState.activityActivity = activity ? activity._id : null
      newState.activityCode = activityCode
      newState.activityProcess = ""
      newState.activityStatus = "P"
      newState.activityRequester = ""
      newState.activitySoldHours = "0000:00"
      newState.activityEstimatedHours = "0000:00"
      newState.activityPerformedHours = "0000:00"
      newState.activityHoursSettled = true
      newState.activityHasActivities = true
      newState.activityConclusion = ""
    } else {
      let formattedEstimatedHours = activity.estimatedHours
      let formattedSoldHours = activity.soldHours
      let fomattedPerformedHours = activity.performedHours
      while (formattedEstimatedHours.length < 7) {
        formattedEstimatedHours = `0${formattedEstimatedHours}`
      }
      while (formattedSoldHours.length < 7) {
        formattedSoldHours = `0${formattedSoldHours}`
      }
      while (fomattedPerformedHours.length < 7) {
        fomattedPerformedHours = `0${fomattedPerformedHours}`
      }

      newState.activityActivity = activity ? activity._id : null
      newState.activityCode = activity.code
      newState.activityProcess = activity.process
      newState.activityRequester = activity.requester
      newState.activitySoldHours = formattedSoldHours
      newState.activityEstimatedHours = formattedEstimatedHours
      newState.activityPerformedHours = fomattedPerformedHours
      newState.activityHoursSettled = activity.hoursSettled
      newState.activityHasActivities = activity.hasActivities
      newState.activityStatus = activity.status
      newState.activityConclusion = activity.conclusion
    }

    this.setState(newState)
  }

  closeModalActivity(e) {
    this.setState({
      modalActivityOpened: false,
      modalActivityTitle: "",
      crudActivity: "",
      activityActivity: null,
      activityCode: "",
      activityProcess: "",
      activityRequester: "",
      activitySoldHours: "0000:00",
      activityEstimatedHours: "0000:00",
      activityPerformedHours: "0000:00",
      activityHoursSettled: false,
      activityHasActivities: false,
      activityStatus: "",
      activityConclusion: "",
      customStyles: {
        activityProcessState: null,
        activitySoldHoursState: null,
      },
    })
  }

  saveActivity = async (e) => {
    let newState = this.state
    let newCustomStyles = newState.customStyles
    let letSave = true

    if (newState.activityProcess === "") {
      letSave = false
      newCustomStyles.activityProcessState = "invalid"
    }
    if (!newState.activityHasActivities) {
      if (newState.activitySoldHours.search("_") >= 0) {
        letSave = false
        newCustomStyles.activitySoldHoursState = "invalid"
      }
    }

    if (!letSave) {
      this.setState(newState)
      return
    }

    let success = true
    let baseActivity = newState.selectedActivity

    if (newState.crudActivity === "D") {
      const appointmentsResponse = await api.post("appointment/search", {
        activity: newState.selectedActivity._id,
      })
      if (appointmentsResponse.data.length > 0) {
        this.warningAlert("Atividade com apontamentos não pode ser excluida")
      } else {
        try {
          await api.put("/activity/update/" + newState.selectedActivity._id, {
            deleted: "Y",
          })
        } catch (error) {
          this.warningAlert(
            "Ocorreu um erro ao tentar excluir. Tente novamente.\n" + error
          )
          success = false
          this.setState({ saving: false })
          return
        }
      }
    } else {
      let activityData = {
        process: newState.activityProcess,
        requester: newState.activityRequester,
        soldHours: newState.activitySoldHours,
        status: newState.activityStatus,
        estimatedHours: newState.activityEstimatedHours,
        hoursSettled: newState.activityHoursSettled,
        hasActivities: newState.activityHasActivities,
      }

      if (newState.crudActivity === "C") {
        activityData = {
          ...activityData,
          project: newState.id,
          activity: newState.selectedActivity
            ? newState.selectedActivity._id
            : null,
          code: newState.activityCode,
          status: newState.activityStatus,
          performedHours: "0000:00",
        }

        try {
          const newActivity = await api.post("/activity/create", activityData)
          baseActivity = newActivity.data
        } catch {
          this.warningAlert(
            "Ocorreu um erro ao tentar incluir. Tente novamente."
          )
          success = false
          this.setState({ saving: false })
          return
        }
      } else {
        try {
          await api.put(
            "/activity/update/" + newState.selectedActivity._id,
            activityData
          )
        } catch {
          this.warningAlert(
            "Ocorreu um erro ao tentar alterar. Tente novamente."
          )
          success = false
          this.setState({ saving: false })
          return
        }
      }
    }

    if (success) {
      const respProject = await api.put(
        "/activity/reviewHours/" + baseActivity._id
      )
      newState.selectedproject.referenceHours = respProject.data.referenceHours
      newState.selectedproject.estimatedHours = respProject.data.estimatedHours
      newState.selectedproject.performedHours = respProject.data.performedHours

      this.closeModalActivity()
      this.openActivitiesPanel(e, newState.selectedproject)
    }
  }

  showOrHideActivity(e, origActivities, activity) {
    let activities = origActivities
    let openedActivities = [...this.state.openedActivities] || []
    let index = openedActivities.findIndex((ac) => ac === activity._id)

    if (index >= 0) {
      openedActivities.splice(index, 1)
    } else {
      openedActivities.push(activity._id)
    }

    this.setState({
      activities: activities,
      openedActivities: openedActivities,
    })
  }

  showAllActivities(e, origActivities) {
    let activities = origActivities
    let openedActivities = [...this.state.openedActivities]
    for (let i = 0; i < activities.length; i++) {
      if (activities[i].hasActivities) {
        activities[i].opened = true

        this.showAllActivities(e, activities[i].subActivities)
      }
    }

    this.setState({
      activities: activities,
      allActivitiesOpened: true,
      openedActivities: Array.from(new Set(openedActivities)),
    })
  }

  hideAllActivities(e, origActivities) {
    let activities = origActivities
    for (let i = 0; i < activities.length; i++) {
      activities[i].opened = false
      if (activities[i].subActivities) {
        this.showAllActivities(e, activities[i].subActivities)
      }
    }
    this.setState({
      activities: activities,
      allActivitiesOpened: false,
      openedActivities: [],
    })
  }

  stylesForm = async (e, stateName, checkbox) => {
    if (checkbox) {
      let newCheck = this.state[stateName]
      newCheck = newCheck ? false : true

      if (stateName === "activityHasActivities") {
        if (newCheck) {
          this.setState({
            activityEstimatedHours: "0000:00",
            activitySoldHours: "0000:00",
          })
        }
      }

      this.setState({
        [stateName]: newCheck,
      })
    } else {
      let newState = this.state
      let newCustomStyles = newState.customStyles
      let newValue = e.target.value
      if (
        stateName === "consultantEstimatedHours" ||
        stateName === "soldHours" ||
        stateName === "activitySoldHours"
      ) {
        newValue = e.target.value.replace(/_/, /0/).replace("/0", "0")
      }
      if (newValue === null || newValue === "") {
        newCustomStyles[stateName + "State"] = "invalid"
      } else {
        newCustomStyles[stateName + "State"] = "valid"
      }

      newState.newCustomStyles = newCustomStyles
      newState[stateName] = newValue

      this.setState({
        newState,
      })

      if (stateName === "customer") {
        this.setState({
          contract: null,
        })
        if (newValue === "" || newValue === null) {
          this.setState({
            contract: null,
          })
        } else {
          this.loadContractsList()
        }
      }
    }
  }

  warningAlert = (message) => {
    this.setState({
      alert: (
        <ReactBSAlert
          warning
          style={{ display: "block" }}
          title="Atenção!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="warning"
          confirmBtnText="Ok"
          btnSize=""
        >
          {message}
        </ReactBSAlert>
      ),
    })
  }

  hideAlert = () => {
    this.setState({
      alert: null,
    })
  }

  openAppointmentsModal = async (e, activityConsultant) => {
    this.setState({
      isLoading: true,
    })
    let date = null
    if (
      this.state.appointmentDateFilterFrom &&
      this.state.appointmentDateFilterTo
    )
      date = {
        $lte: new Date(this.state.appointmentDateFilterTo).toISOString(),
        $gte: new Date(this.state.appointmentDateFilterFrom).toISOString(),
      }
    else if (this.state.appointmentDateFilterFrom)
      date = {
        $gte: new Date(this.state.appointmentDateFilterFrom).toISOString(),
      }
    else if (this.state.appointmentDateFilterTo)
      date = {
        $lte: new Date(this.state.appointmentDateFilterTo).toISOString(),
      }
    else date = null

    const appointmentsResponse = await api.post(
      "appointment/search",
      date
        ? {
            activity: activityConsultant.activity,
            consultant: activityConsultant.consultant._id,
            date: { ...date },
            $or: [
              { osCreated: true, thirdParty: null },
              { osCreated: null, thirdParty: true },
            ],
          }
        : {
            activity: activityConsultant.activity,
            consultant: activityConsultant.consultant._id,
            $or: [
              { osCreated: true, thirdParty: null },
              { osCreated: null, thirdParty: true },
            ],
          }
    )

    const activityResponse = await api.get(
      "activity/read/" + activityConsultant.activity
    )

    this.setState({
      consultantActivitiAppointments: appointmentsResponse.data,
      consultantActivityActivity: activityResponse.data,
      isAppointmentsModalOpen: true,
      isLoading: false,
    })
  }

  openModalConsultant = async (e, crud, consultantRow, activity) => {
    let newState = this.state
    let title = ""

    switch (crud) {
      case "C":
        title = "Adicionando Consultor"
        break

      case "U":
        title = "Alterando Consultor"
        break

      case "D":
        title = "Excluindo Consultor"
        break

      default:
        title = ""
    }

    if (consultantRow) {
      newState.selectedActivityConsultant = consultantRow
      newState.consultantActivity = consultantRow.activity
      newState.consultant = consultantRow.consultant._id
      newState.consultantEstimatedHours = consultantRow.estimatedHours
    } else {
      newState.selectedActivityConsultant = {}
      newState.consultantActivity = activity._id
      newState.consultant = ""
      newState.consultantEstimatedHours = "0000:00"
    }

    newState.modalConsultantOpen = true
    newState.modalConsultantTitle = title
    newState.crudConsultant = crud

    this.setState(newState)
  }

  closeModalConsultant() {
    let newState = this.state

    newState.modalConsultantOpen = false
    newState.consultantActivity = ""
    newState.modalConsultantTitle = ""
    newState.crudConsultant = ""
    newState.consultant = ""
    newState.consultantEstimatedHours = "0000:00"
    newState.selectedActivityConsultant = {}

    this.setState(newState)
  }

  saveConsultant = async (e) => {
    let newState = this.state
    let newCustomStyles = newState.customStyles
    let letSave = true
    var invalidConsultant = newState.consultant === ""

    if (!invalidConsultant && newState.crudConsultant === "C") {
      const respActivityCons = await api.post("/activityConsultant/search", {
        deleted: "N",
        consultant: newState.consultant,
        activity: newState.consultantActivity,
      })
      invalidConsultant = respActivityCons.data.length > 0

      if (invalidConsultant) {
        this.warningAlert(
          "O consultor selecionado já está alocado nessa atividade."
        )
      }
    }

    if (invalidConsultant) {
      letSave = false
      newCustomStyles.consultantState = "invalid"
    }

    if (newState.consultantEstimatedHours.search("_") >= 0) {
      letSave = false
      newCustomStyles.consultantEstimatedHoursState = "invalid"
    } else if (
      newState.consultantEstimatedHours === "0000:00" &&
      newState.hoursSettled
    ) {
      letSave = false
      newCustomStyles.consultantEstimatedHoursState = "invalid"
    }
    if (!letSave) {
      this.setState({
        customStyles: newCustomStyles,
      })
      return
    }

    let success = true

    if (newState.crudConsultant === "D") {
      const appointmentsResponse = await api.post("appointment/search", {
        activity: newState.consultantActivity,
      })
      const consultantHasActivities = appointmentsResponse.data.filter(
        (appointment) => {
          return appointment.consultant === newState.consultant
        }
      )
      if (consultantHasActivities.length > 0) {
        this.warningAlert("O consultor já criou apontamentos nessa atividade")
        return
      } else {
        try {
          await api.put(
            "/activityConsultant/update/" +
              newState.selectedActivityConsultant._id,
            { deleted: "Y" }
          )
        } catch {
          this.warningAlert(
            "Ocorreu um erro ao tentar excluir. Tente novamente."
          )
          success = false
          this.setState({ saving: false })
          return
        }
      }
    } else {
      let consultantData = {
        consultant: newState.consultant,
        estimatedHours: newState.consultantEstimatedHours,
      }

      if (newState.crudConsultant === "C") {
        consultantData = {
          ...consultantData,
          activity: newState.consultantActivity,
          performedHours: "0000:00",
        }

        try {
          await api.post("/activityConsultant/create", consultantData)
        } catch {
          this.warningAlert(
            "Ocorreu um erro ao tentar incluir. Tente novamente."
          )
          success = false
          this.setState({ saving: false })
          return
        }
      } else {
        try {
          await api.put(
            "/activityConsultant/update/" +
              newState.selectedActivityConsultant._id,
            consultantData
          )
        } catch {
          this.warningAlert(
            "Ocorreu um erro ao tentar alterar. Tente novamente."
          )
          success = false
          this.setState({ saving: false })
          return
        }
      }
    }
    if (success) {
      const respProject = await api.put(
        "/activity/reviewHours/" + newState.consultantActivity
      )
      newState.selectedproject.soldHours = respProject.data.soldHours
      newState.selectedproject.estimatedHours = respProject.data.estimatedHours
      newState.selectedproject.performedHours = respProject.data.performedHours
      this.closeModalConsultant()
      this.openActivitiesPanel(e, newState.selectedproject)
    }
  }
  /**
   *
   * @param {Event} e
   * @param {Object} project
   * @param {String} crud
   */
  confirmCloseProject = async (e, project, crud) => {
    let text = ""
    let action = {
      text: "Fechar projeto",
      blockConfirm: false,
      afterConfirm: "close-project",
    }

    const activitiesResult = await api
      .post("activity/search", { project: project._id, deleted: "N" })
      .catch(console.error())
    const schedulesResult = await api
      .post("schedule/search", { project: project._id, deleted: "N" })
      .catch(console.error())

    const activitiesOpen = activitiesResult.data.some((activity) => {
      return activity.status !== "F"
    })

    const schedulesWoServiceOrder = schedulesResult.data.filter((schedule) => {
      return !schedule.serviceOrder
    })

    if (schedulesWoServiceOrder.length > 0) {
      text =
        "Agendas pendentes de Ordem de Serviço para o projeto. Encerramento não permitido. <br><br/> <h4>Ordens de serviço pendentes<h4/>"
      text += "<ul>"
      schedulesWoServiceOrder.forEach((schedule) => {
        if (schedule.consultant)
          text +=
            "<li>" +
            schedule.consultant.fullName +
            " (" +
            convertMongoDate(schedule.date) +
            ")</li>"
      })
      text += "</ul>"
      action.blockConfirm = true
    } else if (activitiesOpen)
      text =
        "Ainda há Atividades pendentes ou em aberto nesse projeto, continuar com a operação?"
    else text = "Tem certeza que deseja encerrar esse projeto?"

    this.setState(
      {
        confirmationModalAction: action,
        confirmationModalText: text,
        selectedProject: project,
      },
      () => {
        this.setState({
          confirmationModalOpen: true,
        })
      }
    )
  }
  /**
   *
   * @param {Event} e
   * @param {Object} project
   * @param {String} crud
   */
  confirmReOpenProject = async (e, project, crud) => {
    let text = "Deseja mesmo reabrir o projeto selecionado?"
    let action = {
      text: "Reabrir projeto",
      blockConfirm: false,
      afterConfirm: "reopen-project",
    }

    this.setState(
      {
        confirmationModalAction: action,
        confirmationModalText: text,
        selectedProject: project,
      },
      () => {
        this.setState({
          confirmationModalOpen: true,
        })
      }
    )
  }

  /**
   *
   * @param {String} date
   */
  closeProject = async (date) => {
    this.setState({
      isLoading: true,
    })
    try {
      const project = this.state.selectedProject

      const activitiesResult = await api
        .post("activity/search", { project: project._id })
        .catch(console.error())

      const openActivities = activitiesResult.data.filter((activity) => {
        return activity.status !== "F"
      })

      for (const activity of openActivities) {
        await api.put("activity/update/" + activity._id, { status: "F" })
      }

      await api.put("project/update/" + project._id, {
        endDate: new Date(date),
      })
    } catch (error) {
      console.error(error)
      alert("Ocorreu um erro \n" + error)
    } finally {
      this.setState({
        finishProjectModalOpen: false,
      })
      this.loadData()
      this.loadCustomersList()
      this.loadCoordinatorsList()
      this.loadConsultantsList()
    }
  }
  /**
   *
   */
  reopenProject = async () => {
    this.setState({
      isLoading: true,
    })
    try {
      const project = this.state.selectedProject

      const activitiesResult = await api
        .post("activity/search", { project: project._id })
        .catch(console.error())

      for (const activity of activitiesResult.data) {
        const appointmentResponse = await api
          .post("appointment/search", {
            activity: activity._id,
            osCreated: true,
          })
          .catch(console.error())

        let status = appointmentResponse.data.length > 0

        await api.put("activity/update/" + activity._id, {
          status: status ? "I" : "P",
        })
      }

      await api.put("project/update/" + project._id, { endDate: null })
    } catch (error) {
      console.error(error)
      alert("Ocorreu um erro \n" + error)
    } finally {
      this.setState({
        finishProjectModalOpen: false,
      })
      this.loadData()
      this.loadCustomersList()
      this.loadCoordinatorsList()
      this.loadConsultantsList()
    }
  }
  /**
   *
   */
  confirm = async () => {
    if (this.state.confirmationModalAction.afterConfirm === "reopen-project") {
      await this.reopenProject()
      this.setState({
        confirmationModalOpen: false,
        confirmationModalText: "",
        confirmationModalAction: {},
      })
    }
    if (this.state.confirmationModalAction.afterConfirm === "close-project") {
      this.setState({
        confirmationModalOpen: false,
        confirmationModalText: "",
        confirmationModalAction: {},
        finishProjectModalOpen: true,
      })
    }
  }

  deny = async () => {
    this.setState({
      confirmationModalOpen: false,
      confirmationModalText: "",
      confirmationModalAction: {},
    })
  }

  async changeAppointmentsFilter(event, filter) {
    if (filter === "filterNonPerformed") {
      this.setState({
        ...this.state,
        filterNonPerformed: !this.state.filterNonPerformed,
      })
    } else {
      this.setState({
        ...this.state,
        [filter]: event.target.value,
      })
    }
  }

  async filterAppointments() {
    this.setState({
      isLoading: true,
    })

    let newState = this.state
    const respTree = await api.post("/project/getProjectFullData", {
      dateTo: this.state.appointmentDateFilterTo,
      dateFrom: this.state.appointmentDateFilterFrom,
      project: newState.id,
      consultant: this.state.consultantFilter,
    })

    newState.estimatedHours = minutesToHours(respTree.data.estimatedMinutes)
    newState.projectTotalHours = minutesToHours(respTree.data.performedMinutes)
    newState.projectTotal = respTree.data.cost

    newState.isLoading = false
    if (this.state.filterNonPerformed) {
      newState.activities = respTree.data.activities.filter(
        (activity) => activity.performedMinutes > 0
      )
    } else {
      newState.activities = respTree.data.activities
    }
    this.setState(newState)
  }

  render() {
    return (
      <>
        <LightHeader
          title="Cadastro de Projetos"
          parent="Cadastros"
          menuTitle="Projetos"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col>
              <Card hidden={this.state.crudProject !== ""}>
                <CardHeader>
                  <Row>
                    <Col xs="6">
                      <h3 className="mb-0">Lista de Projetos</h3>
                    </Col>
                    <Col className="text-right" xs="6">
                      <Button
                        className="btn-round btn-icon"
                        color="primary"
                        onClick={(e) => this.openDataPanel(e, null, "C")}
                        size="sm"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fas fa-plus" />
                        </span>
                        <span className="btn-inner--text">Novo Projeto</span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <ToolkitProvider
                  data={this.state.data}
                  keyField="_id"
                  columns={[
                    {
                      dataField: "actions",
                      editable: false,
                      text: "Ações",
                      isDummyField: true,
                      formatter: (cell, row, rowIndex) => {
                        return (
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              color=""
                              role="button"
                              size="sm"
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              {!row.endDate && (
                                <DropdownItem
                                  onClick={(e) =>
                                    this.openDataPanel(e, row, "U")
                                  }
                                >
                                  <i className="fas fa-edit" />
                                  <span>Alterar</span>
                                </DropdownItem>
                              )}
                              <DropdownItem
                                onClick={(e) => this.openDataPanel(e, row, "D")}
                              >
                                <i className="fas fa-trash" />
                                <span>Excluir</span>
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) => this.openDataPanel(e, row, "R")}
                              >
                                <i className="fas fa-glasses" />
                                <span>Visualizar</span>
                              </DropdownItem>
                              {!row.endDate && (
                                <DropdownItem
                                  onClick={(e) =>
                                    this.confirmCloseProject(e, row, "U")
                                  }
                                >
                                  <i className="fas fa-ban" />
                                  <span>Encerrar Projeto</span>
                                </DropdownItem>
                              )}
                              {row.endDate && (
                                <DropdownItem
                                  onClick={(e) =>
                                    this.confirmReOpenProject(e, row, "U")
                                  }
                                >
                                  <i className="fas fa-backward" />
                                  <span>Reabrir Projeto</span>
                                </DropdownItem>
                              )}
                              <DropdownItem divider />
                              <DropdownItem
                                onClick={(e) =>
                                  this.openActivitiesPanel(e, row)
                                }
                              >
                                <i className="fas fa-list" />
                                <span>Atividades</span>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        )
                      },
                    },
                    {
                      dataField: "_id",
                      text: "Id",
                      hidden: true,
                    },
                    {
                      dataField: "code",
                      text: "Código",
                      sort: true,
                      width: 200,
                    },
                    {
                      dataField: "name",
                      text: "Nome do Projeto",
                      sort: true,
                    },
                    {
                      dataField: "customerShortName",
                      text: "Cliente",
                      sort: true,
                    },
                    {
                      dataField: "projectType",
                      text: "Tipo",
                      sort: true,
                      formatter: (cell, row) => projectTypes[cell],
                    },
                    {
                      dataField: "coordinator.fullName",
                      text: "Coordenador",
                      sort: true,
                    },
                    {
                      dataField: "endDate",
                      text: "Status",
                      sort: true,
                      formatter: (cell, row) => {
                        if (!cell || cell === null || cell === "") {
                          return (
                            <Badge className="badge-lg" color="danger" pill>
                              Não Iniciado
                            </Badge>
                          )
                        } else {
                          if (
                            !row.endDate ||
                            row.endDate === null ||
                            row.endDate === ""
                          ) {
                            return (
                              <Badge className="badge-lg" color="warning" pill>
                                Em Andamento
                              </Badge>
                            )
                          } else {
                            return (
                              <Badge className="badge-lg" color="success" pill>
                                Concluído
                              </Badge>
                            )
                          }
                        }
                      },
                    },
                    {
                      dataField: "soldHours",
                      text: "Horas Vendidas",
                      sort: true,
                    },
                    {
                      dataField: "referenceHours",
                      text: "Horas Referência",
                      sort: true,
                    },
                    {
                      dataField: "estimatedHours",
                      text: "Horas Estimadas",
                      sort: true,
                    },
                   /* {
                      dataField: "performedHours",
                      text: "Horas Realizadas",
                      sort: true,
                    },*/
                  ]}
                  search
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <Container fluid>
                        <Row>
                          <Col xs={12}>
                            <div
                              id="datatable-basic_filter"
                              className="dataTables_filter px-4 pb-1 float-left"
                            >
                              <label>
                                Pesquisar:
                                <SearchBar
                                  className="form-control-sm"
                                  placeholder="Pesquisar por..."
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                      <BootstrapTable
                        ref={(el) => (this.componentRef = el)}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        hover
                        id="react-bs-table"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Card>
              <ProjectPanel
                openedActivities={this.state.openedActivities}
                crudProject={this.state.crudProject}
                dataPanelTitle={this.state.dataPanelTitle}
                saving={this.state.saving}
                code={this.state.code}
                filterNonPerformed={this.state.filterNonPerformed}
                projectReadOnly={this.state.projectReadOnly}
                customStyles={this.state.customStyles}
                name={this.state.name}
                projectType={this.state.projectType}
                beginDate={this.state.beginDate}
                customer={this.state.customer}
                endDate={this.state.endDate}
                customersList={this.state.customersList}
                coordinator={this.state.coordinator}
                coordinatorsList={this.state.coordinatorsList}
                contract={this.state.contract}
                contractsList={this.state.contractsList}
                soldHours={this.state.soldHours}
                referenceHours={this.state.referenceHours}
                estimatedHours={this.state.estimatedHours}
                performedHours={this.state.performedHours}
                estimatedBeginDate={this.state.estimatedBeginDate}
                projectTotal={this.state.projectTotal}
                projectTotalHours={this.state.projectTotalHours}
                estimatedEndDate={this.state.estimatedEndDate}
                appointmentDateFilterFrom={this.state.appointmentDateFilterFrom}
                appointmentDateFilterTo={this.state.appointmentDateFilterTo}
                closeDataPanel={this.closeDataPanel.bind(this)}
                saveProjectData={this.saveProjectData.bind(this)}
                stylesForm={this.stylesForm.bind(this)}
                activitiesMaintenence={this.state.activitiesMaintenence}
                customerShortName={this.state.customerShortName}
                contractName={this.state.contractName}
                coordinatorFullName={this.state.coordinatorFullName}
                activities={this.state.activities}
                showOrHideActivity={this.showOrHideActivity.bind(this)}
                showAllActivities={this.showAllActivities.bind(this)}
                hideAllActivities={this.hideAllActivities.bind(this)}
                allActivitiesOpened={this.state.allActivitiesOpened}
                openModalActivity={this.openModalActivity.bind(this)}
                openModalConsultant={this.openModalConsultant.bind(this)}
                changeAppointmentsFilter={this.changeAppointmentsFilter.bind(
                  this
                )}
                consultantsList={this.state.consultantsList}
                filterAppointments={this.filterAppointments.bind(this)}
                openAppointmentsModal={this.openAppointmentsModal.bind(this)}
                consultantFilter={this.state.consultantFilter}
                setConsultantFilter={(id) => {
                  this.setState({ consultantFilter: id })
                }}
              />
            </Col>
          </Row>
          <Activity
            modalActivityOpened={this.state.modalActivityOpened}
            modalActivityTitle={this.state.modalActivityTitle}
            crudActivity={this.state.crudActivity}
            activityActivity={this.state.activityActivity}
            activityCode={this.state.activityCode}
            activityProcess={this.state.activityProcess}
            activityStatus={this.state.activityStatus}
            activityRequester={this.state.activityRequester}
            activityHasActivities={this.state.activityHasActivities}
            activitySoldHours={this.state.activitySoldHours}
            activityEstimatedHours={this.state.activityEstimatedHours}
            activityPerformedHours={this.state.activityPerformedHours}
            activityHoursSettled={this.state.activityHoursSettled}
            activityConclusion={this.state.activityConclusion}
            stylesForm={this.stylesForm.bind(this)}
            customStyles={this.state.customStyles}
            closeModalActivity={this.closeModalActivity.bind(this)}
            saveActivity={this.saveActivity.bind(this)}
          />
          <ActivityConsultant
            modalConsultantOpen={this.state.modalConsultantOpen}
            modalConsultantTitle={this.state.modalConsultantTitle}
            consultantsList={this.state.consultantsList}
            crudConsultant={this.state.crudConsultant}
            consultant={this.state.consultant}
            consultantEstimatedHours={this.state.consultantEstimatedHours}
            saving={this.state.saving}
            closeModalConsultant={this.closeModalConsultant.bind(this)}
            saveConsultant={this.saveConsultant.bind(this)}
            customStyles={this.state.customStyles}
            stylesForm={this.stylesForm.bind(this)}
          />
          <Loading modalOpened={this.state.isLoading} />
          {this.state.alert}
        </Container>
        <ConfirmationModal
          isOpen={this.state.confirmationModalOpen}
          action={this.state.confirmationModalAction || {}}
          text={this.state.confirmationModalText}
          confirm={() => this.confirm()}
          deny={() => this.deny()}
          closeModal={() => {
            this.setState({
              confirmationModalOpen: false,
            })
          }}
        />
        <Appointments
          isOpen={this.state.isAppointmentsModalOpen}
          appointments={this.state.consultantActivitiAppointments || []}
          consultantActivity={this.state.consultantActivityActivity}
          closeModal={() => {
            this.setState({
              isAppointmentsModalOpen: false,
              consultantActivitiAppointments: [],
              consultantActivityActivity: {},
            })
          }}
        />
        <FinishProjectModal
          isOpen={this.state.finishProjectModalOpen}
          project={this.state.selectedProject}
          updateProject={(date) => this.closeProject(date)}
          closeModal={() => {
            this.setState({
              finishProjectModalOpen: false,
            })
          }}
        />
      </>
    )
  }
}

export default Projects