import ScheduleForm from "components/Forms/ScheduleForm";
import "moment/locale/pt-br";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import ReactBSAlert from "react-bootstrap-sweetalert";

import { Button, Card, CardBody, Modal, UncontrolledTooltip } from "reactstrap";
import api from "services/api";
import { userId } from "services/auth";

import { BrowserView } from "react-device-detect";
import BigCalendar from "components/BigCalendar/BigCalendar";
import ConfirmationModal from "../ConfirmationModal";
import { minutesToHours } from "utils/timeHandling";

export default function Schedule(props) {
  const [saving, setSaving] = useState(false);

  const [customers, setCustomers] = useState([]);
  const [customerAddresses, setCustomerAddresses] = useState([]);
  const [consultants, setConsultants] = useState([]);
  const [projects, setProjects] = useState([]);
  const [events, setEvents] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [confirmationModalText, setConfirmationModalText] = useState("");
  const [formConfirmationModalOpen, setFormConfirmationModalOpen] =
    useState(false);
  const [alert, setAlert] = useState(null);

  const [scheduleBody, setScheduleBody] = useState({
    customer: "",
    address: "",
    consultant: "",
    date: new Date().toISOString().split("T")[0],
    timeFrom: "00:00",
    timeTo: "00:00",
    timeCommute: "00:00",
    timeOthers: "00:00",
    timeTotal: "00:00",
    billing: false,
    project: "",
    log: [],
  });

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationModalAction, setConfirmationModalAction] = useState({});

  const closeModal = () => {
    props.closeModal();
  };
  /**
   * formats string to ISO format (the + " 00:00 " fixes a glitch where the date is wright but is presented a day early in the DOM)
   * @param {*} date
   * @returns {String}
   */
  function getIsoDate(date) {
    return new Date(date).toISOString().split("T")[0] + " 00:00";
  }
  /**
   * gets schedule id
   * @param {*} date
   * @returns {String}
   */
  function getSchedule(date) {
    return props.userSchedules.find(
      (sh) => getIsoDate(sh.date) === getIsoDate(date)
    );
  }
  /**
   * Hook to calculate final time
   */
  useEffect(() => {
    let timeArray = [
      scheduleBody.timeTo,
      scheduleBody.timeFrom,
      scheduleBody.timeCommute,
      scheduleBody.timeOthers,
    ];
    let hours = 0,
      minutes = 0;
    timeArray.forEach((time, index) => {
      let parseTime = time.split(":");
      //on even index we add in not even we subtract
      if (index % 2 === 0) {
        hours += parseInt(parseTime[0]);
        minutes += parseInt(parseTime[1]);
      } else {
        hours -= parseInt(parseTime[0]);
        minutes -= parseInt(parseTime[1]);
      }
    });

    setScheduleBody((prev) => ({
      ...prev,
      timeTotal: minutesToHours(hours * 60 + minutes),
    }));
  }, [
    scheduleBody.timeCommute,
    scheduleBody.timeFrom,
    scheduleBody.timeOthers,
    scheduleBody.timeTo,
  ]);

  const getData = useCallback(async () => {
    const customersResponse = await api.get("customer/all");
    setCustomers(customersResponse.data);

    const customerAddressesResponse = await api.get("customerAddress/all");
    setCustomerAddresses(customerAddressesResponse.data);

    const consultantsResponse = await api.get("user/all");
    setConsultants(consultantsResponse.data);

    const projectResponse = await api.get("project/all");
    setProjects(projectResponse.data);

    if (scheduleBody._id) {
      const appointmentsResponse = await api.post("appointment/search", {
        schedule: scheduleBody._id,
      });
      setAppointments(appointmentsResponse.data);
    }
  }, [scheduleBody._id]);
  /** Hook to get Database info */
  useEffect(() => {
    getData();
  }, [getData]);

  /** Hook to fill the big calendar component with events on component creation */
  useEffect(() => {
    if (!props.isOpen) {
      setEvents([]);
    } else {
      if (props.userSchedules) {
        let events = [];
        props.userSchedules.forEach((schle) => {
          if (
            schle.activity === scheduleBody.activity &&
            schle.address === scheduleBody.address &&
            schle.consultant === scheduleBody.consultant &&
            schle.customer === scheduleBody.customer &&
            schle.project === scheduleBody.project &&
            schle.timeFrom === scheduleBody.timeFrom &&
            schle.timeTo === scheduleBody.timeTo &&
            schle.project === scheduleBody.project &&
            schle.timeOthers === scheduleBody.timeOthers &&
            schle.billing === scheduleBody.billing &&
            schle._id !== scheduleBody._id
          ) {
            events.push({
              id: schle._id,
              title: "Copiada",
              type: "copied-schedule",
              start: getIsoDate(schle.date),
              end: getIsoDate(schle.date),
            });
          } else if (
            schle.consultant === scheduleBody.consultant &&
            schle.project === scheduleBody.project &&
            schle._id !== scheduleBody._id
          ) {
            events.push({
              id: schle._id,
              title: "Ocupada",
              type: "busy-schedule",
              start: getIsoDate(schle.date),
              end: getIsoDate(schle.date),
            });
          } else if (schle._id === scheduleBody._id) {
            events.push({
              id: schle._id,
              title: "Atual",
              type: "self-schedule",
              start: getIsoDate(schle.date),
              end: getIsoDate(schle.date),
            });
          }
        });
        setEvents(events);
      }
    }
  }, [props.isOpen, scheduleBody, props.userSchedules]);

  /** Hook to set schedule body  */
  useEffect(() => {
    if (props.schedule._id) {
      props.schedule.date = props.schedule.date.split("T")[0];
      setScheduleBody(props.schedule);
    }
  }, [props.schedule]);

  /**
   * Function to save schedule
   */
  const saveSchedule = async () => {
    try {
      await api.patch(`schedule/update/${scheduleBody._id}`, {
        ...scheduleBody,
        user: userId(),
      });
      props.updateList();
      closeModal();
    } catch (error) {
      alert("Ocorreu um erro");
    }
  };

  /**
   * Function do delete schedule
   */
  const deleteSchedule = async () => {
    try {
      await api.patch(`schedule/delete/${scheduleBody._id}`, {
        ...scheduleBody,
        user: userId(),
      });
      props.updateList();
      closeModal();
    } catch (error) {
      alert("Ocorreu um erro");
    }
  };

  /**
   * Function to filter the event of clicking date slots
   * @param {Event} e
   */
  const setSelectedDates = (e) => {
    const scheduleIsoDate = getIsoDate(scheduleBody.date);

    //gets formatted slots and removes current schedule from event (if it is on the event)
    const slots = e.slots.filter((slot) => {
      return getIsoDate(slot) !== scheduleIsoDate;
    });

    // separetes the current events in copied events, events set to deletion and empty & selected  events and removes current event
    const deleteDates = events
      .filter((event) => {
        return event.type === "delete-schedule";
      })
      .map((slot) => {
        return getIsoDate(slot.start);
      });

    const copyDates = events
      .filter((event) => {
        return event.type === "copied-schedule";
      })
      .map((slot) => {
        return getIsoDate(slot.start);
      });

    const busyDates = events
      .filter((event) => {
        return event.type === "busy-schedule";
      })
      .map((slot) => {
        return getIsoDate(slot.start);
      });

    const currentEvents = events
      .filter((ev) => ev.type !== "self-schedule")
      .map((slot) => {
        return slot.start;
      });

    //joins the events from the DOM event generated  list with the current events
    const joinEvents = [...currentEvents, ...slots].map((date) => {
      return getIsoDate(date);
    });

    // gets the events that are not repeated and are nos in other lists
    const filteredUniques = joinEvents.filter((event) => {
      return (
        joinEvents.filter((ev) => ev === event).length < 2 &&
        deleteDates.filter((ev) => ev === event) < 1 &&
        copyDates.filter((ev) => ev === event) < 1 &&
        busyDates.filter((ev) => ev === event) < 1
      );
    });

    // creates the events with the right format on the big calendar
    const newEvents = filteredUniques.map((date, index) => {
      return {
        title: "Copiar",
        type: "selected-schedule",
        start: new Date(date),
        end: new Date(date),
      };
    });
    const deleteEvents = deleteDates.map((date, index) => {
      return {
        title: "Excluir",
        type: "delete-schedule",
        start: new Date(date),
        end: new Date(date),
      };
    });
    const copyEvents = copyDates.map((date, index) => {
      return {
        title: "copiada",
        type: "copied-schedule",
        start: new Date(date),
        end: new Date(date),
      };
    });
    const busyEvents = busyDates.map((date, index) => {
      return {
        title: "Ocupada",
        type: "busy-schedule",
        start: new Date(date),
        end: new Date(date),
      };
    });

    setEvents([
      {
        title: "Atual",
        type: "self-schedule",
        start: scheduleIsoDate,
        end: scheduleIsoDate,
      },
      ...newEvents,
      ...deleteEvents,
      ...copyEvents,
      ...busyEvents,
    ]);
  };

  const handleSelectEvent = (e) => {
    if (e.type === "copied-schedule") {
      let newEvents = events.map((ev) => {
        if (getIsoDate(ev.start) === getIsoDate(e.start)) {
          return {
            title: "Excluir",
            type: "delete-schedule",
            start: new Date(ev.start),
            end: new Date(ev.start),
          };
        } else return ev;
      });
      setEvents(newEvents);
    } else if (e.type === "delete-schedule") {
      let newEvents = events.map((ev) => {
        if (getIsoDate(ev.start) === getIsoDate(e.start)) {
          return {
            title: "copiada",
            type: "copied-schedule",
            start: new Date(ev.start),
            end: new Date(ev.start),
          };
        } else return ev;
      });

      setEvents(newEvents);
    } else if (e.type === "selected-schedule") {
      let newEvents = events.map((ev) => {
        return ev;
      });

      const index = newEvents.findIndex(
        (ev) => getIsoDate(ev.start) === getIsoDate(e.start)
      );

      newEvents.splice(index, 1);

      setEvents(newEvents);
    }
  };
  /**
   * copies selected schedules
   */
  const copySchedules = async () => {
    try {
      await Promise.all(
        events
          .filter((ev) => ev.type === "selected-schedule")
          .map((event) => {
            let scheduleBodyCopy = {
              ...scheduleBody,
            };
            scheduleBodyCopy.date = new Date(event.start).toISOString();
            delete scheduleBodyCopy["_id"];
            delete scheduleBodyCopy["createdAt"];
            delete scheduleBodyCopy["modifiedAt"];
            delete scheduleBodyCopy["serviceOrder"];
            scheduleBodyCopy.user = userId();
            return api.post("schedule/create", scheduleBodyCopy);
          })
      );
      props.updateList();
    } catch (error) {
      alert("Ocorreu um erro ao copiar a agenda");
    }
  };
  /**
   * delete selectes cschedules copies
   */
  const deleteSchedules = async () => {
    try {
      await Promise.all(
        events
          .filter((ev) => ev.type === "delete-schedule")
          .map((event) => {
            const schedule = getSchedule(event.start);
            if (schedule.serviceOrder) {
              alert("Agenda já possui OS");
              return null;
            } else {
              return api.patch("schedule/delete/" + schedule._id);
            }
          })
      );
      props.updateList();
    } catch (error) {
      console.error(error);
    }
  };
  async function checkActivities() {
    let activitiesResponse = await api.get(
      `user/projectActivities/${scheduleBody.consultant}/${scheduleBody.project}`
    );

    if (!activitiesResponse.data) {
      setConfirmationModalText(
        "O consultor não possui atividades alocadas para o projeto requisitado. Deseja continuar?"
      );
      setConfirmationModalAction({
        action: "approve-schedule",
        text: `Atenção:`,
      });
      setConfirmationModalOpen(true);
    } else {
      approveSchedule();
    }
  }
  async function approveSchedule() {
    try {
      await api.patch("schedule/update/" + scheduleBody._id, {
        status: "AP",
        logActivity: "AP",
        consultantId: scheduleBody.consultant,
      });
      props.getUsersAndSchedules();
      closeModal();
    } catch (error) {
      console.error(error);
      showAlert("Ocorreu um erro ao aprovar a agenda", "danger");
    }
  }
  async function rejectSchedule() {
    try {
      await api.patch("schedule/update/" + scheduleBody._id, {
        status: "RJ",
        rejectionReason: scheduleBody.rejectionReason,
      });
      props.getUsersAndSchedules();
      closeModal();
    } catch (error) {
      console.error(error);
      showAlert("Ocorreu um erro ao rejeitar a agenda", "danger");
    }
  }

  function hideAlert() {
    setAlert(null);
  }

  function showAlert(message, color) {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block" }}
        title="Atenção!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle={`${color}`}
        confirmBtnText="Ok"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );
  }

  return (
    <Modal
      className="modal-dialog-centered"
      size="xl"
      isOpen={props.isOpen}
      autoFocus={false}
    >
      <div className="modal-header">
        <h5 className="modal-title">{props.title}</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={(e) => closeModal()}
          disabled={saving}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <Card>
        {customers && customerAddresses && consultants && (
          <CardBody>
            <ScheduleForm
              customers={customers}
              customerAddresses={customerAddresses}
              consultants={consultants}
              projects={projects}
              scheduleBody={scheduleBody}
              readOnly={props.readOnly}
              setScheduleBody={(prop) => {
                setScheduleBody((prev) => ({ ...prev, ...prop }));
              }}
              confirmationModalOpen={formConfirmationModalOpen}
              setConfirmationModalOpen={(value) => {
                setConfirmationModalText(
                  "Você tem certeza que deseja excluir esse apontamento?"
                );
                setFormConfirmationModalOpen(value);
              }}
              rejectSchedule={() => rejectSchedule()}
            />
            <BrowserView>
              {!props.readOnly && (
                <BigCalendar
                  schedule={scheduleBody}
                  project={projects.find(
                    (proj) => proj._id === scheduleBody.project
                  )}
                  events={events}
                  setSelectedDates={(e) => setSelectedDates(e)}
                  handleSelectEvent={(e) => handleSelectEvent(e)}
                  copySchedules={() => copySchedules()}
                  deleteSchedules={() => deleteSchedules()}
                />
              )}
            </BrowserView>
          </CardBody>
        )}
      </Card>
      {!props.readOnly && (
        <div className="modal-footer">
          {!scheduleBody.serviceOrder ||
            (appointments.length > 0 && (
              <UncontrolledTooltip delay={0} target="deleteSchedule">
                Agenda com apontamentos não pode ser excluída
              </UncontrolledTooltip>
            ))}
          {!scheduleBody.status || scheduleBody.status === "AP" ? (
            <>
              <Button
                color="warning"
                data-dismiss="modal"
                type="button"
                id="deleteSchedule"
                onClick={() => {
                  setConfirmationModalText(
                    "Você tem certeza de que quer excluir a agenda?"
                  );
                  setConfirmationModalAction({
                    action: "delete",
                    text: `Excluir agenda`,
                  });
                  setConfirmationModalOpen(true);
                }}
                disabled={
                  saving || scheduleBody.serviceOrder || appointments.length > 0
                }
              >
                Excluir
              </Button>
              <Button
                color="success"
                type="button"
                onClick={(e) => saveSchedule()}
                disabled={
                  saving || scheduleBody.serviceOrder || appointments.length > 0
                }
              >
                {props.action}
              </Button>
            </>
          ) : scheduleBody.status === "PA" ? (
            <>
              <Button
                color="warning"
                data-dismiss="modal"
                type="button"
                id="deleteSchedule"
                onClick={() => {
                  setFormConfirmationModalOpen(true);
                }}
                disabled={
                  saving || scheduleBody.serviceOrder || appointments.length > 0
                }
              >
                Rejeitar Agenda
              </Button>
              <Button
                color="success"
                type="button"
                onClick={(e) => checkActivities()}
                disabled={
                  saving || scheduleBody.serviceOrder || appointments.length > 0
                }
              >
                Aprovar Agenda
              </Button>
            </>
          ) : (
            <> Agenda aguardando alteração do consultor</>
          )}

          <ConfirmationModal
            isOpen={confirmationModalOpen}
            action={confirmationModalAction}
            text={confirmationModalText}
            confirm={() => {
              if (confirmationModalAction.action === "delete") {
                deleteSchedule();
                setConfirmationModalAction({});
              }
              if (confirmationModalAction.action === "approve-schedule") {
                approveSchedule();
              }
            }}
            closeModal={() => {
              setConfirmationModalOpen(false);
            }}
            deny={() => {
              setConfirmationModalOpen(false);
            }}
          />
        </div>
      )}
      {alert}
    </Modal>
  );
}
