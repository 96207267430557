/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"
import { Link } from "react-router-dom"
// nodejs library that concatenates classes
import classnames from "classnames"
// nodejs library to set properties for components
import PropTypes from "prop-types"
// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap"
import Avatar from "react-avatar"

import api from "services/api"
import { logout } from "services/auth"
import { userFullName } from "services/auth"

class AdminNavbar extends React.Component {
  state = {
    fullName: "",
    imgPath: null,
  }

  componentDidMount() {
    this.setState({ fullName: userFullName() })
    this.loadUserImg()
  }

  loadUserImg = async () => {
    if (
      localStorage.getItem(process.env.REACT_APP_USERIMGID_KEY) !== "null" &&
      localStorage.getItem(process.env.REACT_APP_USERIMGID_KEY) !== ""
    ) {
      const response = await api.get(
        "/document/read/" +
          localStorage.getItem(process.env.REACT_APP_USERIMGID_KEY)
      )

      if (response.data) {
        this.setState({ imgPath: response.data.url })
      } else {
        this.setState({ imgPath: null })
      }
    }
  }

  leavePortal() {
    logout()
  }

  // function that on mobile devices makes the search open
  openSearch = () => {
    document.body.classList.add("g-navbar-search-showing")
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing")
      document.body.classList.add("g-navbar-search-show")
    }, 150)
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown")
    }, 300)
  }
  // function that on mobile devices makes the search close
  closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown")
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show")
      document.body.classList.add("g-navbar-search-hiding")
    }, 150)
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding")
      document.body.classList.add("g-navbar-search-hidden")
    }, 300)
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden")
    }, 500)
  }

  render() {
    return (
      <>
        <Navbar
          className={classnames(
            "navbar-top navbar-expand border-bottom",
            { "navbar-dark bg-info": this.props.theme === "dark" },
            { "navbar-light bg-default": this.props.theme === "light" }
          )}
        >
          <Container fluid>
            <Collapse navbar isOpen={true}>
              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem className="d-xl-none">
                  <div
                    className={classnames(
                      "pr-3 sidenav-toggler",
                      { active: this.props.sidenavOpen },
                      { "sidenav-toggler-dark": this.props.theme === "dark" }
                    )}
                    onClick={this.props.toggleSidenav}
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="fas fa-bars"></i>
                    </div>
                  </div>
                </NavItem>
                {/* <NavItem className="d-sm-none">
                  <NavLink onClick={this.openSearch}>
                    <i className="ni ni-zoom-split-in" />
                  </NavLink>
                </NavItem> */}
              </Nav>
              <Nav className="align-items-center ml-auto ml-md-0" navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    className="nav-link pr-0"
                    color=""
                    tag="a"
                    href="#"
                  >
                    <Media className="align-items-center">
                      <span className="avatar avatar-sm rounded-circle">
                        {this.state.imgPath && this.state.imgPath !== null ? (
                          <img alt="..." src={this.state.imgPath} />
                        ) : (
                          <Avatar
                            name={this.state.fullName}
                            maxInitials={2}
                            round={true}
                            size={36}
                            title={" "}
                            color={"#ced4da"}
                            fgColor={"#172b4d"}
                          />
                        )}
                      </span>
                      <Media className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold text-white">
                          {this.state.fullName}
                        </span>
                      </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0">Bem vindo !</h6>
                    </DropdownItem>
                    <DropdownItem
                      // href="/admin/profile"
                      // onClick={e => e.preventDefault()}
                      to={"/admin/profile"}
                      tag={Link}
                    >
                      <i className="ni ni-single-02" />
                      <span>Meu perfil</span>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem href="/" onClick={(e) => this.leavePortal()}>
                      <i className="ni ni-user-run" />
                      <span>Sair</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    )
  }
}
AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
}
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
}

export default AdminNavbar
